import _ from 'lodash';
import dot from 'dot-object';
import humps from 'humps';
import { objectUtils } from '../../../../../../..';
import { _isElementNode_v1_0_0, getWimlPageNodeCollectionFromNode_v1_0_0 } from './nodes';
import {
    WimlNode_v1_0_0, ThemeDataListComponentCollection_v1_0_0

} from './types';
import {
    ThemeDataListQualifiedAndMergedWithComponents_v1,
    ThemeDataPageList_v1,
    ListInstanceComponentThemeData_v1,
    ThemeDataListQualifiedAndMerged_v1,
    ThemeDataPageQualifiedListComponent_v1,
    ThemeDataPageListQualifiedComponent_v1,
    ThemeDataPageComponent_v1
} from "../../../types";
import { _getComponentId_v1_0_0 } from './components';
import { getInferredComponentDefaultType_v1_0_0 } from './components';
import { processListQualifiedNodeChildren_v1_0_0 } from './nodes';
import { _isTextNode_v1_0_0, _isListNode_v1_0_0, _isPageNode_v1_0_0 } from './nodes';
import { wimlComponents_v1_0_0 } from '../renderer/wiml-components-v1.0.0';
import * as R from 'fp-ts/lib/Record';
import { pipe } from 'fp-ts/lib/function';
import { getListIdFromNodeAttrs_v1_0_0 } from './nodes';
import { isThemeDataListComponent_v1_0_0 } from './components';
import { listItemComponentCheckoutRules } from '../renderer/render-components';
import { RuntimeWimlComponentCollection_v1_0_0 } from '../renderer/types';

export function getThemeDataListCollectionFromNode_v1_0_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, jsonNode: WimlNode_v1_0_0) {
    // extract all top-level pages including header and footer
    const nodeCollection = getWimlPageNodeCollectionFromNode_v1_0_0(jsonNode);

    // copy key names from allComponents to new variable
    const themeDataListComponents = Object.keys(nodeCollection)
        .map((pageKey) => {
            const page = nodeCollection[pageKey];
            const listComponentsArray = _getListComponentsFromPage_v1_0_0(runtimeComponents, page);

            const listComponentsArrayPageOccurrence = listComponentsArray.map((c) => {
                if (c.type === 'List') {
                    const retVal = { ...c, pageOccurrenceId: pageKey } as ThemeDataPageList_v1;
                    return retVal;
                } else {
                    const retVal = c;
                    return retVal;
                }
            });
            return listComponentsArrayPageOccurrence;
        })
        .flat() as ListInstanceComponentThemeData_v1[];

    // group by list id and deep merge
    const fullyQualifiedListComponents: ListInstanceComponentThemeData_v1[] = themeDataListComponents.map((c) => ({ ...c, id: _getComponentId_v1_0_0(c) }));
    const themeDataListQualifiedAndMergedComponents = mergeListInstanceComponents_v1_0_0(fullyQualifiedListComponents);

    // this ensures list and its components get same namespace. remove the qualified id.
    const nonListComponents = fullyQualifiedListComponents.filter((c) => c.type != 'List');

    const mergedListsAndNonListComponents = [...themeDataListQualifiedAndMergedComponents, ...nonListComponents];
    const listQualifiedComponentsObject = mergedListsAndNonListComponents.map((c) => getListQualifiedComponent(c));
    const listComponentsObject = objectUtils.convertArrayToObject(listQualifiedComponentsObject, 'lqId');
    const camelizedPageComponents = humps.camelizeKeys(listComponentsObject) as Record<string, ThemeDataPageQualifiedListComponent_v1>;

    // split keys on . and create nested objects
    const nestedPageComponents = dot.object({ ...camelizedPageComponents }) as Record<string, ThemeDataListQualifiedAndMerged_v1>;

    // this is necesarry for listed objects
    const camelizedPageComponents2 = humps.camelizeKeys(nestedPageComponents) as Record<string, ThemeDataListQualifiedAndMergedWithComponents_v1>;
    const camelizedPageComponents3 = pipe(
        camelizedPageComponents2,
        R.map(listObj => ({
            ...listObj,
            components: {
                ...listObj.components,
                ids: Object.keys(listObj.components.items),
            },
        }))
    ) as Record<string, ThemeDataListQualifiedAndMergedWithComponents_v1>;

    const retVal = { ids: Object.keys(camelizedPageComponents3), items: camelizedPageComponents3 } as ThemeDataListComponentCollection_v1_0_0;

    return retVal;
}

export function mergeListInstanceComponents_v1_0_0(listComponentsArray: ListInstanceComponentThemeData_v1[]) {
    const lists = listComponentsArray.filter((c): c is ThemeDataPageList_v1 => isThemeDataListComponent_v1_0_0(c));
    const groupedLists: { [key: string]: ThemeDataPageList_v1[] } = _.groupBy(lists, (c) => c.listId);
    const mergedLists = Object.keys(groupedLists).map((key) => {
        // remove 'list' prefix from key. case insensitive
        const list = groupedLists[key];

        // take both lists and merge pageOccurrenceId into array
        const pageOccurrenceIds = list.map((l) => l.pageOccurrenceId);
        const merged = list.reduce((acc, listComponent) => {
            const merged2 = { ...acc, ...listComponent, pageOccurrenceIds: pageOccurrenceIds };
            const { pageOccurrenceId, ...retVal } = merged2;
            return retVal;
        }, {} as ThemeDataListQualifiedAndMerged_v1);
        return merged;
    });
    return mergedLists;
}

function getListQualifiedComponent(c: ThemeDataPageQualifiedListComponent_v1): ThemeDataPageListQualifiedComponent_v1 {
    if (isThemeDataListComponent_v1_0_0(c)) {
        const retVal = { ...c, lqId: c.listId };
        return retVal;

    } else {
        const retVal = { ...c, lqId: `${c.listId}.components.items.${c.id}` };
        return retVal;
    }
}

export function _getListComponentsFromPage_v1_0_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, node: WimlNode_v1_0_0, parentListId: string = '', parentListInstanceComponentId: string = ''): ThemeDataPageComponent_v1[] {
    // todo fix this because checing if is text node is not enough, what if <div> or <img> -- the answer: check ifIWmlComponent

    if (_isElementNode_v1_0_0(node)) {
        // console.group();
        BeginLog(node, parentListId, parentListInstanceComponentId);
        const abc = processListQualifiedNodeChildren_v1_0_0(runtimeComponents, node, parentListId, parentListInstanceComponentId, _getListComponentsFromPage_v1_0_0);

        const isList = _isListNode_v1_0_0(node);
        const currentListId = isList ? getListIdFromNodeAttrs_v1_0_0(node) : parentListId;
        const lookup = wimlComponents_v1_0_0[node.tag];
        // if (!lookup) {
        //     debugger;
        //     throw new Error('component ' + node.tag + ' not found');
        // }

        if (currentListId && lookup?.inputProps) {
            const nameFormatted = getInferredComponentDefaultType_v1_0_0(node.tag);

            const componentId = node.attr?.key || nameFormatted;

            const component = { key: componentId, type: node.tag, listId: currentListId };

            if (!component.key) throw new Error('component ' + node.tag + ' must have a key attribute');
            EndLog(node, parentListId, parentListInstanceComponentId);
            // console.groupEnd();
            return [...abc, component];
        } else {
            EndLog(node, parentListId, parentListInstanceComponentId);
            // console.groupEnd();
            return abc;
        }
    } else {
        EndLog(node, parentListId, parentListInstanceComponentId);
        // console.groupEnd();
        return [];
    }
}
function BeginLog(node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) {
    return;
    // const beginString = `Beginning processing of NODE:${node.tag} KEY:${node.attr?.key}     LISTID:${options.listId} parentListId:${parentListId} LISTCOMPONENTID:${options.listComponentId} parentListComponentId: ${parentListComponentId}`;
    // if (options.listId != parentListId || options.listComponentId != parentListComponentId) {
    //     console.warn(beginString);
    // } else {
    //     console.log(beginString);
    // }
}


function EndLog(node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) {
    return;
    // const endString = `End processing of NODE:${node.tag} KEY:${node.attr?.key}   LISTID:${options.listId} parentListId:${parentListId} LISTCOMPONENTID:${options.listComponentId} parentListComponentId: ${parentListComponentId}`;
    // if (options.listId != parentListId || options.listComponentId != parentListComponentId) {
    //     console.warn(endString);
    // } else {
    //     console.log(endString);
    // }
}
