import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback, useMemo, useRef } from 'react';
import StepZilla, { IStep } from "react-stepzilla";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ReactstrapInputBlurChangeDetector } from '../../../../../../form/input';
// @ts-ignore
import { updateOnboard, updateOnboardByAdvance, updateListItemComponentProperty, updatePageChildComponentProperty } from '@wip/common/event-store/website';

export function OnboardingInitialBio_v1_3_0(props: any) {
    const { register, formState, handleChangeEvent } = props;
    const lastStepIndex = useRef(0);
    const dispatch = useDispatch();

    const onInitialBioStepForward = (nextStep: number) => {
        const lastStepIndex = nextStep - 1;
        steps[lastStepIndex].customOnStepForward?.(lastStepIndex);
        if (nextStep < steps.length) {
            // the last stepzilla step will have nowhere to advance, skip onEnter
            steps[nextStep].customOnStepEnter?.(nextStep);
        }
    }

    const onInitialBioStepBackward = (nextStep: number) => {
        const lastStepIndex = nextStep + 1;
        steps[lastStepIndex].customOnStepBackward?.(lastStepIndex);
        steps[nextStep].customOnStepEnter?.(nextStep);
    }

    const initialBioStepChange = React.useCallback((nextStep: number) => {
        if (nextStep > lastStepIndex.current) {
            onInitialBioStepForward(nextStep);
        } else if (nextStep < lastStepIndex.current) {
            onInitialBioStepBackward(nextStep);
        } else {
            throw new Error('Invalid step change');
        }

        lastStepIndex.current = nextStep;

    }, [dispatch, props]);

    const steps: IStep[] = [
        {
            name: 'Step 1',
            component: <ThemeInspirationStep register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} />,
            customOnStepForward: (stepIndex: number) => {
                // defaultStepChangeFunction('not_started', dispatch);
                // // const onboardName = props.websiteData.onboard.name; -- may be empty if they don't change anything in form, as it defaults to about.name
                // const onboardName = formState.defaultValues.onboard.name;
                // dispatch(updatePageChildComponentProperty({ pageId: 'header', componentId: 'heading__name', propertyId: 'content', propertyValue: onboardName }));
            }
        },
        {
            name: 'Step 2',
            component: <NameStep register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} />,
            customOnStepForward: (stepIndex: number) => {
                defaultStepChangeFunction('not_started', dispatch);
                // const onboardName = props.websiteData.onboard.name; -- may be empty if they don't change anything in form, as it defaults to about.name
                const onboardName = formState.defaultValues.onboard.name;
                dispatch(updatePageChildComponentProperty({ pageId: 'header', componentId: 'heading__name', propertyId: 'content', propertyValue: onboardName }));
            }
        },
        { name: 'Step 3', component: <WritingPreferencesStepPart1 register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} /> },
        { name: 'Step 4', component: <WritingPreferencesStepPart2 register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} /> },
        { name: 'Step 5', component: <ContentProvidedStep register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} /> },
        {
            name: 'Step 6',
            component: <AddFirstBookStep register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} />,
            customOnStepForward: (stepIndex: number) => {
                defaultStepChangeFunction('initial_bio_started', dispatch);
            }
        }
    ];

    // https://github.com/vercel/styled-jsx/issues/615
    // octal in styled-jsx
    // https://github.com/newbreedofgeek/react-stepzilla/blob/master/src/css/main.css
    // stepzilla css
    return <div className='step-progress'>
        <StepZilla stepsNavigation={false} steps={steps} onStepChange={initialBioStepChange} />
        <style jsx>{`
            .step-progress :global(.multi-step) {
                display: flex;
                flex-direction: column;
            }

            .step-progress :global(.multi-step) :global(ol) {
                order: 1;
            }

            .step-progress :global(.footer-buttons) {
                margin-top: 50px;
            }

            .step-progress :global(.footer-buttons .btn-next) {
                margin-right: 10px;
            }
        `}</style>
        <style jsx global>{`
         ol.progtrckr {
            list-style-type: none;
            padding: 0;
            display: flex;
            justify-content: flex-start;
            }

            ol.progtrckr li {
            flex: 1 0; /* flex-grow:1 flex-shrink: 0 (implicit) flex-basis:0 (every child has equal width, regardless of content) */
            text-align: center;
            line-height: 4.5rem;
            cursor: pointer;
            }

            ol.progtrckr li span {
            /* padding: 0 1.5rem; */
            }

            @media (max-width: 650px) {
            .progtrckr li span {
                display: none;
            }
            }
            .progtrckr em {
            display: none;
            font-weight: 700;
            padding-left: 1rem;
            }

            @media (max-width: 650px) {
            .progtrckr em {
                display: inline;
            }
            }

            ol.progtrckr li.progtrckr-todo {
            color: silver;
            border-bottom: 4px solid silver;
            }

            ol.progtrckr li.progtrckr-doing {
            color: black;
            border-bottom: 4px solid #CCCCCC;
            }

            ol.progtrckr li.progtrckr-done {
            color: black;
            border-bottom: 4px solid #5cb85c;
            }

            ol.progtrckr li:after {
            /* content: "${'\\00a0\\00a0'}"; */
            }

            ol.progtrckr li:before {
            position: relative;
            bottom: -3.7rem;
            float: left;
            left: 50%;
            }

            ol.progtrckr li.progtrckr-todo:before {
            /*content: "${'\\039F'}";*/
            color: silver;
            background-color: white;
            width: 1.2em;
            line-height: 1.4em;
            }

            ol.progtrckr li.progtrckr-todo:hover:before {
            color: #ff4500;
            }

            ol.progtrckr li.progtrckr-doing:before {
           /* content: "${'\\2022'}";*/
            color: white;
            background-color: #CCCCCC;
            width: 1.2em;
            line-height: 1.2em;
            border-radius: 1.2em;
            }

            ol.progtrckr li.progtrckr-doing:hover:before {
            color: #ff4500;
            }

            ol.progtrckr li.progtrckr-done:before {
            content: "${'\\2713'}";
            color: white;
            background-color: #5cb85c;
            width: 1.2em;
            line-height: 1.2em;
            border-radius: 1.2em;
            }

            ol.progtrckr li.progtrckr-done:hover:before {
            color: #333;
            }
        `}</style>
    </div>;
}

const ThemeInspirationStep = forwardRef(function (props: React.PropsWithChildren<any>, ref) {
    const { register, formState, handleChangeEvent } = props;
    const errors = formState.errors;

    // https://github.com/newbreedofgeek/react-stepzilla/blob/master/src/examples/Step5.js
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    registerStepzillaValidationHook(ref, errors);

    // return <ImageDropzone />;
    return <>
        <h5 className="border-bottom pb-2 mb-4">Get started</h5>
        <ThemeModificationImageInspiration_v1_3_0 {...props} />
    </>;
});

const NameStep = forwardRef(function (props: React.PropsWithChildren<any>, ref) {
    const { register, formState, handleChangeEvent } = props;
    const errors = formState.errors;

    // https://github.com/newbreedofgeek/react-stepzilla/blob/master/src/examples/Step5.js
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    registerStepzillaValidationHook(ref, errors);

    const { ref: nameRef, ...nameRest } = register('onboard.name', { required: true });
    return (
        <>
            <h5 className="border-bottom pb-2 mb-4">About you</h5>
            <FormGroup>
                <Label for={nameRest.name}>Name</Label>
                <ReactstrapInputBlurChangeDetector id={nameRest.name} invalid={!!errors.onboard?.name} {...nameRest} innerRef={nameRef} onDiff={handleChangeEvent} />
                {Object.keys(errors).length ? <div>Name is required</div> : null}
            </FormGroup>
            <style jsx>{`
                :global(aside > section), :global(aside > div) {
                    filter: blur(3px) grayscale(.75);
                }
            `}</style>
        </>
    );
});
function registerStepzillaValidationHook(ref: React.ForwardedRef<unknown>, errors: any) {
    useImperativeHandle(ref, () => ({
        isValidated() {
            if (!!Object.keys(errors).length) {
                return false;
            } else {
                return true;
            }
        }
    }));
}

const WritingPreferencesStepPart1 = forwardRef(function (props: React.PropsWithChildren<any>, ref) {
    const { register, formState, handleChangeEvent } = props;
    const errors = formState.errors;

    // https://github.com/newbreedofgeek/react-stepzilla/blob/master/src/examples/Step5.js
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    registerStepzillaValidationHook(ref, errors);

    // note onChange is handled in react-hook-form 7.18
    const { ref: writesBooksRef, ...writesBooksRest } = register('onboard.writesBooks', { onChange: handleChangeEvent });
    const { ref: writesShortStoriesRef, ...writesShortStoriesRest } = register('onboard.writesShortStories', { onChange: handleChangeEvent });
    const { ref: writesPoetryRef, ...writesPoetryRest } = register('onboard.writesPoetry', { onChange: handleChangeEvent });
    const { ref: writesOtherRef, ...writesOtherRest } = register('onboard.writesOther', { onChange: handleChangeEvent });

    // how to do radio - https://stackoverflow.com/questions/67626696/radio-buttons-with-react-hook-form
    const { ref: bookCountFirstRef, ...bookCountFirstRest } = register('onboard.bookCount', { onChange: handleChangeEvent });
    const { ref: bookCountManyRef, ...bookCountManyRest } = register('onboard.bookCount', { onChange: handleChangeEvent });
    const { ref: bookCountOtherRef, ...bookCountOtherRest } = register('onboard.bookCount', { onChange: handleChangeEvent });

    const { ref: publishStatusSelfRef, ...publishStatusSelfRest } = register('onboard.publishStatus', { onChange: handleChangeEvent });
    const { ref: publishStatusTraditionalRef, ...publishStatusTraditionalRest } = register('onboard.publishStatus', { onChange: handleChangeEvent });
    const { ref: publishStatusOtherRef, ...publishStatusOtherRest } = register('onboard.publishStatus', { onChange: handleChangeEvent });

    return (
        <div>
            <h5 className="border-bottom pb-2 mb-4">About your books</h5>

            <div className="d-flex flex-column align-items-center">
                <h5>What do you write?</h5>
                <div className="d-flex options">
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={writesBooksRest.name} innerRef={writesBooksRef} {...writesBooksRest} /> {' '}
                            Books
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={writesShortStoriesRest.name} innerRef={writesShortStoriesRef} {...writesShortStoriesRest} /> {' '}
                            Short Stories
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={writesPoetryRest.name} innerRef={writesPoetryRef} {...writesPoetryRest} /> {' '}
                            Poetry
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={writesOtherRest.name} innerRef={writesOtherRef} {...writesOtherRest} /> {' '}
                            Other
                        </Label>
                    </FormGroup>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center mt-5">
                <h5>How many books have you written?</h5>
                <div className="d-flex options">
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" id={bookCountFirstRest.name + "_first"} value="first" innerRef={bookCountFirstRef} {...bookCountFirstRest} /> {' '}
                            First
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" id={bookCountManyRest.name + "_many"} value="many" innerRef={bookCountManyRef} {...bookCountManyRest} /> {' '}
                            Multiple
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" id={bookCountOtherRest.name + "_other"} value="other" innerRef={bookCountOtherRef} {...bookCountOtherRest} /> {' '}
                            Other
                        </Label>
                    </FormGroup>
                </div >
            </div >
            <div className="d-flex flex-column align-items-center mt-5">
                <h5>How have you published your books?</h5>
                <div className="d-flex options">
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" id={publishStatusSelfRest.name + "_self"} value="first" innerRef={publishStatusSelfRef} {...publishStatusSelfRest} /> {' '}
                            Self-Publishing
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" id={publishStatusTraditionalRest.name + "_traditional"} value="many" innerRef={publishStatusTraditionalRef} {...publishStatusTraditionalRest} /> {' '}
                            Traditional
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" id={publishStatusOtherRest.name + "_other"} value="other" innerRef={publishStatusOtherRef} {...publishStatusOtherRest} /> {' '}
                            Other
                        </Label>
                    </FormGroup>
                </div >
            </div >
            <style jsx>{`
                :global(aside > section), :global(aside > div) {
                    filter: blur(3px) grayscale(.75);
                }

                div.d-flex.options {
                    gap: 10px;
                }
            `}</style>
        </div >
    );
});

const WritingPreferencesStepPart2 = forwardRef(function (props: React.PropsWithChildren<any>, ref) {
    const { register, formState, handleChangeEvent } = props;
    const errors = formState.errors;

    // https://github.com/newbreedofgeek/react-stepzilla/blob/master/src/examples/Step5.js
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    registerStepzillaValidationHook(ref, errors);

    // note onChange is handled in react-hook-form 7.18
    const { ref: writesBooksRef, ...writesBooksRest } = register('onboard.writesBooks', { onChange: handleChangeEvent });
    const { ref: writesShortStoriesRef, ...writesShortStoriesRest } = register('onboard.writesShortStories', { onChange: handleChangeEvent });
    const { ref: writesPoetryRef, ...writesPoetryRest } = register('onboard.writesPoetry', { onChange: handleChangeEvent });
    const { ref: writesOtherRef, ...writesOtherRest } = register('onboard.writesOther', { onChange: handleChangeEvent });

    return (
        <div>
            <h5 className="border-bottom pb-2 mb-4">About your books</h5>

            <div className="d-flex flex-column align-items-center ">
                <h5>What do you need on your website?</h5>
                <div className="d-flex options">
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={writesBooksRest.name} innerRef={writesBooksRef} {...writesBooksRest} /> {' '}
                            Hosting
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={writesShortStoriesRest.name} innerRef={writesShortStoriesRef} {...writesShortStoriesRest} /> {' '}
                            Design
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={writesPoetryRest.name} innerRef={writesPoetryRef} {...writesPoetryRest} /> {' '}
                            Newsletter
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={writesOtherRest.name} innerRef={writesOtherRef} {...writesOtherRest} /> {' '}
                            Other
                        </Label>
                    </FormGroup>
                </div>
            </div>
            <style jsx>{`
                :global(aside > section), :global(aside > div) {
                    filter: blur(3px) grayscale(.75);
                }

                div.d-flex.options {
                    gap: 10px;
                }
            `}</style>
        </div >
    );
});

const ContentProvidedStep = forwardRef(function (props: React.PropsWithChildren<any>, ref) {
    const { register, formState, handleChangeEvent } = props;
    const errors = formState.errors;

    // https://github.com/newbreedofgeek/react-stepzilla/blob/master/src/examples/Step5.js
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    registerStepzillaValidationHook(ref, errors);

    // note onChange is handled in react-hook-form 7.18
    const { ref: contentProvidedAuthorPhotoRef, ...contentProvidedAuthorPhotoRest } = register('onboard.contentProvidedAuthorPhoto', { onChange: handleChangeEvent });
    const { ref: contentProvidedAuthorBioRef, ...contentProvidedAuthorBioRest } = register('onboard.contentProvidedAuthorBio', { onChange: handleChangeEvent });
    const { ref: contentProvidedBookCoverRef, ...contentProvidedBookCoverRest } = register('onboard.contentProvidedBookCover', { onChange: handleChangeEvent });
    const { ref: contentProvidedBookDescriptionRef, ...contentProvidedBookDescriptionRest } = register('onboard.contentProvidedBookDescription', { onChange: handleChangeEvent });
    const { ref: contentProvidedEventsRef, ...contentProvidedEventsRest } = register('onboard.contentProvidedEvents', { onChange: handleChangeEvent });
    const { ref: contentProvidedPressRef, ...contentProvidedPressRest } = register('onboard.contentProvidedPress', { onChange: handleChangeEvent });
    const { ref: contentProvidedOtherRef, ...contentProvidedOtherRest } = register('onboard.contentProvidedOther', { onChange: handleChangeEvent });
    const { ref: contentProvidedOtherDetailsRef, ...contentProvidedOtherDetailsRest } = register('onboard.contentProvidedOtherDetails', { onChange: handleChangeEvent });

    const contentProvidedOther = props.websiteData.onboard.contentProvidedOther;
    const writesOtherDetailsElement = contentProvidedOther
        ? <FormGroup className='align-self-end'>
            <ReactstrapInputBlurChangeDetector type="textarea" id={contentProvidedOtherDetailsRest.name} innerRef={contentProvidedOtherDetailsRef} {...contentProvidedOtherDetailsRest} onDiff={handleChangeEvent} /> {' '}
        </FormGroup>
        : null;
    return (
        <div>
            <h5 className="border-bottom pb-2 mb-4">Let's gather your content</h5>
            <div className="d-flex flex-column align-items-center">
                <h5 className="text-center">What content do you have ready to upload right now?</h5>
                <div className="options">
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={contentProvidedAuthorPhotoRest.name} innerRef={contentProvidedAuthorPhotoRef} {...contentProvidedAuthorPhotoRest} /> {' '}
                            Author photo
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={contentProvidedAuthorBioRest.name} innerRef={contentProvidedAuthorBioRef} {...contentProvidedAuthorBioRest} /> {' '}
                            Author bio
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={contentProvidedBookCoverRest.name} innerRef={contentProvidedBookCoverRef} {...contentProvidedBookCoverRest} /> {' '}
                            Book cover image
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={contentProvidedBookDescriptionRest.name} innerRef={contentProvidedBookDescriptionRef} {...contentProvidedBookDescriptionRest} /> {' '}
                            Book description
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={contentProvidedEventsRest.name} innerRef={contentProvidedEventsRef} {...contentProvidedEventsRest} /> {' '}
                            Events
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={contentProvidedPressRest.name} innerRef={contentProvidedPressRef} {...contentProvidedPressRest} /> {' '}
                            Press
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox" id={contentProvidedOtherRest.name} innerRef={contentProvidedOtherRef} {...contentProvidedOtherRest} /> {' '}
                            Other
                        </Label>
                    </FormGroup>
                </div>
                {writesOtherDetailsElement}
            </div>
            <style jsx>{`
                :global(aside > section), :global(aside > div) {
                    filter: blur(3px) grayscale(.75);
                }

                .options {
                    display: grid;
                    grid-template-rows: repeat(4, 1fr); /* Adjust this value as needed */
                    grid-auto-flow: column;
                    column-gap: 50px;
                }
            `}</style>
        </div >
    );
});

function AddFirstBookStep(props: React.PropsWithChildren<any>) {
    const { register, formState, handleChangeEvent } = props;
    const errors = formState.errors;

    const { ref: wimlMarkupRef, ...wimlMarkupRest } = register('style.wiml.themeData.markup', { required: true });

    return (
        <>
            <h5 className="border-bottom pb-2 mb-4">Ready to add your first book to your site?</h5>

            <FormGroup>
                <p>Now we are going to add your books. Click ok to proceed.</p>

                <style jsx>{`
                :global(.complete-bio-onboard) {
                    position: absolute;
                    left: 135px;
                    top: 179px;
                }

                /* Note: stepzilla, by default, will hide the button on last step. */
                :global(.multi-step .btn-prev){
                    display: initial !important;
                    background-color: ${props.themeConfig.buttonPrimaryColor};
                }
            `}</style>
            </FormGroup>
        </>
    );
}

// having each state mapped to a component would be ideal
// this almost works but causes modals to re-render because  its a different wrapper, but logically only the next step, but just with a modal close button. but this rerenders a popup,looks weird.
// we'd also have to re-attachs state to anything inside stepzilla
// const stateWrappers: Record<string, React.ComponentType<any>> = {
//     not_started: OnboardingInitialBioWrapper_v1_2_0,
//     initial_bio_started: OnboardingInitialBioWrapper_v1_2_0,
//     initial_bio_completed: OnboardingInitialThemeWrapper_v1_2_0,
// }
export function OnboardWimlManage_v1_holdsteady_0(props: React.PropsWithChildren<any>) {
    const websiteData = props.websiteData;
    const onboardStatus = websiteData.onboard.status;

    const onboardingDefaultValues = useMemo(() => {
        return {
            ...props.websiteData,
            onboard: {
                ...props.websiteData.onboard,
                name: props.websiteData.onboard.name || props.websiteData.about.firstName + ' ' + props.websiteData.about.lastName,
            }
        };
    }, [props.websiteData]);
    const defaultValues = onboardingDefaultValues;

    const { register, reset, formState, setValue, } = useForm({
        mode: 'onBlur',
        defaultValues: defaultValues,
    });
    const errors = formState.errors;

    const dispatch = useDispatch();

    // Update the input value whenever the about.firstName value changes
    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>, key: string, value: any) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                key = event.target.name;
                if (event.target.type == 'checkbox') {
                    value = event.target.checked;
                } else {
                    value = event.target.value;
                }
            }

            if (!key)
                throw new Error('Handle change event `key` is missing.');
            if (value == undefined)
                throw new Error('Handle change event `value` must be set to a value or null.');

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'onboard') {
                dispatch(updateOnboard({ key: newKey, value }));
            }
        }
    };

    // todo put these different elements in state machien too...
    // this almost works but causes modals to re-render because  its a different wrapper, but logically only the next step, but just with a modal close button. but this rerenders a popup,looks weird.
    let StateWrapper;
    if (websiteData.onboardStatusIsOnOrAfter('initial_theme_completed')) {
        StateWrapper = <OnboardingInitialSidebarWrapper_v1_3_0 register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} />;
    } else if (websiteData.onboardStatusIsOnOrAfter('initial_book_completed')) {
        StateWrapper = <OnboardingInitialThemeWrapper_v1_3_0 register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} />;
    } else if (websiteData.onboardStatusIsOnOrAfter('initial_bio_completed')) {
        StateWrapper = <OnboardingInitialBookWrapper_v1_3_0 register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} />;
    } else {
        StateWrapper = <OnboardingInitialBioWrapper_v1_3_0 register={register} formState={formState} handleChangeEvent={handleChangeEvent} {...props} />;
    }

    return <>
        {StateWrapper}
        <style jsx global>{`
            .sidebar-editor-controls {
                display: none !important;
            }
        `}</style>
    </>;
}


export function OnboardModal_v1_3_0(props: React.PropsWithChildren<any>) {
    return (
        <Modal isOpen={props.shouldDisplayOnboardingModal} toggle={props.toggle}>
            {/* <ModalHeader toggle={props.toggle}>Get Started</ModalHeader> */}
            <ModalBody>
                {props.children}
            </ModalBody>
            {
                /* <ModalFooter>
                   footer
                </ModalFooter> */
            }
        </Modal>
    );
}


export function OnboardingInitialBioWrapper_v1_3_0(props: React.PropsWithChildren<any>) {
    const websiteData = props.websiteData;
    const onboardStatus = websiteData.onboard.status;

    const dispatch = useDispatch();
    const { register, formState, handleChangeEvent } = props;
    const errors = formState.errors;

    // if toggle is undefined, the modal will not close
    // there is a potential flaw here where the user could reach a status, then back track, and if weren't for form
    // validation, we'd have an incomplete website
    // make toggle not visible if form is errors
    const toggle = useMemo(() => {
        if (!Object.keys(errors).length && websiteData.onboardStatusIsOnOrAfter('initial_bio_completed')) {
            return () => {
                props.onChange('shouldDisplayOnboardingModal', false);
            };
        } else {
            return undefined;
        }
        // the errors object itself doesn't change, so it cannot be a dependency, but the length of the errors object or any derivitive can
    }, [onboardStatus, Object.keys(errors).length]);


    const shouldDisplayOnboardingModal = props.shouldDisplayOnboardingModal ?? true;

    // todo put these different elements in state machien too
    return <>
        <OnboardModal_v1_3_0 {...props} toggle={toggle} shouldDisplayOnboardingModal={shouldDisplayOnboardingModal}>
            <OnboardingInitialBio_v1_3_0 {...props} />
        </OnboardModal_v1_3_0>
    </>
}

import Joyride, { Step } from 'react-joyride';
import { findNodeInTree_v1_3_0, findNodeParentByType_v1_3_0, flatNodeChildren_v1_3_0 } from './wiml-editor-v1.3.0';
import { _getThemeDataComponentId_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/theme-data/compiler/components';
import { bookCoverPhoto, bookCoverPhotoHeight, bookCoverPhotoWidth } from '@wip/common/domain/website';
import { getListChildComponentPropertyValueSiteData_v1_0_0 } from '@wip/common/app/wiml/versions/v1/v1.0.0/site-data/retrieval';
import { getListChildComponentPropertyValueSiteData_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/site-data/retrieval';
import { ThemeModificationImageInspiration_v1_3_0 } from './image-style-inspiration-v1.3.0';
const spotlightPadding = 10;

type CustomStep = Step & {
    customOnStepEnter?: (step: number) => void;
    customOnStepForward?: (step: number) => void;
    customOnStepBackward?: (step: number) => void;
};
export function OnboardingInitialBookWrapper_v1_3_0(props: React.PropsWithChildren<any>) {
    const [run, setRun] = useState(false);
    const [step, setStep] = useState(0);
    const lastStepIndex = useRef(0);
    const defaultImageCoverComponentId = 'image__cover';

    const handleDefaultImageAddEvent = (listId: string, listItemId: string) => {
        if (!listId || !listItemId) throw new Error('PageId, listId, or listItemId is missing.');

        const componentId = defaultImageCoverComponentId;

        const imgSrc = bookCoverPhoto;
        dispatch(updateListItemComponentProperty({ listId, listItemId, componentId, propertyId: 'url', propertyValue: imgSrc }));

        const width = bookCoverPhotoWidth;
        dispatch(updateListItemComponentProperty({ listId, listItemId, componentId, propertyId: 'width', propertyValue: width }));

        const height = bookCoverPhotoHeight;
        dispatch(updateListItemComponentProperty({ listId, listItemId, componentId, propertyId: 'height', propertyValue: height }));
    }

    const onboardingBookCoverStepContent = <>
        <p>
            Great! Add your book cover image, then click next.
        </p>
        <p>
            <strong>If you don't have one, just click skip.</strong> We'll provide you with a temporary book cover image, and you can change it when you're ready.
        </p>
        <Button
            size="sm"
            color="success" className="complete-book-cover" onClick={() => {
                const selector = '.react-joyride__tooltip button[data-test-id="button-primary"]';

                const button = document.querySelector(selector);
                if (button instanceof HTMLElement) {
                    // todo - use a custom tooltip component to avoid this, we can easily supply a skip button
                    // todo or just use setStep
                    // this doesn't work because it causes joyride to call handleStepChange immediately after we call handleStepChange
                    // becase our call changes the stepState and that re-invokes handleStepChange
                    // handleStepChange({ type: 'step:after', action: 'skip', index: steps.indexOf(onboardingBookCoverStep) });
                    button.click();
                } else {
                    throw new Error(`Could not find button with selector: ${selector}`);
                }
            }}>Skip</Button>

        <style jsx>{`
        :global(.complete-book-cover) {
            position: absolute;
            left: 265px;
            top: 236px;
        }
    `}</style>
    </>;

    const onboardingBookCoverStep: CustomStep = {
        disableBeacon: true,
        target: '#' + defaultImageCoverComponentId,
        // onboardingBookCoverStep is special because it has a skip button.
        // by default, react-joyride SKIP button will not skip a step, but END the tour with a tour:end action.
        // i cannot find a way to intercept this action, so i have to create a custom skip button.
        content: onboardingBookCoverStepContent,
        spotlightClicks: true,
        placement: 'right',
        customOnStepForward: (stepIndex: number) => {
            const listId = props.listId;
            const listItemId = props.listItemId;
            const lookupProps = {
                ...props,
                componentId: defaultImageCoverComponentId,
            }
            const doesItemHaveImage = getListChildComponentPropertyValueSiteData_v1_2_0(lookupProps, 'url', listId, listItemId);
            if (!doesItemHaveImage) {
                handleDefaultImageAddEvent(listId, listItemId);
            }
        },
    };

    const steps: CustomStep[] = [
        {
            // https://github.com/gilbarbara/react-joyride/discussions/719
            disableBeacon: true,
            target: '.sidebar-tab-page__books',
            content: <p>Let's add some books!</p>,
            spotlightClicks: true,
            hideFooter: true,
            placement: 'right-end',
        },
        {
            disableBeacon: true,
            target: '#list__books > button.btn-primary',
            content: <p>Click "Add" to start.</p>,
            spotlightClicks: true,
            hideFooter: true,
            placement: 'right-end',
            customOnStepEnter: (stepIndex: number) => {
                const rootNode = JSON.parse(props.websiteData.style.wiml.themeData.components.items.rootNodeJson);
                const pageNode = findNodeInTree_v1_3_0(rootNode, 'Page', 'books');
                const booksListNode = findNodeInTree_v1_3_0(pageNode, 'List', 'books');
                const parentNode = findNodeParentByType_v1_3_0(pageNode, booksListNode, 'Container');
                if (parentNode) {
                    const containerComponentId = _getThemeDataComponentId_v1_2_0({ type: parentNode.tag, key: parentNode.attr?.key });

                    props.onChange({
                        'containerId': containerComponentId,
                    });
                }
            },
            customOnStepForward: (stepIndex: number) => {
                defaultStepChangeFunction('initial_bio_completed', dispatch);
            },
        },
        {
            disableBeacon: true,
            target: '#heading__title',
            content: <p>Great! Now, type in your book title. Then click next.</p>,
            spotlightClicks: true,
            placement: 'right-end'
        },
        {
            disableBeacon: true,
            target: '#text__description',
            content: <p>Now, type in your book description. Then click next.</p>,
            spotlightClicks: true,
            placement: 'right-end'
        },
        onboardingBookCoverStep,
        {
            disableBeacon: true,
            target: '.preview-body',
            content: <p>Wow, look at that! Things are really coming together. Next, we'll change how your theme looks</p>,
            placement: 'center',
            customOnStepForward: (stepIndex: number) => {

                /*Copied from sidebar_v1_0_0. 
                todo: make a callabaale func, DRY */
                props.onChange('listId', null);
                props.onChange('listItemId', null);
                props.onChange('listInstanceComponentId', null);
                /*END*/

                props.onChange('selectedTab', 'page__home');
                defaultStepChangeFunction('initial_book_started', dispatch);
            },
        },
    ];

    const handleStepChange = (data: any) => {
        if (data.type == "error:target_not_found") throw new Error('Target not found');
        const { action, index, type } = data;
        if (type === 'step:after' && action === 'next') {
            /*
            ||
            // note: this is a custom skip button, see onboardingBookCoverStep
            type === 'step:after' && action === 'skip'
             */
            if (index == 2) {
                setStep(3);
            } else if (index == 3) {
                setStep(4);
            } else if (index == 4) {
                setStep(5);
            }

            handleInitialBookStepChange(index + 1);
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            // only way i could get overaly to appear on first render
            // https://github.com/gilbarbara/react-joyride/issues/519
            setRun(true);
        }, 200);
        return () => clearTimeout(timeout);
    }, []);
    const dispatch = useDispatch();

    const onInitialBookStepForward = (nextStep: number) => {
        const lastStepIndex = nextStep - 1;
        steps[lastStepIndex].customOnStepForward?.(lastStepIndex);
        if (nextStep < steps.length) {
            // the last stepzilla step will have nowhere to advance, skip onEnter
            steps[nextStep].customOnStepEnter?.(nextStep);
        }
    };

    const onInitialBookStepBackward = (nextStep: number) => {
        const lastStepIndex = nextStep + 1;
        steps[lastStepIndex].customOnStepBackward?.(lastStepIndex);
        steps[nextStep].customOnStepEnter?.(nextStep);
    };

    const handleInitialBookStepChange = React.useCallback((nextStep: number) => {
        if (nextStep > lastStepIndex.current) {
            onInitialBookStepForward(nextStep);
        } else if (nextStep < lastStepIndex.current) {
            onInitialBookStepBackward(nextStep);
        } else {
            throw new Error('Invalid step change');
        }

        lastStepIndex.current = nextStep;

        // the props dependency is necessary if any onAfter steps need it, e.g. listItem, pageId, etc.
    }, [dispatch, props]);

    useEffect(() => {
        if (props.selectedTab === 'page__books') {
            if (step === 0) {
                // setTimeout(() => {
                setStep(1);
                // }, 5000);
            }
        }
    }, [props.selectedTab]);

    useEffect(() => {
        if (props.listId === 'list__books') {
            if (step === 1) {
                // setTimeout(() => {
                setStep(2);
                // }, 2000);
            }
        }
    }, [props.listId, props.listItemId]);

    // disableScrollParentFix -> https://github.com/gilbarbara/react-joyride/issues/750#issuecomment-981846074 and https://github.com/gilbarbara/react-joyride/issues/563#issuecomment-565856031
    // this is needed because scrolling down a long page causes the overlay to break the whole page
    // it doesn't occur on fast computers, but when i throttle cpu, it happens. it happens on my older lpatop too.
    // overlay height https://github.com/gilbarbara/react-joyride/issues/603
    useDemoScrollIntoView(steps, step, run, spotlightPadding);
    return (
        <>
            <Joyride
                run={run}
                stepIndex={step}
                callback={handleStepChange}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                hideBackButton={true}
                spotlightPadding={spotlightPadding}
                // disableScrolling={true}
                disableScrollParentFix={true}
                // scrollDuration={2000}
                // scrollOffset={-50}
                continuous={true} // indicates this tour is 1 long tour, not stop and start
                styles={{
                    buttonClose: {
                        display: 'none',
                    },
                    // tooltipFooter: {
                    //     display: 'none',
                    // },
                    // tooltip: { maxHeight: '100%', overflow: 'hidden' },
                    // overlay: { height: '100%' },
                }}
                locale={{
                    last: 'Continue'
                }}
                steps={steps} />
        </>
    );
}

export function OnboardingInitialThemeWrapper_v1_3_0(props: React.PropsWithChildren<any>) {
    const [run, setRun] = useState(false);
    const [step, setStep] = useState(0);
    const lastStepIndex = useRef(0);

    const steps: CustomStep[] = [
        {
            // https://github.com/gilbarbara/react-joyride/discussions/719
            disableBeacon: true,
            target: '.sidebar-tab-dashboard',
            content: <p>Time to pick out your design.</p>,
            spotlightClicks: true,
            hideFooter: true,
            placement: 'right-end',
        },
        {
            disableBeacon: true,
            target: '.theme-selector-wrapper',
            content: <p>Choose from a variety of website deisgns and templates.</p>,
            spotlightClicks: true,
            hideFooter: true,
            placement: 'right-end',
            customOnStepForward: (stepIndex: number) => {
                defaultStepChangeFunction('initial_book_completed', dispatch);
            },
        },
        {
            disableBeacon: true,
            target: '.sidebar-editor-wrapper',
            content: <p>
                Great! Now, give it a design! When you're ready, click Next!
                <style jsx global>{`
                .react-joyride__spotlight {
                    width: calc(100vw - 105px) !important;
                }
            `}</style>
            </p>,
            spotlightClicks: true,
            placement: 'right-start',
        },
        {
            disableBeacon: true,
            target: '.sidebar-editor-controls .btn-primary',
            content: <p>
                This is shaping up nicely! Before moving forward, let's save your progress by clicking the "Save" button.
                <style jsx global>{`
                    .sidebar-editor-controls {
                        display: flex !important;
                    }

                    .sidebar-editor-controls .btn-primary {
                        filter: brightness(120%);
                        border: 1px dashed #000;
                    }

                    .sidebar-editor-controls .btn-danger {
                        user-select: none;
                        pointer-events: none;
                        filter: grayscale(.75);
                    }
            `}</style>
            </p>,
            spotlightClicks: true,
            hideFooter: true,
            placement: 'auto',
            customOnStepForward: (stepIndex: number) => {
                defaultStepChangeFunction('initial_theme_started', dispatch);
            },
        },
    ];

    const handleStepChange = (data: any) => {
        if (data.type == "error:target_not_found") throw new Error('Target not found');

        const { action, index, type } = data;
        if (type === 'step:after' && action === 'next') {
            /*
            ||
            // note: this is a custom skip button, see onboardingBookCoverStep
            type === 'step:after' && action === 'skip'
            */
            if (index == 2) {
                setStep(3);
            }

            handleInitialThemeStepChange(index + 1);
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            // only way i could get overaly to appear on first render
            // https://github.com/gilbarbara/react-joyride/issues/519
            setRun(true);
        }, 200);
        return () => clearTimeout(timeout);
    }, []);
    const dispatch = useDispatch();

    const onInitialThemeStepForward = (nextStep: number) => {
        const lastStepIndex = nextStep - 1;
        steps[lastStepIndex].customOnStepForward?.(lastStepIndex);
        if (nextStep < steps.length) {
            // the last stepzilla step will have nowhere to advance, skip onEnter
            steps[nextStep].customOnStepEnter?.(nextStep);
        }
    };

    const onInitialThemeStepBackward = (nextStep: number) => {
        const lastStepIndex = nextStep + 1;
        steps[lastStepIndex].customOnStepBackward?.(lastStepIndex);
        steps[nextStep].customOnStepEnter?.(nextStep);
    };

    const handleInitialThemeStepChange = React.useCallback((nextStep: number) => {
        if (nextStep > lastStepIndex.current) {
            onInitialThemeStepForward(nextStep);
        } else if (nextStep < lastStepIndex.current) {
            onInitialThemeStepBackward(nextStep);
        } else {
            throw new Error('Invalid step change');
        }

        lastStepIndex.current = nextStep;

        // the props dependency is necessary if any onAfter steps need it, e.g. listItem, pageId, etc.
    }, [dispatch, props]);

    useEffect(() => {
        if (props.selectedTab === 'dashboard') {
            if (step === 0) {
                setStep(1);
            }
        }
    }, [props.selectedTab]);

    useEffect(() => {
        if (props.styleSection === 'baseTheme') {
            if (step === 1) {
                setStep(2);
            }
        }
    }, [props.styleSection]);

    useEffect(() => {
        if (props.pendingChanges.length === 0) {
            if (step === 3) {
                // because this is last step, we have to use this to advance, setting state to steps.length + 1 (4 in this case, doesn't do anything, joyride quits).
                handleStepChange({ type: 'step:after', action: 'next', index: 3 });
            }
        }
    }, [props.pendingChanges]);

    useDemoScrollIntoView(steps, step, run, spotlightPadding);
    return (
        <>
            <Joyride
                run={run}
                stepIndex={step}
                callback={handleStepChange}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                hideBackButton={true}
                spotlightPadding={spotlightPadding}
                // disableScrolling={true}
                disableScrollParentFix={true}
                // scrollDuration={2000}
                // scrollOffset={-50}
                continuous={true}
                styles={{
                    buttonClose: {
                        display: 'none',
                    },
                    // tooltipFooter: {
                    //     display: 'none',
                    // },
                    // tooltip: { maxHeight: '100%', overflow: 'hidden' },
                    // overlay: { height: '100%' },
                }}
                locale={{
                    last: 'Next'
                }}
                steps={steps} />
        </>
    );
}



export function OnboardingInitialSidebarWrapper_v1_3_0(props: React.PropsWithChildren<any>) {
    const [run, setRun] = useState(false);
    const [step, setStep] = useState(0);
    const lastStepIndex = useRef(0);

    const user = props.userData;
    const isAdmin = user.roles.includes('admin');

    const supportChatTarget = (process.env.NODE_ENV === 'production' && !isAdmin) ? '#fc_frame' : 'header .dropdown.nav-item';
    const supportChatContent = (process.env.NODE_ENV === 'production' && !isAdmin) ? <>
        <p>Before you go! If you have any questions, reach out anytime.</p>
        <p>Just click on the help button right here.</p>
    </> : <>
        <p>Before you go! If you have any questions, reach out anytime.</p>
        <p><em>Note: You ({user.displayName}) are logged in as an admin user. Standard users can just click on the help button in the bottom right. But you are admin so that does not show (only for you). Standard users will see the help button.</em></p>
    </>

    const supportChatPlacement = (process.env.NODE_ENV === 'production' && !isAdmin) ? 'top' : 'auto';
    const steps: CustomStep[] = [
        {
            disableBeacon: true,
            target: '.preview-body',
            content: <>
                <p>Let's take a look at your website editor.</p>
                <p>First, this is your preview screen, which shows your website changes as you make them.</p>
            </>,
            spotlightClicks: true,
            placement: 'top-start',
        },
        {
            disableBeacon: true,
            target: '.sidebar-nav',
            content: <>
                <p>These tabs take you to each of your pages and settings.</p>
                <p>Click on a tab to edit the content on that page.</p>
            </>,
            spotlightClicks: true,
            placement: 'auto',
            customOnStepForward: (stepIndex: number) => {
                defaultStepChangeFunction('initial_theme_completed', dispatch);
            },
        },
        {
            disableBeacon: true,
            target: '.preview-body',
            content: <>
                <p>Great! You have the bones of your website here.</p>
                <p>You can exit the tutorial and begin adding additional content or pages.</p>
            </>,
            spotlightClicks: true,
            placement: 'center',
            customOnStepForward: (stepIndex: number) => {
                defaultStepChangeFunction('initial_sidebar_started', dispatch);
            },
        },
        {
            disableBeacon: true,
            target: supportChatTarget,
            content: supportChatContent,
            spotlightClicks: true,
            placement: supportChatPlacement,
            customOnStepForward: (stepIndex: number) => {
                defaultStepChangeFunction('initial_sidebar_completed', dispatch);
            },
        },
    ];

    const handleStepChange = (data: any) => {
        if (data.type == "error:target_not_found") throw new Error('Target not found');

        const { action, index, type } = data;
        if (type === 'step:after' && action === 'next') {
            if (index == 0) {
                setStep(1);
            } else if (index == 1) {
                setStep(2);
            } else if (index == 2) {
                setStep(3);
            }
            handleInitialThemeStepChange(index + 1);
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            // only way i could get overaly to appear on first render
            // https://github.com/gilbarbara/react-joyride/issues/519
            setRun(true);
        }, 200);
        return () => clearTimeout(timeout);
    }, []);
    const dispatch = useDispatch();

    const onInitialThemeStepForward = (nextStep: number) => {
        const lastStepIndex = nextStep - 1;
        steps[lastStepIndex].customOnStepForward?.(lastStepIndex);
        if (nextStep < steps.length) {
            // the last stepzilla step will have nowhere to advance, skip onEnter
            steps[nextStep].customOnStepEnter?.(nextStep);
        }
    };

    const onInitialThemeStepBackward = (nextStep: number) => {
        const lastStepIndex = nextStep + 1;
        steps[lastStepIndex].customOnStepBackward?.(lastStepIndex);
        steps[nextStep].customOnStepEnter?.(nextStep);
    };

    const handleInitialThemeStepChange = React.useCallback((nextStep: number) => {
        if (nextStep > lastStepIndex.current) {
            onInitialThemeStepForward(nextStep);
        } else if (nextStep < lastStepIndex.current) {
            onInitialThemeStepBackward(nextStep);
        } else {
            throw new Error('Invalid step change');
        }

        lastStepIndex.current = nextStep;

        // the props dependency is necessary if any onAfter steps need it, e.g. listItem, pageId, etc.
    }, [dispatch, props]);

    useDemoScrollIntoView(steps, step, run, spotlightPadding);
    return (
        <>
            <Joyride
                run={run}
                stepIndex={step}
                callback={handleStepChange}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                hideBackButton={true}
                spotlightPadding={spotlightPadding}
                // disableScrolling={true}
                disableScrollParentFix={true}
                // scrollDuration={2000}
                // scrollOffset={-50}
                continuous={true}
                styles={{
                    buttonClose: {
                        display: 'none',
                    },
                    // tooltipFooter: {
                    //     display: 'none',
                    // },
                    // tooltip: { maxHeight: '100%', overflow: 'hidden' },
                    // overlay: { height: '100%' },
                }}
                locale={{
                    last: 'Finish'
                }}
                steps={steps} />
        </>
    );
}



export const defaultStepChangeFunction = (fromStatus: string, dispatch: any) => {
    // putting a dispatch in a timeout is the only way to not break react-joyride. 
    // we have a reaction of events and it should work sync but my guess is reat-joyride needs time to synchronize, e.g. scroll detection.
    // this is similar to useDemoScrollIntoView. See: // To fix: go to node_modules/react-joyride/dist/index.mjs:1051 and 1009 and increase timeouts by 5x
    // update: Set a breakpoint node_modules/react-joyride/dist/index.mjs:1049 and notice it is not fired unless there is a timeout OR breakpoints are set in the debugger that cause enough of a delay
    // it has nothing to do with sync / async in redux dispatch.
    // when we have useEffect ->  set state      ->  useEffect ->                  -> dispatch
    //       'list id change' -> 'step change'   -> 'handleInitialBookStepChange'  -> dispatch 'update by advance'
    // dispatch(updateOnboardByAdvance({ fromStatus }));
    setTimeout(() => dispatch(updateOnboardByAdvance({ fromStatus })));
};

// todo remove this global step recipe - too confusing when adding or removing steps. add a method eg. onBefore (or onExit, rather) to each step and call it. will allow to remove the offset stuff.
const stepFunctions: { [key: number]: { onExit: (props: any, dispatch: any) => void } } = {
    6: {
        onExit: (props: any, dispatch: any) => {

        },
    },
    // 7: defaultStepChangeFunction('initial_bio_completed'),
    // 10: defaultStepChangeFunction('initial_book_started'),
};

// https://github.com/gilbarbara/react-joyride/issues/750
// fixes issues where changing tabs causes sidebar to change height
const useDemoScrollIntoView = (
    steps: Step[],
    stepIndex: number,
    run: boolean,
    spotlightPadding: number
) => {
    const scrollToElement = useCallback((element: HTMLElement) => {
        // const rect = element.getBoundingClientRect();

        // if hidden within a parent container for example
        // const hasClientRect = rect.top !== 0 || rect.left !== 0 || rect.bottom !== 0 || rect.right !== 0;

        // const buffer = 400;
        // const isBufferVisible = rect.top + buffer >= 0 && rect.left + buffer >= 0 && rect.bottom + buffer <= (window.innerHeight || document.documentElement.clientHeight) && rect.right + buffer <= (window.innerWidth || document.documentElement.clientWidth);

        // const isVisible = hasClientRect && isBufferVisible;
        // let timeout: NodeJS.Timeout | null = null;
        // if (!isVisible) {
        //     const scrollIntoViewOptions: ScrollIntoViewOptions = {
        //         behavior: "smooth",
        //         block: "start",
        //     };

        const timeout = setTimeout(() => {
            // todo, just keep checking if the element is visible, and if not, scroll to it
            // but be sure to clear timeouts
            // element.scrollIntoView(scrollIntoViewOptions);
            // look into scroll margins https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far/56391657#answer-67923821


            //     const absoluteElementTop = window.pageYOffset + rect.top;
            // const offset = 120; // Change this to the offset you want
            //     const scrollPosition = absoluteElementTop - offset;
            //     window.scrollTo({
            //         top: scrollPosition,
            //         behavior: "smooth"
            // });

            // const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
            // window.scrollTo({ top: y, behavior: 'smooth' });

            // this is a fix because the logic for overlay and spotlight is executing timeout 
            // before scroll for target is actually complete. 
            // To fix: go to node_modules/react-joyride/dist/index.mjs:1051 and 1009 and increase timeouts by 5x

            const overlay = document.getElementsByClassName('react-joyride__spotlight')[0];
            if (overlay instanceof HTMLElement) {
                // const rect = overlay.getBoundingClientRect();

                // const hasClientRect = rect.top !== 0 || rect.left !== 0 || rect.bottom !== 0 || rect.right !== 0;

                // const buffer = 20;
                // const isBufferVisible = rect.top + buffer >= 0 && rect.left + buffer >= 0 && rect.bottom + buffer <= (window.innerHeight || document.documentElement.clientHeight) && rect.right + buffer <= (window.innerWidth || document.documentElement.clientWidth);

                // const isVisible = hasClientRect && isBufferVisible;

                // if (!isVisible) {
                //     // set overlay top to element offset y
                //     overlay.style.top = `${element.getBoundingClientRect().top - buffer}px`;
                //     // overlay.style.top = `${element.offsetHeight}px`;
                // }

                const elementRect = element.getBoundingClientRect();
                const overlayRec = overlay.getBoundingClientRect();

                // const hasClientRect = overlayRec.top !== 0 || overlayRec.left !== 0 || overlayRec.bottom !== 0 || overlayRec.right !== 0;

                // const buffer = 20;
                // const isBufferVisible = overlayRec.top + buffer >= 0 && overlayRec.left + buffer >= 0 && overlayRec.bottom + buffer <= (window.innerHeight || document.documentElement.clientHeight) && overlayRec.right + buffer <= (window.innerWidth || document.documentElement.clientWidth);

                // const isVisible = hasClientRect && isBufferVisible;

                // if (!isVisible) {
                // set overlay top to element offset y
                overlay.style.top = `${elementRect.top - spotlightPadding}px`;
                // overlay.style.top = `${element.offsetHeight}px`;
                // }


            }

        }, 500);
        // }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);

    useEffect(() => {
        // stepIndex can equal steps.length because its needed to end the demo tour
        // It is a conditional here because there will be no step based on that stepIndex
        if (stepIndex < steps.length && run) {
            const step = steps[stepIndex];
            if (typeof step.target !== 'string') throw new Error('Step target must be a string');
            const selector = step.target.toString();
            const element = document.querySelector(selector);

            // todo return this as clear callback
            if (element instanceof HTMLElement) {
                return scrollToElement(element);
            }
        }
    }, [run, scrollToElement, stepIndex, steps]);
};
