import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateUtils, objectUtils } from '@wip/common';
import { getInferredComponentDefaultType_v1_0_0 } from '@wip/common/app/wiml/versions/v1/v1.0.0/theme-data/compiler/components';
import { wimlComponents_v1_0_0 } from '@wip/common/app/wiml/versions/v1/v1.0.0/theme-data/renderer/wiml-components-v1.0.0';
import { wimlComponents_v1_1_0 } from '@wip/common/app/wiml/versions/v1/v1.1.0/theme-data/renderer/wiml-components-v1.1.0';
import { wimlComponents_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/theme-data/renderer/wiml-components-v1.2.0';
import { sortListItems } from '@wip/common/domain/list-sort';
import { createNewListItem, deleteListItem, deleteRelationship, saveFile, updateAssets, updateListItemComponentProperty, updatePageChildComponentProperty, updateRelationship, updateStyle, } from '@wip/common/event-store/website';
import { move } from "@wip/common/lib/array-utils";
import { dateYYYYMMDD } from '@wip/common/lib/date-utils';
import { convertObjectToArray } from "@wip/common/lib/object-utils";
import imageCompression from 'browser-image-compression';
import humps from 'humps';
import _ from 'lodash';
import { useEffect, useMemo } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button, CustomInput, Form, FormGroup, Input, Label } from 'reactstrap';
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../../../../../form/input';
import { getListItemsComponents_v1_0_0 } from './list-items-v1.0.0';
import WimlProducts from './wiml-products-v1.0.0';

// todo make this  and the rest of the admin wiml-version-specific
export const DEFAULT_ADMIN_LIST_LABEL_KEY_v1_0_0 = 'heading.content.data.value';

// create a debounce function that accepts a function to run and a delay
export const resetDebouncer_v1_0_0 = _.debounce((reset) => {
    // update on below - i had updated react-hook-form from 7.4.0 to 7.51.4
    //     // note onChange is handled in react-hook-form 7.18 see apps/admin/src/components/manage/v4/wiml/versions/v1/v1.3.0/onboarding-v1.3.0.tsx:367
    // see branch git checkout react-hook-form-7-51-4
    // original: having 2 resets like this is the ONLY way I can get the form to reset to defaultValues
    //     // https://app.clickup.com/25740756/docs/rhhem-14790/rhhem-5390
    // https://github.com/orgs/react-hook-form/discussions/7589 
    reset(); // NOTE: this is needed in 7.51.4, see branch git checkout react-hook-form-7-51-4
    reset({}); // {} forces new list items to NOT resort to defaultValues  -- we want new list items to be pristine - this is how to do it
}, 800);

const defaultSelectionOption = <option value="" />;
const viewListOptions = [
    { key: "components:page-specific", value: "Show page-specific components (default)" },
    { key: "components:all", value: "Show all components" },
];
export default function WimlEditor(props) {
    return __WimlEditor(props, wimlComponents_v1_0_0);
};
export const __WimlEditor = (props, componentsMetaLookup) => {
    const wimlVersion = props.websiteData.style.wiml.themeData.meta?.version || "1.0.0";
    const dispatch = useDispatch();

    const handleDeleteListItem = () => {
        dispatch(deleteListItem({ listId: props.listId, listItemId: props.listItemId }));
        deselectListItem();
    };

    const deselectListItemRelationship = () => {
        props.onChange('relationshipAListId', null);
        props.onChange('relationshipAListItemId', null);
        props.onChange('relationshipBListId', null);
        props.onChange('relationshipBListItemId', null);
    };


    const handleDeleteListItemRelationship = () => {
        dispatch(deleteRelationship({
            listId: props.relationshipAListId, listItemId: props.relationshipAListItemId,
            relationshipListId: props.relationshipBListId, relationshipListItemId: props.relationshipBListItemId
        }));
        deselectListItemRelationship();
    };

    const deselectListItem = () => {
        props.onChange('listId', null);
        props.onChange('listItemId', null);
    };

    let defaultValues;

    const relationshipAListId = props.relationshipAListId;
    const relationshipAListItemId = props.relationshipAListItemId;
    const relationshipBListId = props.relationshipBListId;
    const relationshipBListItemId = props.relationshipBListItemId;
    const relationshipBIsNew = props.relationshipBIsNew;

    const selectedTab = props.selectedTab;
    const pageId = props.selectedTab;
    const listId = props.listId;
    const listItemId = props.listItemId;
    const componentId = props.componentId;
    if (listId) {
        defaultValues = props.websiteData.style.wiml.siteData?.components?.items?.lists?.items?.[listId]?.items?.items?.[listItemId]?.components?.items;
    } else {
        defaultValues = props.websiteData.style.wiml.siteData?.components?.items?.pages?.items?.[props.selectedTab]?.components?.items;
    }


    function uploadImage(file) {
        const options = {
            maxSizeMB: .5, // this should probably match the firebase rules
            maxWidthOrHeight: 1500,
            useWebWorker: true
        };

        const workPromise = imageCompression(file, options)
            .then(compressedFile => dispatch(saveFile(compressedFile)));

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: file => uploadImage(file).then(action => action.payload) };

        return retVal;
    }, [dispatch]);

    // useEffect(() => {
    //     // https://app.clickup.com/25740756/docs/rhhem-14790/rhhem-5390
    //     resetDebouncer(reset, defaultValues);
    // }, [reset, defaultValues]);

    const { register, reset, formState } = useForm({
        mode: 'onBlur', //todo is it possible to make ONLY onBlur and exclude onChange?
        defaultValues: defaultValues,
    });

    // this is different from v3 because each selected tab uses its own form. but there is a bug where the first page (Home) will not show pending changes (e.g. customHtml) until you go to 1 tab then back.
    useEffect(() => {
        const c = defaultValues;
        resetDebouncer_v1_0_0(reset);
    }, [reset, selectedTab, listItemId]);

    const errors = formState.errors;
    // kind of annoying - https://github.com/react-hook-form/react-hook-form/issues/4414
    //regsiter with default values, then update the default values
    const { ref: wimlMarkupRef, ...wimlMarkupRest } = register('style.wiml.themeData.markup', { required: true, value: props.websiteData.style.wiml.themeData.markup });
    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')


            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'style') {
                dispatch(updateStyle({ key: newKey, value }));
            }

        }
    };

    const handleRichTextEditorChangeEvent = ({ pageId, listId, listItemId }, componentId, propertyId, propertyValue) => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            dispatchUpdateComponentProperty({ pageId, listId, listItemId }, componentId, propertyId, propertyValue);
        }
    }
    const handleDragEnd = (result) => {
        // this assumes we're dragging into the same list. if we change this, you must use draggableId
        const { destination, source, draggableId } = result;
        if (!destination) return;
        if (destination.droppableId == source.droppableId && destination.index == source.index) return;

        const soucePageQualifiedListId = source.droppableId.split('.');
        const sourcePageId = soucePageQualifiedListId[0];
        const sourceListId = soucePageQualifiedListId[1];

        const destinationPageQualifiedListInstanceComponentId = destination.droppableId.split('.');
        const destinationPageId = destinationPageQualifiedListInstanceComponentId[0];
        const destinationListId = destinationPageQualifiedListInstanceComponentId[1];
        const destinationListInstanceComponentId = destinationPageQualifiedListInstanceComponentId.slice(2).join('.');

        const listItemId = draggableId;

        // handle existing sort order
        const destinationListComponent = props.websiteData.style.wiml.siteData.components.items.pages.items[destinationPageId]?.components?.items?.[destinationListInstanceComponentId];
        const sortExpression = destinationListComponent?.sort?.data?.value;
        const listItems = props.websiteData.style.wiml.siteData.components.items.lists.items[destinationListId].items.items;
        const listItemsArr = objectUtils.convertObjectToArray(listItems);
        let sortedItemsArr = listItemsArr;
        if (sortExpression) {
            if (!sortExpression.startsWith("{{manualOrder")) {
                throw new Error("Manual order is not supported when sorting is enabled.")
            }
            sortedItemsArr = sortListItems(sortExpression, listItemsArr);
        }

        const newSortedItems = move(sortedItemsArr, source.index, destination.index);
        const newOrderedIds = newSortedItems.map((x, index) => ({ [x.id]: index }));
        const newExpression = newOrderedIds.reduce((accum, next) => {
            const objId = Object.keys(next)[0];
            accum[objId] = next[objId];
            return accum;
        }, {})
        const newSortExpression = `{{manualOrder(current_item.id,${JSON.stringify(newExpression)})}}`;

        dispatchUpdateComponentProperty({ pageId: destinationPageId }, destinationListInstanceComponentId, 'sort', newSortExpression);
    };

    const handlePropertyChangeEvent = (event) => {

        // todo - look for onChange and run it e.g. event-detail.js
        /*
            src/components/manage/event-detail.js
           const handleEventDateChangeEvent = async (e) => {
            dateRest.onChange && await dateRest.onChange(e);

            const id = e.target.dataset.id;
            const key = e.target.name;
            const value = dateUtils.parseDate(e.target.value);
            handleChangeEvent(null, id, key, value);
          };
     */


        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const pageId = event.target.dataset.pageId;
            const listId = event.target.dataset.listId;
            const listItemId = event.target.dataset.listItemId;

            const name = event.target.name;

            let propertyValue;
            if (event.target.type == 'checkbox') {
                propertyValue = event.target.checked;
            }
            else if (event.target.type == 'number') {
                // todo put this logic in domain layer
                propertyValue = event.target.value ? parseFloat(event.target.value) : null;
            }
            else if (event.target.type == 'date') {
                // todo put this logic in domain layer
                // keep the date as a string, but convert it to a date object for timezone
                propertyValue = event.target.value ? dateUtils.parseDate(event.target.value).toISOString() : null;
                // console.log({ propertyValue })
            }
            else {
                propertyValue = event.target.value;
            }

            const splitKeys = name.split('.');
            // const componentId = splitKeys[0];
            // const propertyId = splitKeys[1];
            // if splitKeys[startinIndex] starts with "list", then iterate or recur until it doesn't
            const findIndex = splitKeys.findIndex(key => !key.startsWith('list')) - 1;
            const startIndex = findIndex !== -1 ? findIndex : 0;

            const componentId = splitKeys.slice(0, startIndex + 1).join('.');
            const propertyId = splitKeys[startIndex + 1];

            dispatchUpdateComponentProperty({ pageId, listId, listItemId }, componentId, propertyId, propertyValue);
        }
    };

    const dispatchUpdateComponentProperty = ({ pageId, listId, listItemId }, componentId, propertyId, propertyValue) => {
        if (!pageId && (!listId || !listItemId)) throw new Error('Handle change event `pageId` or `listId` must be set to a value or null.')
        if (!componentId) throw new Error('Handle change `componentKey` is missing.')
        if (!propertyId) throw new Error('Handle change `propertyId` is missing.')
        if (typeof propertyValue == 'undefined') throw new Error('Handle change event `value` must be set to a value or null.')

        let retVal;

        if (pageId) {
            retVal = dispatch(updatePageChildComponentProperty({ pageId, componentId, propertyId, propertyValue }));
        } else if (listId) {
            retVal = dispatch(updateListItemComponentProperty({ listId, listItemId, componentId, propertyId, propertyValue }));
        }

        return retVal;
    };

    const dispatchCreateNewListItem = (listId, listInstanceComponentId) => {
        const retVal = dispatch(createNewListItem({ listId }));

        props.onChange('listId', listId);
        props.onChange('listItemId', retVal.payload.id);
        props.onChange('listInstanceComponentId', listInstanceComponentId);

        return retVal;
    };

    const onImageAdd = event => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const pageId = event.target.dataset.pageId;
            const listId = event.target.dataset.listId;
            const listItemId = event.target.dataset.listItemId;

            const name = event.target.name;
            const imageFile = event.target.files[0];

            const splitKeys = name.split('.');
            const path = splitKeys[0];
            const propertyId = splitKeys[1]; // url prop

            // dispatch async thunks are promises
            // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
            uploadImage(imageFile).then(action => {
                const downloadUrl = action.payload;
                const value = downloadUrl;
                dispatchUpdateComponentProperty({ pageId, listId, listItemId }, path, propertyId, value);
                event.target.value = "";
            });
        }
    };

    const onImageRemove = e => {
        const key = e.target.dataset.key;
        dispatch(updateAssets({ key, value: null }));
    };


    const handleRelationshipChangeEvent = (event) => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const eventValue = event.target.value;
            if (event.target.dataset.relationshipBListId != null) {
                props.onChange('relationshipBListId', eventValue);
                props.onChange('relationshipBListItemId', null);
            } else if (event.target.dataset.relationshipBListItemId != null) {
                // it's possible they set this to empty, so don't dispatch an update
                if (eventValue) {
                    props.onChange('relationshipBListItemId', eventValue);
                    dispatchUpdateRelationship(relationshipAListId, relationshipAListItemId, relationshipBListId, eventValue);
                }
            } else {
                throw new Error('Handle change event `relationshipBListId` or `relationshipBListItemId` must be set to a value or null.')
            }
        }
    };

    const dispatchUpdateRelationship = (relationshipAListId, relationshipAListItemId, relationshipBListId, relationshipBListItemId) => {
        if (!relationshipAListId || !relationshipAListItemId || !relationshipBListId || !relationshipBListItemId) throw new Error('Handle change event `pageId` or `listId` must be set to a value or null.')

        let retVal;

        retVal = dispatch(updateRelationship({ listId: relationshipAListId, listItemId: relationshipAListItemId, relationshipListId: relationshipBListId, relationshipListItemId: relationshipBListItemId }));

        return retVal;
    };

    let componentsToEdit = null;

    if (relationshipAListId) {
        // const typeListOptions = property.options.map((option) => {
        //     return (
        //         <option key={option.key} value={option.key}>
        //             {option.value}
        //         </option>
        //     );
        // });\

        // todo merge w/ defaultValues prop
        const { ref: listIdAInputRef, ...listIdAInputRest } = register("relationshipAListId", { value: relationshipAListId });
        const { ref: listItemIdAInputRef, ...listItemIdAInputRest } = register("relationshipAListItemId", { value: relationshipAListItemId });
        const { ref: listIdBInputRef, ...listIdBInputRest } = register("relationshipBListId", { value: relationshipBListId });
        const { ref: listItemIdBInputRef, ...listItemIdBInputRest } = register("relationshipBListItemId", { value: relationshipBListItemId });
        const listIdBOptions = props.websiteData.style.wiml.siteData?.components?.items?.lists?.ids;

        const relationshipBListIdOptions = listIdBOptions.map((option) => {
            return (
                <option key={option} value={option}>
                    {option}
                </option>
            );
        });

        const relationshipBListItemIdOptions = convertObjectToArray(props.websiteData.style.wiml.siteData?.components?.items?.lists?.items?.[relationshipBListId]?.items?.items).map((option) => {
            return (
                <option key={option.id} value={option.id}>
                    {option.components?.items?.heading?.content?.data?.value || option.id}
                </option>
            );
        });
        // const relationshiptDataId = { "data-relationship-a-list-id": listId, "data-relationship-a-list-item-id": listItemId };
        const deleteButton = <Button className='ml-3' color="danger" onClick={handleDeleteListItemRelationship}>
            delete
        </Button>;

        componentsToEdit = (
            <>
                <h4>List Item A</h4>
                <Input type="select" innerRef={listIdAInputRef} {...listIdAInputRest} disabled>
                    <option value={relationshipAListId}>
                        {relationshipAListId}
                    </option>
                </Input>
                <Input type="select" innerRef={listItemIdAInputRef} {...listItemIdAInputRest} disabled>
                    <option value={relationshipAListItemId}>
                        {relationshipAListItemId}
                    </option>
                </Input>
                <h4>List Item B</h4>
                <Input type="select" innerRef={listIdBInputRef} {...listIdBInputRest} onChange={handleRelationshipChangeEvent} disabled={!relationshipBIsNew} data-relationship-b-list-id>
                    {defaultSelectionOption}
                    {relationshipBListIdOptions}
                </Input>
                <Input type="select" innerRef={listItemIdBInputRef} {...listItemIdBInputRest} onChange={handleRelationshipChangeEvent} disabled={!relationshipBIsNew} data-relationship-b-list-item-id>
                    {defaultSelectionOption}
                    {relationshipBListItemIdOptions}
                </Input>
                <Button color="primary" onClick={deselectListItemRelationship}>
                    back
                </Button>
                {props.relationshipBIsNew ? null : deleteButton}
            </>
        );
    }
    else if (listId) {
        const listItemComponents = props.websiteData.style.wiml.themeData.components.items.lists?.items?.[listId]?.components;
        const listItemComponentsReact = getListItemComponents(listId, listItemId, listItemComponents, props.selectedTab, props.listInstanceComponentId, props.listItemViewFormatKey, props.websiteData);
        const relationshipItemComponentsReact = getListItemRelationshipComponents(listId, listItemId,);

        componentsToEdit = (
            <>
                <div className='mb-5'>
                    <Button color="primary" onClick={deselectListItem}>
                        back
                    </Button>
                    <Button className='ml-3' color="danger" onClick={handleDeleteListItem}>
                        delete
                    </Button>
                </div>
                {/* <div>{JSON.stringify(itemData, null, 2)}</div> */}
                {listItemComponentsReact}
                {relationshipItemComponentsReact}
            </>
        );
    } else {
        const pages = props.websiteData.style.wiml.themeData.components.items.pages.items;
        const page = pages?.[pageId];

        const components = page?.components;
        // todo migrate everyone
        componentsToEdit = getPageComponents_v1_0_0(pageId, components, componentsMetaLookup);
    }
    const isAdmin = props.userData.roles.includes('admin');
    let wimlInput = null

    if (isAdmin) {
        const listId = props.listId;
        const listItemId = props.listItemId;

        let listItemElem = null;

        if (listId) {

            const selectListItemViewFormat = (e) => {
                props.onChange('listItemViewFormatKey', e.target.value);
            };

            const viewListOptionElems = viewListOptions.map((option) => {
                return (
                    <option key={option.key} value={option.key}>
                        {option.value}
                    </option>
                );
            });

            const listItemViewFormatInputComponent = (
                <Input type="select" value={props.listItemViewFormatKey} onChange={selectListItemViewFormat}>
                    {viewListOptionElems}
                </Input>
            );


            listItemElem = (
                <div className="my-2">
                    <h6>List: {listId}</h6>
                    <h6>List Item: {listItemId}</h6>
                    {listItemViewFormatInputComponent}
                </div>
            );
        }

        wimlInput = (
            <>
                <FormGroup>
                    <Label for="style.wiml">WIML</Label>
                    <ReactstrapInputBlurChangeDetector invalid={!!errors.wiml} type="textarea" rows={20} {...wimlMarkupRest} innerRef={wimlMarkupRef} onDiff={handleChangeEvent} />
                </FormGroup>
                <div className="text-muted mb-4">
                    <h6>Website: {props.websiteData.id}</h6>
                    {listItemElem}
                </div>
            </>
        );
    }

    let extraComponents = null;

    if (pageId == 'products') {
        extraComponents = <WimlProducts {...props} />;
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Form>
                {wimlInput}
                {extraComponents}
                {componentsToEdit}
            </Form>
        </DragDropContext>
    );

    function getPageComponents_v1_0_0(pageId, components, componentsMetaLookup) {
        const componentsArray = components?.ids?.map((componentId) => {
            // todo fix because id should be in the component
            const component = { ...components.items[componentId], id: componentId };
            if (component.themeProps?.adminDisplay != 'none') {
                const properties = getComponentProperties_v1_0_0(props, component, { pageId }, componentsMetaLookup);

                return getComponentFormGroup(component, properties);
            }
        }).filter(Boolean);

        const retVal = componentsArray;
        return retVal;
    }

    function getListItemComponents(listId, listItemId, components, pageId, listInstanceComponentId, listItemViewFormatKey, websiteData) {
        if (!listId) throw new Error('listId is required')
        if (!listItemId) throw new Error('listItemId is required');
        if (!components) throw new Error('components is required');
        if (!pageId) throw new Error('pageId is required');
        if (!listInstanceComponentId) throw new Error('listInstanceComponentId is required');
        if (!listItemViewFormatKey) throw new Error('listItemViewFormatKey is required');
        if (!websiteData) throw new Error('websiteData is required');

        let retrievalIds;
        if (listItemViewFormatKey === 'components:page-specific') {
            let newPageId = pageId;
            if (newPageId == 'books' && websiteData.style.wiml.themeData.components.items.pages.ids.includes('book')) {
                newPageId = 'book';
            } else if (newPageId == 'blog' && websiteData.style.wiml.themeData.components.items.pages.ids.includes('post')) {
                newPageId = 'post';
            }
            retrievalIds = websiteData.style.wiml.themeData.components.items.pages.items[newPageId]?.components?.items?.[listInstanceComponentId]?.components?.ids;
        } else if (listItemViewFormatKey === 'components:all') {
            retrievalIds = components.ids;
        } else {
            throw new Error(`listItemViewFormatKey ${listItemViewFormatKey} is not supported`);
        }

        const componentsArray = retrievalIds.map((componentId) => {
            const component = components.items[componentId];
            if (component.themeProps?.adminDisplay != 'none') {
                const properties = getComponentProperties_v1_0_0(props, component, { listId, listItemId }, componentsMetaLookup);
                return getComponentFormGroup(component, properties);
            }
        }).filter(Boolean);

        const retVal = componentsArray;
        return retVal;
    }

    function getListItemRelationshipComponents(listId, listItemId,) {
        let listItemRelationships = null;

        const relatedLists = props.websiteData.style.wiml.siteData.components.items.lists?.items?.[listId]?.items.items?.[listItemId]?.relationships?.lists?.items;

        let itemComponents = null;

        if (relatedLists) {
            const relatedListsArray = convertObjectToArray(relatedLists);
            itemComponents = relatedListsArray.map((relatedList) => {
                // const listItemComponent = (
                //     <div key={item.id}><ListItemRelationship item={item} component={component} onSelectListItemRelationship={selectListItemRelationship} /></div>
                // );
                const relatedListItemsArray = convertObjectToArray(relatedList.items);
                const listItemRelationshipComponents = relatedListItemsArray.map((relatedListItem) => {
                    // const labelValue = dot.pick(labelKey, item.components?.items) || `<i title=${labelKey}>EMPTY</i>`;
                    const selectListItemRelationship = () => {
                        props.onChange('relationshipAListId', listId);
                        props.onChange('relationshipAListItemId', listItemId);
                        props.onChange('relationshipBListId', relatedList.id);
                        props.onChange('relationshipBListItemId', relatedListItem.id);
                        props.onChange('relationshipBIsNew', false);
                    };
                    const listItemRelationshipComponent = (
                        <div key={relatedList.id + '_' + relatedListItem.id} >
                            <ListItemRelationship relatedListId={relatedList.id} relatedListItemId={relatedListItem.id} onClick={selectListItemRelationship} />
                        </div>

                    );

                    return listItemRelationshipComponent;
                });


                return listItemRelationshipComponents;
            });
        }

        const addNew = () => {
            props.onChange('relationshipAListId', listId);
            props.onChange('relationshipAListItemId', listItemId);
            props.onChange('relationshipBIsNew', true);
        };

        listItemRelationships = (
            <>
                <h6>Relationships</h6>
                <Button color="primary" onClick={addNew}>
                    Add
                </Button>
                {itemComponents}
            </>
        );
        const retVal = (
            <>
                {listItemRelationships}
            </>
        );

        return retVal;
    }

    function getComponentProperties_v1_0_0(props, component, { pageId, listId, listItemId }, componentsMetaLookup) {
        const propertyMeta = {
            "List.filter": false,
            "List.sort": false,
            "Image.width": false,
            "Image.height": false,
        }
        const isAdmin = props.userData.roles.includes('admin');

        const componentMeta = componentsMetaLookup[component.type];
        const componentDataId = { "data-page-id": pageId, "data-list-id": listId, "data-list-item-id": listItemId };

        let inputPropComponents = null;
        const inputProps = componentMeta.inputProps;
        if (inputProps) {
            inputPropComponents = Object.keys(inputProps)
                .filter((inputKey) => {
                    if (isAdmin) return true;

                    const fqPropKey = `${component.type}.${inputKey}`;
                    const isFiltered = propertyMeta[fqPropKey] === false;
                    return !isFiltered;
                })
                .map((inputKey) => {

                    const property = inputProps[inputKey];
                    let inputPropComponent = null;

                    if (Array.isArray(property)) {
                        throw new Error('Array input props are not supported.');
                    } else {
                        let inputComponent;

                        const inputType = property.type;
                        const inputName = `${component.id}.${inputKey}.data.expression`;
                        let defaultValue = defaultValues?.[component.id]?.[inputKey]?.data.expression;
                        if (inputType == 'date' && defaultValue) {
                            defaultValue = dateUtils.dateYYYYMMDD(new Date(defaultValue));
                        }
                        const inputLabel = property.label;
                        const inputDescription = property.description;
                        // use register to set a default value

                        const { ref: formInputRef, ...formInputRest } = register(inputName, { value: defaultValue });
                        // todo DRY THIS UP
                        switch (inputType) {
                            case 'number': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="number" step="0.01" onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'select': {
                                const typeListOptions = property.options.map((option) => {
                                    return (
                                        <option key={option.key} value={option.key}>
                                            {option.value}
                                        </option>
                                    );
                                });

                                inputComponent = (
                                    <Input type="select" id={inputName} name={inputName} innerRef={formInputRef} {...formInputRest} onChange={handlePropertyChangeEvent} {...componentDataId}>
                                        {defaultSelectionOption}
                                        {typeListOptions}
                                    </Input>
                                );
                                break;
                            }
                            case 'url': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="url" onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'short_text': {
                                inputComponent = <ReactstrapInputBlurChangeDetector onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'date': {

                                // https://stackoverflow.com/questions/49277112/react-js-how-to-set-a-default-value-for-input-date-type

                                /**
                                 *  const eventDate = props.event.schedule.date;
        const eventDateFormat = eventDate ? dateUtils.dateYYYYMMDD(new Date(eventDate)) : null;
        const schedule = { ...props.event.schedule, date: eventDateFormat };
        const abc = { ...props.event, schedule };
        const { register, formState: { errors } } = useForm({
            mode: 'onBlur',
            defaultValues: abc
        });
        
        
        src/components/manage/event-detail.js
                                 */
                                const defaultValueDate = defaultValue ? dateYYYYMMDD(new Date(defaultValue)) : null;
                                inputComponent = (
                                    // very important -- onChange must come last -- see above and see src/components/manage/event-detail.js
                                    <ReactstrapInputBlurChangeDetector type="date" id={inputName} name={inputName} innerRef={formInputRef} {...formInputRest} onChange={handlePropertyChangeEvent} {...componentDataId} defaultValue={defaultValueDate} />
                                );
                                break;
                            }
                            case 'medium_text': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="textarea" rows={2} onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'long_text': {
                                const onDiff = (newValue) => {
                                    return handleRichTextEditorChangeEvent({ pageId, listId, listItemId }, component.id, inputKey, newValue);
                                };
                                // Q: why does rich text get a unique key? A: https://app.clickup.com/25740756/docs/rhhem-14790/rhhem-5390
                                const uniqueKey = [component.id, inputKey, pageId, listId, listItemId].join('-');
                                // console.log({ uniqueKey })
                                inputComponent = <RichTextEditor key={uniqueKey} defaultValue={defaultValue} onDiff={onDiff} {...richTextConfig} />;
                                break;
                            }
                            case 'json': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="textarea" rows={5} onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'boolean': {
                                inputComponent = <CustomInput type="switch" id={inputName} name={inputName} innerRef={formInputRef} {...formInputRest} onChange={handlePropertyChangeEvent} {...componentDataId} />;
                                break;
                            }
                            case 'list_item': {
                                throw new Error('Array input props are not supported.');
                            }
                            case 'image': {
                                const imageUrl = defaultValue;
                                inputComponent = (
                                    <>
                                        <Input name={inputName} type="file" accept="image/*" {...componentDataId} onChange={onImageAdd} /*todo: put this in global style={{ color: "transparent" }}*/ />
                                        <ReactstrapInputBlurChangeDetector type="url" onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />

                                        {imageUrl &&
                                            <>
                                                <div>
                                                    <Button outline color="primary" data-key='Image' onClick={onImageRemove}>
                                                        <FontAwesomeIcon icon={faTrash} /> Remove
                                                    </Button>
                                                </div>
                                                <div>
                                                    <img src={imageUrl} className="img-fluid shadow mt-4" />
                                                </div>
                                            </>}

                                    </>
                                );
                                break;
                            }
                            default: {
                                throw new Error(`Unknown input type: ${inputType}`);
                            }
                        }

                        const reactComponent = (
                            <FormGroup key={inputKey}>
                                <Label for={inputName} title={inputDescription}>{inputLabel}</Label>
                                {inputComponent}
                            </FormGroup>
                        );
                        inputPropComponent = reactComponent;
                    }

                    return inputPropComponent;
                });
        }

        let listItemComponents = null;

        const listProps = componentMeta.listProps;
        if (listProps) {
            listItemComponents = getListItemsComponents_v1_0_0(pageId, component, props, dispatchCreateNewListItem);
        }
        const retVal = (
            <>
                {inputPropComponents}
                {listItemComponents}
            </>
        );

        return retVal;
    }
}

const ListItemRelationship = props => {
    const relatedListId = props.relatedListId;
    const relatedListItemId = props.relatedListItemId;

    const retVal = (
        <Button color="primary" onClick={props.onClick}>
            <h6>{relatedListId} | {relatedListItemId}</h6>
        </Button>
    );
    return retVal;
};
function getComponentFormGroup(component, properties) {
    const componentKey = getInferredComponentDefaultType_v1_0_0(component.type);

    let header;

    // id is a concat of type and id, key is just id
    const keyProvided = component.key !== componentKey;
    if (keyProvided) {

        // convert dash case to title case
        const newHeader = _.startCase(component.key.replace(/-/g, ' '));
        header = (
            <h5>{component.type} <span className="text-secondary"> {newHeader}</span></h5>
        );
    } else {
        header = (
            <h5>{component.type} <span className="text-secondary"> [default]</span></h5>
        );
    }

    const componentId = component.id;
    const camlizedComponentId = humps.decamelize(componentId, { separator: '-' });

    const retVal = (
        // <div key={componentId} className="my-4" data-wiml-component-id={camlizedComponentId}>
        <div key={componentId} className="my-4" id={camlizedComponentId}>
            {/* <pre>{camlizedComponentId}</pre> */}
            {/* <pre>{JSON.stringify(component, null, 2)}</pre> */}
            {header}
            {properties}
        </div>
    );

    return retVal;
}
