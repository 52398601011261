import Confetti from 'react-confetti'
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, ModalHeader, ModalBody
} from 'reactstrap';
import React, { useRef, useState, useEffect } from 'react';
import Layout from '../layout';
import { fetchWebsiteData } from '@wip/common/event-store/website';
import 'nprogress/nprogress.css'
import NProgress from 'nprogress';
import { fetchThemes } from '@wip/common/event-store/themes';
import {
  useLocation
} from "react-router-dom";
import { ManageV4Editor } from './v4';
import { ManageV3Editor } from './v3';
import Website from '@wip/common/domain/website';
import MobileNotice from '../mobile-notice';

// https://reactrouter.com/web/example/query-parameters
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Manage(props) {
  const { isLoading, isSaving, hasErrors, data: websiteState, isReplayed, pendingChanges: { queue }, history: { snapshots } } = useSelector(
    (state) => state.website
  );
  const websiteData = React.useMemo(() => new Website(websiteState.id, websiteState), [websiteState]);

  const { data: userData } = useSelector(
    (state) => state.user
  );

  const queryParams = useQuery();
  // const defaultVersionSpecificTab = websiteData?.meta?.appVersion == 'v4' ? 'home' : 'about';
  const defaultTab = queryParams.get('tab') || 'home';

  const [state, setState] = useState({
    selectedTab: defaultTab,
    listId: null,
    listItemId: null,
    listItemViewFormatKey: 'components:page-specific',
    shouldDisplayConfetti: false,
    shouldDisplaySuccessModal: false,
  });

  const handleStateChange = (nameOrNewState, value) => {
    if (typeof nameOrNewState === 'object') {
      setState(prevState => ({ ...prevState, ...nameOrNewState }));
    } else {
      setState(prevState => ({ ...prevState, [nameOrNewState]: value }));
    }
  };

  const initialSnapshotCount = useRef(0);

  // note, since moving the routes to use `render` instead of `component`, which provides routeProps, this stopped working.
  // let { slug } = useParams();
  // const b = useParams();
  const slug = props.computedMatch.params.slug;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSaving) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [isSaving]);


  // TODO not moving this logic to middleware -- it's too complex. we need to use
  // redux saga or or redux rxjs
  // else if (action.type.startsWith('pendingChanges/commitPendingChanges/pending')) {
  //     store.dispatch(saveWebsiteData());
  // }
  useEffect(() => {
    // dispatch async thunks are promises
    // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
    // dispatch(fetchThemes()).then(action => {
    dispatch(fetchWebsiteData(slug));
    // });
  }, [dispatch]); // why this dependency? well react lint - https://stackoverflow.com/questions/59350881/useeffect-missing-dependency-when-using-redux-usedispatch

  // useEffect(() => {
  //   
  //   const s = state;
  //   // when they save the 2nd snapshot, make true
  //   const shouldDisplayConfetti = snapshots.length == 2;
  //   if (shouldDisplayConfetti) {
  //     handleStateChange('shouldDisplayConfetti', true);
  //   }

  // }, [snapshots.length]);

  // NOTE: this straight up is not working right now
  useEffect(() => {
    // fyi current snapshots does not update when new snapshots are added  = must reload page.
    if (!isLoading) {

      initialSnapshotCount.current = snapshots.length;
    }
  }, [isLoading, snapshots.length]);

  // NOTE -- the order of ...props and ...sate is important
  // we want our state to overwrite any props sent to component so that we can actually change things.
  const newProps = { ...props, ...state, onChange: handleStateChange, userData, websiteData, pendingChanges: queue, websiteHistory: snapshots };

  if (isLoading) return 'now i am loading data'
  if (hasErrors) return 'we ran into an unexpected error. Please email us at support@wildinkmarketing.com.';
  if (!isReplayed) return 'loading previous changes…'

  const onSave = () => {
    // show it a bit more in the beginning then less frequently over time.
    // NOTE: this straight up is not working right now
    const shouldShowConfetti = (
      initialSnapshotCount.current <= 1 ||
      (initialSnapshotCount.current <= 25 && initialSnapshotCount.current % 5 == 0) ||
      initialSnapshotCount.current % 25 == 0
    );

    // show confetti on first and second save. most sites have 1 save when user onboards.
    if (shouldShowConfetti) {
      // there is a bug with confetti only showing on first render. subsequent rerenders with shouldDisplayConfetti = true is not working.
      handleStateChange('shouldDisplayConfetti', true);
    }

    // fyi current snapshots does not update when new snapshots are added  = must reload page.
    initialSnapshotCount.current = initialSnapshotCount.current + 1;
  };

  const toggle = () => handleStateChange('shouldDisplaySuccessModal', false);

  let publicAddress = `https://${slug}.wildinkpages.com/`;
  if (websiteData.meta.customDomain) {
    publicAddress = `https://www.${websiteData.meta.customDomain}`;
  }

  const code = 'friend793';
  const shareSubject = encodeURIComponent(`I built my author website with this private app. Want an invite?`);
  const shareContent = encodeURIComponent(`Hi Friend!

I'm sharing my secret invitation code with you for a new author website platform called Wild Ink Pages. You can setup a new author website in just 10 minutes.
  
Head to https://www.wildinkpages.com and use my invite code ${code} to get access during the private pre-launch period. Users who sign up will get discounted prices and early access as well as a ton of hand-holding support from the Wild Ink team.
  
Thank you!

`);

  // reduce width - it causes window to have horizontal scroll bar
  // https://github.com/alampros/react-confetti/blob/484bad0a0aaddbcfcc2fb4c1a4a7eeceaa6d4879/src/ReactConfetti.tsx#L59
  // https://github.com/alampros/react-confetti/blob/develop/README.md#use
  // https://github.com/alampros/react-confetti/issues/130
  return (
    <Layout {...newProps} >
      <Confetti
        run={state.shouldDisplayConfetti}
        width={window.innerWidth - 100}
        height={window.innerHeight}
        recycle={false}
        tweenDuration={1000}
        onConfettiComplete={() => {
          handleStateChange('shouldDisplayConfetti', false);
          // handleStateChange('shouldDisplaySuccessModal', true); // removing as we are allowing self-servicee
        }}
      />
      <Modal isOpen={state.shouldDisplaySuccessModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Your Website is Now Live 🎉 Visit <a href={publicAddress} target="_blank">{publicAddress}</a></ModalHeader>
        <ModalBody>
          <p>
            <em>Pssst…!</em> Wild Ink Pages is invite-only! Please invite your friends this this code: <strong>{code}</strong>.&nbsp;
            <a href={`mailto:?subject=${shareSubject}?&body=${shareContent}`} target="_blank">Click here to share via email.</a>
          </p>
        </ModalBody>
        {/* <ModalFooter>
          footer
        </ModalFooter> */}
      </Modal>
      <MobileNotice fullscreen={true} />
      <div className="layout container-fluid d-flex flex-column ">
        <div className="inner-wrapper d-flex flex-grow-1 h-100">
          <ManageEditor {...newProps} onSave={onSave} />
        </div>
        {/* {main}
      {footer}
      {bottomImage}
       */}
      </div>
      <style jsx>{`
        .layout {
          /* the 80px should be whatever the height of the navbar is */
          height: calc(100vh - 80px);
          padding: 0;
          background-color: ${props.themeConfig.backgroundPrimaryColor};
        }

        .layout :global(h1) {
          text-transform: uppercase;
          letter-spacing: .1rem;
          padding: .5rem;
        }

        .layout :global(h2) {
          font-weight: bold;
        }

        .layout :global(h3) {
        }

        .layout :global(.ql-editor, .rich-text-editor p) {
          margin-bottom: 1rem;
        }

        /* https://stackoverflow.com/questions/12035400/how-can-i-remove-the-no-file-chosen-tooltip-from-a-file-input-in-chrome */
        .layout :global(input[type="file"]) {
          color: transparent;
        }
    `}</style>
    </Layout>
  );
}

function ManageEditor(props) {
  let Component;
  if (props.websiteData.meta.appVersion == 'v4') {
    Component = ManageV4Editor;
  } else {
    Component = ManageV3Editor;
  }

  return <Component {...props} />
}
