import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Input, Label, CustomInput, Button } from 'reactstrap';
import { arrayUtils } from "@wip/common";
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import { updateAbout, updateAssets, saveFile, updateSocial, addGalleryToAbout, removeGalleryFromAbout, } from '@wip/common/event-store/website';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const About = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: props.websiteData,
    });

    // kind of annoying - https://github.com/react-hook-form/react-hook-form/issues/4414
    const { ref: fNameRef, ...fNameRest } = register('about.firstName', { required: true });
    const { ref: lNameRef, ...lNameRest } = register('about.lastName');
    const { ref: titleRef, ...titleRest } = register('about.title');
    const { ref: instagramRef, ...instagramRest } = register('social.instagram');
    const { ref: tiktokRef, ...tiktokRest } = register('social.tiktok');
    const { ref: youtubeRef, ...youtubeRest } = register('social.youtube');
    const { ref: facebookRef, ...facebookRest } = register('social.facebook');
    const { ref: twitterRef, ...twitterRest } = register('social.twitter');
    const { ref: goodreadsRef, ...goodreadsRest } = register('social.goodreads');
    const { ref: linkedinRef, ...linkedinRest } = register('social.linkedin');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')


            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateAbout({ key: newKey, value }));
            } else if (path == 'social') {
                dispatch(updateSocial({ key: newKey, value }));
            }

        }
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            dispatch(updateAbout({ key, value }));
        }
    };


    const onProfileImageChange = e => {
        const key = e.target.dataset.key;
        const profileImage = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(profileImage)).then(action => {
            const downloadUrl = action.payload;

            dispatch(updateAssets({ key, value: downloadUrl }));
            e.target.value = "";
        });
    };

    const onProfileImageRemove = e => {
        const key = e.target.dataset.key;
        dispatch(updateAssets({ key, value: null }));
    };

    const handleCheckChangeEvent = (event, id, key, value) => {
        // // for some reason isValid is always true even when not…
        // if (errors && Object.keys(errors).length) {
        //     console.log('Skipping due to errors:', errors);
        // } else {
        //     if (event) {
        //         key = event.target.name;
        //         value = event.target.value;
        //     }

        //     if (!key) throw new Error('Handle change event `key` is missing.')
        //     if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')


        //     const splitKeys = key.split('.');
        //     const path = splitKeys[0];
        //     const newKey = splitKeys[1];

        //     if (path == 'about') {
        //         dispatch(addGalleryToAbout({ key: newKey, value }));
        //     } else if (path == 'social') {
        //         dispatch(updateSocial({ key: newKey, value }));
        //     }
        // }

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                key = event.target.name;
                value = event.target.checked;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'galleries') {
                if (value) {
                    dispatch(addGalleryToAbout({ key: newKey }));
                } else {
                    dispatch(removeGalleryFromAbout({ key: newKey }));
                }
            }
        }
    };

    const galleryOptions = props.websiteData.galleries.map(g => {
        const galleryIsChecked = !!props.websiteData.about.galleries.find(aGallery => aGallery == g.id);
        // .bind(this, null, props.customPage.id, `galleries.${g.id}`, galleryIsChecked)
        return (
            <CustomInput defaultChecked={galleryIsChecked} type="checkbox" key={g.id} id={g.id} label={g.about.title} name={`galleries.${g.id}`} onChange={handleCheckChangeEvent} />
        );
    });

    return (
        <Form>
            <FormGroup>
                <Label for="about.firstName">First Name</Label>
                <ReactstrapInputBlurChangeDetector invalid={!!errors.firstName} type="text" {...fNameRest} innerRef={fNameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="about.lastName">Last Name</Label>
                <ReactstrapInputBlurChangeDetector type="text" {...lNameRest} innerRef={lNameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="about.title">Author Title</Label>
                <ReactstrapInputBlurChangeDetector type="text" {...titleRest} innerRef={titleRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="social.instagram">Instagram Link</Label>
                <ReactstrapInputBlurChangeDetector type="url" {...instagramRest} innerRef={instagramRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="social.tiktok">TikTok Link</Label>
                <ReactstrapInputBlurChangeDetector type="url" {...tiktokRest} innerRef={tiktokRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="social.youtube">YouTube Link</Label>
                <ReactstrapInputBlurChangeDetector type="url" {...youtubeRest} innerRef={youtubeRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="social.facebook">Facebook Link</Label>
                <ReactstrapInputBlurChangeDetector type="url" {...facebookRest} innerRef={facebookRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="social.twitter">Twitter Link</Label>
                <ReactstrapInputBlurChangeDetector type="url" {...twitterRest} innerRef={twitterRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="social.goodreads">Goodreads Link</Label>
                <ReactstrapInputBlurChangeDetector type="url" {...goodreadsRest} innerRef={goodreadsRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="social.linkedin">LinkedIn</Label>
                <ReactstrapInputBlurChangeDetector type="url" {...linkedinRest} innerRef={linkedinRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="assets.profileImage">Profile Image</Label>
                <Input id="assets.profileImage" type="file" accept="image/*" data-key='profileImage' onChange={onProfileImageChange} /*todo: put this in global style={{ color: "transparent" }}*/ />
                {/* <Button data-key='profileImage' onClick={onProfileImageChange}>hi</Button> */}
                {
                    props.websiteData.assets.profileImage &&
                    <Button outline color="primary" data-key='profileImage' onClick={onProfileImageRemove}>
                        <FontAwesomeIcon icon={faTrash} /> Remove
                    </Button>
                }
                <img src={props.websiteData.assets.profileImage} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="assets.profileImage2">Profile Image 2</Label>
                <Input id="assets.profileImage2" type="file" accept="image/*" data-key='profileImage2' onChange={onProfileImageChange} />
                {
                    props.websiteData.assets.profileImage2 &&
                    <Button outline color="primary" data-key='profileImage2' onClick={onProfileImageRemove}>
                        <FontAwesomeIcon icon={faTrash} /> Remove
                    </Button>
                }
                <img src={props.websiteData.assets.profileImage2} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="assets.profileImage3">Profile Image 3</Label>
                <Input id="assets.profileImage3" type="file" accept="image/*" data-key='profileImage3' onChange={onProfileImageChange} />
                {
                    props.websiteData.assets.profileImage3 &&
                    <Button outline color="primary" data-key='profileImage3' onClick={onProfileImageRemove}>
                        <FontAwesomeIcon icon={faTrash} /> Remove
                    </Button>
                }
                <img src={props.websiteData.assets.profileImage3} className="img-fluid shadow mt-4" />
            </FormGroup>
            <hr />
            <FormGroup>
                <Label for="assets.titleImage">Title Image</Label>
                <Input id="assets.titleImage" type="file" accept="image/*" data-key='titleImage' onChange={onProfileImageChange} />
                {
                    props.websiteData.assets.titleImage &&
                    <Button outline color="primary" data-key='titleImage' onClick={onProfileImageRemove}>
                        <FontAwesomeIcon icon={faTrash} /> Remove
                    </Button>
                }
                <img src={props.websiteData.assets.titleImage} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="assets.socialShareImage">Social Share Image</Label>
                <Input id="assets.socialShareImage" type="file" accept="image/*" data-key='socialShareImage' onChange={onProfileImageChange} />
                {
                    props.websiteData.assets.socialShareImage &&
                    <Button outline color="primary" data-key='socialShareImage' onClick={onProfileImageRemove}>
                        <FontAwesomeIcon icon={faTrash} /> Remove
                    </Button>
                }
                <img src={props.websiteData.assets.socialShareImage} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="assets.faviconImage">Favicon</Label>
                <Input id="assets.faviconImage" type="file" accept="image/*" data-key='faviconImage' onChange={onProfileImageChange} />
                {
                    props.websiteData.assets.faviconImage &&
                    <Button outline color="primary" data-key='faviconImage' onClick={onProfileImageRemove}>
                        <FontAwesomeIcon icon={faTrash} /> Remove
                    </Button>
                }
                <img src={props.websiteData.assets.faviconImage} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="about.bio">About</Label>
                <RichTextEditor defaultValue={props.websiteData.about.bio} onDiff={handleRichEditorChangeEvent.bind(null, 'bio')} {...richTextConfig} />
            </FormGroup>
            {
                // !! is required - here's why: https://stackoverflow.com/questions/53048037/react-showing-0-instead-of-nothing-with-short-circuit-conditional-component
                !!props.websiteData.galleries.length &&
                <FormGroup>
                    <Label for="exampleCheckbox">Galleries to Display on Page</Label>
                    <div>
                        {galleryOptions}
                    </div>
                </FormGroup>
            }
        </Form>
    );
}

export default About;

//  // how to watch data
//     // 1) https://github.com/react-hook-form/documentation/issues/74
//     // 2) https://github.com/react-hook-form/react-hook-form/discussions/2494
//     // 3) https://stackoverflow.com/questions/38022618/how-do-i-auto-save-with-multiple-form-fields-in-react
//     // 4) https://github.com/react-hook-form/react-hook-form/discussions/3620
//     // 5) https://github.com/react-hook-form/react-hook-form/issues/283
//     // 6) https://github.com/react-hook-form/react-hook-form/issues/3698#issuecomment-752261747
//     // 7) https://github.com/react-hook-form/react-hook-form/issues/1905
//     // https://react-hook-form.com/api/useform/watch
//     // https://github.com/tiaanduplessis/react-hook-form-persist/blob/master/src/index.js
//     watch((_, changedData) => {
//         // debounce https://www.synthace.com/autosave-with-react-hooks/
//         console.log(changedData.name, changedData.type, ':', changedData.value);
//     });

//        // https://github.com/react-hook-form/react-hook-form/discussions/2494
//        const formChanged = (e, e2) => {
//         const { isValid } = formState;
//         const formValues = getValues();
//         console.log("isValid = ", isValid);
//         console.log("formValues = ", formValues);
//         if (isValid) {
//             console.log("sending values to server");
//         }
//     };

