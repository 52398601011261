import { _isTextNode_v1_0_0, _isListNode_v1_0_0, _isPageNode_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/nodes';
import { _getPageUrl_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/page-components';
import { Wiml_v1 } from '../../..';
import { __convertHtmlToCheerio_v1_1_0 } from '../../../v1.1.0/theme-data/compiler/nodes';
import { getKeyFromId_v1_3_0 } from './components';

export function addThemeDataPageChildComponentToMarkup_v1_3_0(pageKey: string, componentType: string, componentKey: string, themeProps: Record<string, any>, position: string = 'top', parentComponentType: string = null, parentComponentKey: string = null, wimlData: Wiml_v1) {
    // consider jq, JSONSelect, or JSONPath
    // but they all seem to introduce learning new syntax.
    const markup = wimlData.themeData.markup;
    const document = __convertHtmlToCheerio_v1_1_0(markup);
    const pageNode = document(`Page[key="${pageKey}"]`);
    const parentNode = parentComponentType
        ? parentComponentKey
            ? document(`${parentComponentType}[key="${parentComponentKey}"]`, pageNode)
            : document(`${parentComponentType}`, pageNode)
        : pageNode;

    const tag = `<${componentType} key="${componentKey}"/>`;
    if (position === 'top') {
        parentNode.prepend(tag);
    } else {
        parentNode.append(tag);
    }
    return document.html();
    // const element = document(tag);
    // const rootNodeJson = JSON.parse(wimlData.themeData.components.items.rootNodeJson);
    // const b = rootNodeJson;
    // add component to page
    // const pageNode = root



    // const cheerioObj = __convertHtmlToCheerio_v1_1_0(markup);
    // cheerioObj
    // const x = cheerioObj;
    // const pageNode = wimlData.themeData.pages[pageId];
    // const markup = wimlData.themeData.markup
    // const rootNodeJson = JSON.parse(JSON.stringify(wimlData.rootNode));

}

export function replaceStyleImportStatementInMarkup_v1_3_0(newImport: string, wimlData: Wiml_v1) {
    // consider jq, JSONSelect, or JSONPath
    // but they all seem to introduce learning new syntax.
    const markup = wimlData.themeData.markup;
    const document = __convertHtmlToCheerio_v1_1_0(markup);


    const layoutNode = document(`WIML > Layout`);
    if (layoutNode.length === 0) {
        throw new Error(`Layout node not found in markup`);
    }

    let styleNode = document(`style`);
    if (styleNode.length === 0) {
        styleNode = document(`<style></style>`);
        layoutNode.prepend(styleNode);
    }

    // replace the existing @import statement with the new one
    const newStyleWithImport = styleNode.html().replace(/(\s*@import.*;)?/, newImport);
    styleNode.html(newStyleWithImport);

    return (document.html());
}

export function replaceStyleCssVarsInMarkup_v1_3_0(newCssVars: string, wimlData: Wiml_v1) {
    // consider jq, JSONSelect, or JSONPath
    // but they all seem to introduce learning new syntax.
    const markup = wimlData.themeData.markup;
    const document = __convertHtmlToCheerio_v1_1_0(markup);


    const layoutNode = document(`WIML > Layout`);
    if (layoutNode.length === 0) {
        throw new Error(`Layout node not found in markup`);
    }

    let styleNode = document(`style`);
    if (styleNode.length === 0) {
        styleNode = document(`<style></style>`);
        layoutNode.prepend(styleNode);
    }

    // replace the existing @import statement with the new one
    const newStyleWithImport = updateOrAddRootBlock((styleNode.html()), newCssVars);


    styleNode.html(newStyleWithImport);

    return (document.html());
}

// function decodeHtmlEntities(str: string) {
//     return str.replace(/&quot;/g, '"')
//         .replace(/&#39;/g, "'")
//         .replace(/&lt;/g, '<')
//         .replace(/&gt;/g, '>')
//         .replace(/&amp;/g, '&')
//         .replace(/&apos;/g, "'");
// }
function updateOrAddRootBlock(css, newRootBlock) {
    const rootRegex = /:root\s*{[^}]*}/s;

    if (rootRegex.test(css)) {
        // If :root {...} exists, replace it
        return css.replace(rootRegex, `:root{\n${newRootBlock}\n}`);
    } else {
        // If :root {...} doesn't exist, add it after any @import statements
        const importRegex = /(@import[^;]+;\s*)+/;
        const match = css.match(importRegex);

        if (match) {
            // If there are @import statements, add :root {...} after them
            return css.replace(importRegex, `$& :root{\n${newRootBlock}\n}\n`);
        } else {
            // If there are no @import statements, add :root {...} at the beginning
            return `:root{\n${newRootBlock}\n}\n${css}`;
        }
    }
}


export function moveThemeDataPageChildComponentToMarkup_v1_3_0(pageId: string, componentId: string, position: string | number, wimlData: Wiml_v1) {
    const pageKey = getKeyFromId_v1_3_0(pageId);
    // consider jq, JSONSelect, or JSONPath
    // but they all seem to introduce learning new syntax.
    const markup = wimlData.themeData.markup;
    const document = __convertHtmlToCheerio_v1_1_0(markup);
    const pageNode = document(`Page[key="${pageKey}"]`);
    const component = wimlData.getPageChildComponentThemeData(pageId, componentId);
    const componentType = component.type;
    `${componentType}[key="${component.key}"]`
    const componentNode = document(`${componentType}[key="${component.key}"]`, pageNode);
    if (!componentNode.length) throw new Error(`Component with id ${componentId} not found in page with id ${pageId}`);
    if (position === 'above') {
        componentNode.prev().before(componentNode);
    } else if (position === 'below') {
        componentNode.next().after(componentNode);
    } else if (typeof position === 'number') {
        const children = pageNode.children();
        if (position < 0 || position > children.length) throw new Error(`Invalid position ${position}`);
        children.eq(position).before(componentNode);
    } else {
        throw new Error(`Invalid position ${position}`);
    }
    return document.html();
}
