import { useEffect } from "react";
import { Form } from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from "@wip/common/lib/auth";

const fbfFreebie = <div className="mt-3 text-center border p-3">
    <h6>FREE bonus included in this lifetime plan: Fan by Fan ($397 value!)</h6>
    <p>Fan by fan is an online course to teach you the fundamentals of book marketing composed of 7 modules.</p>
    <p><a href="https://www.wildinkmarketing.com/fan-by-fan/" target="_blank">Learn more about Fan by Fan here</a>. Note: personal and group coaching is <u>not available in Fan by Fan at this time</u>.</p>
</div>;

const Settings = (props) => {
    let stripe = null;
    const stripePk = process.env.REACT_APP_STRIPE_PK;
    const appVersion = props.websiteData.meta.appVersion;

    const {
        user,
        error
    } = useAuth();

    useEffect(async () => {
        stripe = await loadStripe(stripePk);
    }, []);

    function doPurchase(productId, mode) {
        stripe.redirectToCheckout({
            lineItems: [{ price: productId, quantity: 1 }],
            mode: mode,
            clientReferenceId: props.websiteData.meta.slug,
            customerEmail: user.email,
            /*
             * Do not rely on the redirect to the successUrl for fulfilling
             * purchases, customers may not always reach the success_url after
             * a successful payment.
             * Instead use one of the strategies described in
             * https://stripe.com/docs/payments/checkout/fulfill-orders
             */
            successUrl: window.location.href,
            cancelUrl: window.location.href,
        }).then(function (result) {
            if (result.error) {
                /*
                 * If `redirectToCheckout` fails due to a browser or network
                 * error, display the localized error message to your customer.
                 */
                // var displayError = document.getElementById('error-message');
                // displayError.textContent = result.error.message;
                alert("Error submitting payment: " + result.error.message);
            }
        });
    }
    let pricingLevel;

    const slug = props.websiteData.meta.slug;
    if (slug == 'cate' || slug == 'valery' || slug == 'erin' || slug == 'sibylla'
        || slug == 'natashia' || slug == 'aggie' || slug == 'sarah' || slug == 'maureen' || slug == 'growing' || slug == 'tyfany') {
        pricingLevel = 'v1';
    } else if (slug == 'tracy') {
        pricingLevel = 'v1.exclusive-theme';
    } else if (slug == 'juliette') {
        pricingLevel = 'v1.second-site';
    } else if (slug == 'dori') {
        pricingLevel = 'v2.retention';
    } else {
        // set default if not v4
        if (appVersion != 'v4') {
            pricingLevel = 'v2';
        }
    }

    function purchaseTest() {
        const productId = 'price_1IdlEKBpjJAk0EdGazFg5oI7';
        doPurchase(productId, 'subscription');
    }

    function purchaseForever() {
        let productId;

        if (slug == 'anita') {
            productId = 'price_1JkL8mBpjJAk0EdGOXGQEWIX' // v2.forever-discount
        } else if (pricingLevel == 'v1') {
            productId = 'price_1JY31mBpjJAk0EdGKU6aY3V8'; // v1
        } else if (pricingLevel == 'v2') {
            productId = 'price_1JY315BpjJAk0EdGp3KmTnsL'; // v2
        }

        doPurchase(productId, 'payment');
    }

    function purchaseAnnually() {
        let productId;

        if (pricingLevel == 'v1') {
            productId = 'price_1IdjnDBpjJAk0EdGc5tzN2j8' // v1
        } else if (pricingLevel == 'v1.exclusive-theme') {
            productId = 'price_1JS4bZBpjJAk0EdGyvAdkRa8' // v1.exclusive-theme
        } else if (pricingLevel == 'v1.second-site') {
            productId = 'price_1JUeI0BpjJAk0EdGfWz7bgia' // v1.second-site
        } else if (pricingLevel == 'v2.retention') {
            productId = 'price_1LPzAOBpjJAk0EdGBdyrOqHS' // v2.retention
        } else {
            productId = 'price_1JK7oWBpjJAk0EdGaEJ8N4mm'; // v2
        }

        doPurchase(productId, 'subscription');
    }

    function purchaseMonthly() {
        let productId;

        if (pricingLevel == 'v1') {
            productId = 'price_1IdjnEBpjJAk0EdG2hC2JtTR'; // v1
        } else if (pricingLevel == 'v2.retention') {
            productId = 'price_1LPzAqBpjJAk0EdG8HANuDKe'; // v2.retention
        } else {
            productId = 'price_1JK7lhBpjJAk0EdGdR3SYI1m'; // v2
        }

        doPurchase(productId, 'subscription');
    }

    let settingsElem;

    if (props.websiteData.meta.purchased) {

        if (props.websiteData.meta.stripeCustomerId) {

            // todo import customer ids from stripe
            // right now i manually imported it for 1 person
            // move purcashed and customerId to some other key e.g. `payment`.

            const url = process.env.REACT_APP_API_URL;
            const returnUrl = window.location;

            const paymentApiUrl = `${url}/payment/manage?slug=${props.websiteData.meta.slug}&return_url=${returnUrl}`;

            settingsElem = (
                <section className="settings">
                    <h6 className="border-bottom mt-3">Payment</h6>
                    <p><a className="btn-info btn" href={paymentApiUrl}>Make changes to payment settings here.</a></p>
                    <p>If you need help, please contact <a href="mailto:colleen@wildinkmarketing.com" target="_blank">colleen@wildinkmarketing.com.</a></p>

                </section>
            );
        } else {
            const code = 'friend793';
            const shareSubject = encodeURIComponent(`I built my author website with this private app. Want an invite?`);
            const shareContent = encodeURIComponent(`Hi Friend!

I'm sharing my secret invitation code with you for a new author website platform called Wild Ink Pages. You can setup a new author website in just 10 minutes.
            
Head to https://www.wildinkpages.com and use my invite code ${code} to get access during the private pre-launch period. Users who sign up will get discounted prices and early access as well as a ton of hand-holding support from the Wild Ink team.
            
Thank you!

`);

            settingsElem = (
                <section className="settings">
                    <div>
                        <h6 className="border-bottom mt-3">Payment</h6>
                        <p>Your payment is complete. To cancel, please contact <a href="mailto:colleen@wildinkmarketing.com" target="_blank">colleen@wildinkmarketing.com.</a></p>
                    </div>
                    <div>
                        <h6 className="border-bottom mt-3">Invite a Friend</h6>
                        <p>
                            <em>Pssst…!</em> Wild Ink Pages is invite-only! Please invite your friends with this code: <strong>{code}</strong>.&nbsp;
                            <a href={`mailto:?subject=${shareSubject}?&body=${shareContent}`} target="_blank">Click here to share via email.</a>
                        </p>
                    </div>
                </section>
            );
        }
    } else {
        let checkoutButtons;

        if (stripePk.includes('tsdfsest')) {
            checkoutButtons = (
                <button id="checkout-button-test" role="link" type="button" onClick={purchaseTest}>
                    Checkout test env (only visible in dev)
                </button>
            );
        } else {
            if (appVersion == 'v4') {
                if (pricingLevel) {
                    checkoutButtons = getV3Pricing();
                } else {
                    checkoutButtons = getV4Pricing();
                }
            } else {
                checkoutButtons = getV3Pricing();
            }
        }

        settingsElem = (
            <section className="settings">
                <Form>
                    {
                        appVersion
                            ? null
                            : <section className="directions">
                                <h6 className="border-bottom mt-3">Payment Directions</h6>
                                <ol>
                                    {/* <li><a href="https://bit.ly/wild-ink-pages-roadmap" target="_blank">Read the roadmap</a> to see when upcoming features will go live.</li> */}
                                    {/* <li>If you haven't <a href="https://bit.ly/wild-ink-pages-faq" target="_blank">read the FAQ/Terms</a> of Wild Ink Pages, please do so here.</li> */}
                                    {/* <li>Any questions? Please <a href="http://bit.ly/wild-ink-pages-faq" target="_blank">read the FAQ here</a>.</li> */}
                                    <li>You can sign up for a <strong>Pro</strong> Wild Ink Pages account by clicking below.</li>
                                    <li>If you have a custom domain (e.g. www.MyAuthorSite.com), we can make that your url on Wild Ink Pages after purchase.</li>
                                    <li>Once we setup your custom domain, I will let you know when it's safe to cancel your account with your existing host.</li>
                                </ol>
                            </section>
                    }
                    <section className="payment mb-5">
                        <h6 className="border-bottom mt-3">Payment Options</h6>
                        {checkoutButtons}
                    </section>
                </Form >
                <style jsx>{`
                .settings li {
                    line-height: 1.62rem;
                }
                .settings :global(button) {
                    color: #FFF;
                    padding: 8px 12px;
                    border: 0;
                    border-radius: 4px;
                    font-size: 1rem;
                    width: 100%;
                }

                .settings :global(#checkout-button-test) {
                    background-color: #FF0000;
                }

                .settings :global(#checkout-button-forever) {
                    background-color: #E10D48;
                }

                .settings :global(#checkout-button-primary) {
                    background-color: #0F72E5;
                }

                .settings :global(#checkout-button-secondary) {
                    background-color: #6772E5;
                }
            `}</style>
            </section >
        );
    }

    return (
        settingsElem
    );

    function getV3Pricing() {
        let forLifeCheckoutButton;
        let primaryCheckoutButton;
        let secondaryCheckoutButton;

        if (pricingLevel == 'v1') {
            forLifeCheckoutButton = (
                <>
                    <button id="checkout-button-forever" role="link" type="button" onClick={purchaseForever}>
                        One-Time Purchase (buy it forever)
                        <br />$371.25
                    </button>
                    {/* <b className="text-center d-block">🎉 Save 25% with Yearly Plan!</b> */}
                    <b className="text-center d-block">Averages $74.25 / year if you stick with it for 5 years!</b>
                    {fbfFreebie}
                </>
            );

            primaryCheckoutButton = (
                <>
                    <h5 className="text-center my-3">OR</h5>
                    <button id="checkout-button-primary" role="link" type="button" onClick={purchaseAnnually}>
                        Annual Subscription ($99 / year)
                    </button> <b className="text-center d-inline-block">🎉 Get 2 Months Free with Annual Option!</b>
                </>
            );

            secondaryCheckoutButton = (
                <>
                    <h5 className="text-center my-3">OR</h5>
                    <button id="checkout-button-secondary" role="link" type="button" onClick={purchaseMonthly}>
                        Monthly Subscription ($9.99 / month)
                        <br /><sup>($119.88 / year)</sup>
                    </button>
                </>
            );
        } else if (pricingLevel == 'v1.exclusive-theme') {
            primaryCheckoutButton = (
                <>
                    <b className="mt-2 d-inline-block text-center">Annual Subscription w/ Exclusive Theme</b>
                    <button id="checkout-button-primary" role="link" type="button" onClick={purchaseAnnually}>
                        Annual Subscription ($147 / year)
                    </button>
                </>
            );
        } else if (pricingLevel == 'v1.second-site') {
            primaryCheckoutButton = (
                <>
                    <b className="mt-2 d-inline-block text-center">Annual Subscription - 2nd Website</b>
                    <button id="checkout-button-primary" role="link" type="button" onClick={purchaseAnnually}>
                        Annual Subscription ($30 / year)
                    </button>
                </>
            );
        } else if (pricingLevel == 'v2.retention') {
            primaryCheckoutButton = (
                <>
                    <button id="checkout-button-primary" role="link" type="button" onClick={purchaseAnnually}>
                        Annual Subscription ($39.99 / year)
                    </button>
                </>
            );

            secondaryCheckoutButton = (
                <>
                    <h5 className="text-center my-3">OR</h5>
                    <button id="checkout-button-secondary" role="link" type="button" onClick={purchaseMonthly}>
                        Monthly Subscription ($4.00 / month)
                        <br /><sup>($48.00 / year)</sup>
                    </button>
                </>
            );
        } else {
            if (slug == 'anita') {
                forLifeCheckoutButton = (
                    <>
                        <u style={{ color: "red" }}>🎉 Special Discout Only for <b>Anita!!</b></u>
                        <br />
                        <br />
                        <button id="checkout-button-forever" role="link" type="button" onClick={purchaseForever}>
                            One-Time Purchase (buy it forever)
                            <br />$375.50
                        </button>
                        {/* <b className="text-center d-block">🎉 Save 25% with Yearly Plan!</b> */}
                        <b className="text-center d-block">Averages $75.50 / year if you stick with it for 5 years!</b>
                        {fbfFreebie}
                    </>
                );
            } else {
                forLifeCheckoutButton = (
                    <>
                        <button id="checkout-button-forever" role="link" type="button" onClick={purchaseForever}>
                            One-Time Purchase (buy it forever)
                            <br />$498.88
                        </button>
                        {/* <b className="text-center d-block">🎉 Save 25% with Yearly Plan!</b> */}
                        <b className="text-center d-block">Averages $99.78 / year if you stick with it for 5 years!</b>
                        {fbfFreebie}
                    </>
                );
            }


            primaryCheckoutButton = (
                <>
                    <h5 className="text-center my-3">OR</h5>
                    <button id="checkout-button-primary" role="link" type="button" onClick={purchaseAnnually}>
                        Yearly Subscription
                        <br />($14.99 / month)
                        <br /> <sup>($179.88 / year)</sup>
                    </button>
                    {/* <b className="text-center d-block">🎉 Save 25% with Yearly Plan!</b> */}
                    <b className="text-center d-block">🎉 Get 3 Months Free with Annual Option!</b>
                </>
            );

            secondaryCheckoutButton = (
                <>
                    {/* <b>3 Months FREE with Yearly Plan!</b> */}
                    <h5 className="text-center my-3">OR</h5>
                    <button id="checkout-button-secondary" role="link" type="button" onClick={purchaseMonthly}>
                        Monthly Subscription
                        <br /> ($19.99 / month)
                        <br /> <sup>($239.88 / year)</sup>
                    </button>
                </>
            );
        }

        const checkoutButtons = (
            <>
                {forLifeCheckoutButton}
                <br />
                {primaryCheckoutButton}
                <br />
                {secondaryCheckoutButton}
            </>
        );
        return checkoutButtons;
    }


    function getV4Pricing() {
        const slug = props.websiteData.meta.slug;

        let checkoutButtons;
        const monthylyCheckoutLink_10_00 = `https://buy.stripe.com/dR65lBdvm6nrbwQ5kl?client_reference_id=${slug}`;
        const yearlyCheckoutLink_99_00 = `https://buy.stripe.com/3cs01h76YcLP0SccMM?client_reference_id=${slug}`;
        const yearlyCheckoutLink_119_00 = `https://buy.stripe.com/00g15l2QI9zD1Wg5km?client_reference_id=${slug}`;
        const yearlyCheckoutLink_149_00 = `https://buy.stripe.com/00g5lBgHyeTXeJ2bIL?client_reference_id=${slug}`;

        const firstYearPercentOffDiscount_90_00 = "FIVERR0014";

        if (slug == "bchp") {
            checkoutButtons = (
                <>
                    <a className="btn btn-primary" href={yearlyCheckoutLink_99_00} target="_blank" rel="noopener noreferrer">
                        Yearly Subscription
                        <br />($99 / year)
                    </a>
                    <br />
                    <br />
                    <a className="btn btn-info" href={monthylyCheckoutLink_10_00} target="_blank" rel="noopener noreferrer">
                        Monthly Subscription
                        <br />($9.99 / month)
                        <br /><small>($119.88 / year)</small>
                    </a>
                </>
            );
        } else if (slug == "micha") {
            checkoutButtons = (
                <a className="btn btn-primary" href={yearlyCheckoutLink_149_00} target="_blank" rel="noopener noreferrer">
                    Yearly Subscription
                    <br />($149.00 / year)
                </a>
            );
        } else if (slug == "marcus") {
            checkoutButtons = (
                <a className="btn btn-primary" href={`${yearlyCheckoutLink_99_00}&prefilled_promo_code=${firstYearPercentOffDiscount_90_00}`} target="_blank" rel="noopener noreferrer">
                    Yearly Subscription
                    <br />($99.00 / year)
                </a>
            );
        }
        else {
            checkoutButtons = (
                <a className="btn btn-primary" href={`${yearlyCheckoutLink_119_00}&prefilled_promo_code=${firstYearPercentOffDiscount_90_00}`} target="_blank" rel="noopener noreferrer">
                    Yearly Subscription
                    <br />($119.99 / year)
                </a>
            );
        }

        return checkoutButtons;
    }
}

export default Settings;
