import React from 'react';

import { getInferredComponentDefaultType_v1_0_0 } from '../../compiler/components';
import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../site-data/retrieval';
import { RuntimeWimlComponentProps_v1_0_0 } from '../../../../../../../app/wiml/versions/v1/v1.0.0/theme-data/compiler/types';


export function WimlDate_v1_0_0(props: RuntimeWimlComponentProps_v1_0_0) {
    let retVal = null;

    let date = getChildComponentPropertyValueSiteData_v1_0_0(props, 'date', getInferredComponentDefaultType_v1_0_0('Date')) as string;
    if (date) {
        // const dateStr = new Date(date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        const dateStr = new Date(date).toLocaleDateString();
        // const dateStr = date;
        // include time in the date using current users locale
        // const dateStr = new Date(date).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
        const element = <span>
            {dateStr}
        </span>;
        retVal = element;
    }
    return retVal;
}

WimlDate_v1_0_0.inputProps = {
    date: {
        type: 'date',
        label: 'Date',
        description: 'The date',
    },
};
