import { Input } from 'reactstrap';
import { useRef } from 'react';
import { Editor as MceEditor } from '@tinymce/tinymce-react';
import { withBlurChangeDetection } from '@wip/common';

const tinyMceApiKey = process.env.REACT_APP_TINY_MCE_API_KEY;

export const ReactstrapInputBlurChangeDetector = withBlurChangeDetection(Input);

export function RichTextEditor(props) {
    if (process.env.NODE_ENV !== 'production') {
        // todo fix after tiny mce hosting is fixed
        return "EMPTY - NOT production";
    }

    if (!props.onImageUpload) {
        throw new Error(`onImageUpload is required.`);
    }

    if (!tinyMceApiKey) {
        throw new Error(`tinyMceApiKey is required.`);
    }

    const editorRef = useRef(null);
    const prevVal = useRef();

    const inputBlur = () => {
        const newVal = getHTML();
        if (newVal != prevVal.current) {
            props.onDiff && props.onDiff(newVal);
        }
    };


    function getHTML() {
        const editorHtml = editorRef.current.getContent();

        return editorHtml
    }

    function inputFocus() {
        prevVal.current = getHTML();
    };

    // https://www.tiny.cloud/docs/configure/file-image-upload/#exampleusingimages_upload_handler
    async function uploadImageCallBack(blobInfo, success, failure, progress) {
        const file = blobInfo.blob();

        const fileUrl = await props.onImageUpload(file);


        success(fileUrl);
    }

    return (
        <MceEditor
            apiKey={tinyMceApiKey}
            onInit={setRef}
            /*
            MUST USE initialValue
            https://stackoverflow.com/questions/65024237/why-editor-field-not-rendering-recent-data-in-react-hook-form
            https://app.clickup.com/25740756/docs/rhhem-14790/rhhem-5390
            value={props.defaultValue || ""}
            */
            initialValue={props.defaultValue || ""}
            init={{
                body_class: 'rich-text-editor', /*todo does not work, have to use height*/
                height: 500,
                //    menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                //    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                images_upload_handler: uploadImageCallBack,
                resize_img_proportional: true,
                extended_valid_elements: 'script[src|async|defer|type|charset]',
                // image_advtab: true,
                // image_class_list: [
                //     {title: 'None', value: ''},
                //     {title: '', value: 'img_no_border'},
                //     {title: 'Green border', value: 'img_green_border'},
                //     {title: 'Blue border', value: 'img_blue_border'},
                //     {title: 'Red border', value: 'img_red_border'}
                //   ]                
            }}
            onFocus={inputFocus}
            onBlur={inputBlur}
        />

        // <Editor
        //     onBlur={inputBlur}
        //     onFocus={inputFocus}
        //     editorState={editorState}
        //     toolbarClassName="toolbarClassName"
        //     wrapperClassName="wrapperClassName"
        //     editorClassName="rich-text-editor"
        //     onEditorStateChange={onChange}
        //     toolbar={{
        //         options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'embedded', 'emoji', 'image', 'remove'],
        //         inline: {
        //             options: ['bold', 'italic', 'underline', 'strikethrough'],
        //         },
        //         image: {
        //             uploadCallback: uploadImageCallBack,
        //             urlEnabled: false,
        //             // previewImage: true,
        //             // alt: { present: true, mandatory: false },
        //             inputAccept: 'image/*',
        //             defaultSize: {
        //                 height: 'auto',
        //                 width: 'auto',
        //             },
        //         }
        //     }}
        // />
    );

    function setRef(evt, editor) {
        editorRef.current = editor;
    }
}
