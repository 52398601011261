import { CardHeader, UncontrolledCollapse, Card, CardBody, Collapse } from 'reactstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateUtils, objectUtils } from '@wip/common';
import { _isElementNode_v1_0_0 } from '@wip/common/app/wiml/versions/v1/v1.0.0/theme-data/compiler/nodes';
import { wimlComponents_v1_0_0 } from '@wip/common/app/wiml/versions/v1/v1.0.0/theme-data/renderer/wiml-components-v1.0.0';
import { wimlComponents_v1_1_0 } from '@wip/common/app/wiml/versions/v1/v1.1.0/theme-data/renderer/wiml-components-v1.1.0';
import { _getThemeDataComponentId_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/theme-data/compiler/components';
import { wimlComponents_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/theme-data/renderer/wiml-components-v1.2.0';
import { sortListItems } from '@wip/common/domain/list-sort';
import { createNewListItem, deleteListItem, deleteRelationship, saveFile, updateAssets, updateListItemComponentProperty, updatePageChildComponentProperty, updateRelationship, updateStyle, movePageChildComponent, } from '@wip/common/event-store/website';
import { move } from "@wip/common/lib/array-utils";
import { dateYYYYMMDD } from '@wip/common/lib/date-utils';
import { convertArrayToObject, convertObjectToArray } from "@wip/common/lib/object-utils";
import imageCompression from 'browser-image-compression';
import humps from 'humps';
import _ from 'lodash';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import NewWindow from 'react-new-window';
import { useDispatch } from "react-redux";
import Editor from 'react-simple-code-editor';
import { Button, CustomInput, Form, FormGroup, Input, Label } from 'reactstrap';
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../../../../../form/input';
import WimlProducts from '../v1.0.0/wiml-products-v1.0.0';
import { Droppable, Draggable } from "react-beautiful-dnd";
import { resetDebouncer_v1_0_0 } from '../v1.0.0/wiml-editor-v1.0.0';

// todo make this  and the rest of the admin wiml-version-specific
// v1.3.0
export const DEFAULT_ADMIN_LIST_LABEL_KEY_v1_3_0 = 'heading__title.content.data.value';

// create a debounce function that accepts a function to run and a delay
const componentIdScrollerDebouncer = _.debounce((scrollFunc) => {
    scrollFunc();
}, 250);
const defaultSelectionOption = <option value="" />;
const viewListOptions = [
    { key: "components:page-specific", value: "Show page-specific components (default)" },
    { key: "components:all", value: "Show all components" },
];
const WimlEditor = (props) => {
    const wimlEditorWindow = useRef(null);
    const componentsMetaLookup = wimlComponents_v1_2_0;
    const wimlVersion = props.websiteData.style.wiml.themeData.meta?.version || "1.0.0";
    const dispatch = useDispatch();

    const handleDeleteListItem = () => {
        dispatch(deleteListItem({ listId: props.listId, listItemId: props.listItemId }));
        deselectListItem();
    };

    const deselectListItemRelationship = () => {
        props.onChange('relationshipAListId', null);
        props.onChange('relationshipAListItemId', null);
        props.onChange('relationshipBListId', null);
        props.onChange('relationshipBListItemId', null);
    };


    const handleDeleteListItemRelationship = () => {
        dispatch(deleteRelationship({
            listId: props.relationshipAListId, listItemId: props.relationshipAListItemId,
            relationshipListId: props.relationshipBListId, relationshipListItemId: props.relationshipBListItemId
        }));
        deselectListItemRelationship();
    };

    const deselectListItem = () => {
        props.onChange('listId', null);
        props.onChange('listItemId', null);
    };

    let defaultValues;

    const relationshipAListId = props.relationshipAListId;
    const relationshipAListItemId = props.relationshipAListItemId;
    const relationshipBListId = props.relationshipBListId;
    const relationshipBListItemId = props.relationshipBListItemId;
    const relationshipBIsNew = props.relationshipBIsNew;

    const selectedTab = props.selectedTab;
    const pageId = props.selectedTab;
    const listId = props.listId;
    const listItemId = props.listItemId;
    const componentId = props.componentId;
    const containerId = props.containerId;
    const goToLine = !!props.meta?.goToLine;
    const lineNumber = props.meta?.lineNumber;

    if (listId) {
        defaultValues = props.websiteData.style.wiml.siteData?.components?.items?.lists?.items?.[listId]?.items?.items?.[listItemId]?.components?.items;
    } else {
        defaultValues = props.websiteData.style.wiml.siteData?.components?.items?.pages?.items?.[pageId]?.components?.items;
    }

    const toggleContainer = useCallback((containerId) => {
        props.onChange('containerId', props.containerId === containerId ? null : containerId);
    }, [props.onChange, props.containerId]);

    function uploadImage(file) {
        const options = {
            maxSizeMB: .5, // this should probably match the firebase rules
            maxWidthOrHeight: 1500,
            useWebWorker: true
        };

        const workPromise = imageCompression(file, options)
            .then(compressedFile => dispatch(saveFile(compressedFile)));

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: file => uploadImage(file).then(action => action.payload) };

        return retVal;
    }, [dispatch]);

    // useEffect(() => {
    //     // https://app.clickup.com/25740756/docs/rhhem-14790/rhhem-5390
    //     resetDebouncer(reset, defaultValues);
    // }, [reset, defaultValues]);

    useEffect(() => {
        if (goToLine && lineNumber) {
            const domId = 'container_editor_line_number__' + lineNumber;
            componentIdScrollerDebouncer(scrollDomById(domId, wimlEditorWindow.current?.document));
        }
        else if (componentId) {
            const decamelizeComponentId = humps.decamelize(componentId, { separator: '-' });
            componentIdScrollerDebouncer(scrollDomById(decamelizeComponentId));
        }

    }, [componentId, selectedTab, listItemId, goToLine, lineNumber]);

    const scrollDomById = useCallback((domId, docToSearch = window.document) => {
        return () => {
            // don't rely on 
            // const componentInDom = window[componentId];
            const componentInDom = docToSearch.getElementById(domId);
            // document.querySelector('[data-wiml-component-id="your-component-id"]');
            if (componentInDom) {
                componentInDom.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            } else {
                console.warn(`Could not find component ${domId} in DOM`);
            }
        };
    }, []);


    const { register, reset, formState } = useForm({
        mode: 'onBlur', //todo is it possible to make ONLY onBlur and exclude onChange?
        defaultValues: defaultValues,
        // shouldUnregister: true, --> DOES NOT help with reset problem - see resetDebouncer
    });

    useEffect(() => {
        resetDebouncer_v1_0_0(reset);
    }, [reset, selectedTab, listItemId]);

    const errors = formState.errors;
    // kind of annoying - https://github.com/react-hook-form/react-hook-form/issues/4414
    //regsiter with default values, then update the default values
    // const { ref: wimlMarkupRef, ...wimlMarkupRest } = register('style.wiml.themeData.markup', { required: true, value: props.websiteData.style.wiml.themeData.markup });
    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')


            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'style') {
                dispatch(updateStyle({ key: newKey, value }));
            }

        }
    };

    const handleRichTextEditorChangeEvent = ({ pageId, listId, listItemId }, componentId, propertyId, propertyValue) => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            dispatchUpdateComponentProperty({ pageId, listId, listItemId }, componentId, propertyId, propertyValue);
        }
    }
    // todo  v1.3.0 handle old stuff below
    const handleDragEnd = (result) => {
        const draggableId = result.draggableId;
        if (draggableId.startsWith('container__')) {
            // const position = result.source.index > result.destination.index ? "above" : "below";
            const position = result.destination.index;
            dispatch(movePageChildComponent({ pageId, componentId: draggableId, position }));
        } else {
            // this assumes we're dragging into the same list. if we change this, you must use draggableId
            const { destination, source, draggableId } = result;
            if (!destination) return;
            if (destination.droppableId == source.droppableId && destination.index == source.index) return;

            const soucePageQualifiedListId = source.droppableId.split('.');
            const sourcePageId = soucePageQualifiedListId[0];
            const sourceListId = soucePageQualifiedListId[1];

            const destinationPageQualifiedListInstanceComponentId = destination.droppableId.split('.');
            const destinationPageId = destinationPageQualifiedListInstanceComponentId[0];
            const destinationListId = destinationPageQualifiedListInstanceComponentId[1];
            const destinationListInstanceComponentId = destinationPageQualifiedListInstanceComponentId.slice(2).join('.');

            const listItemId = draggableId;

            // handle existing sort order
            const destinationListComponent = props.websiteData.style.wiml.siteData.components.items.pages?.items?.[destinationPageId]?.components?.items?.[destinationListInstanceComponentId];
            const sortExpression = destinationListComponent?.sort?.data?.value;
            const listItems = props.websiteData.style.wiml.siteData.components.items.lists.items[destinationListId].items.items;
            const listItemsArr = objectUtils.convertObjectToArray(listItems);
            let sortedItemsArr = listItemsArr;
            if (sortExpression) {
                if (!sortExpression.startsWith("{{manualOrder")) {
                    throw new Error("Manual order is not supported when sorting is enabled.")
                }
                sortedItemsArr = sortListItems(sortExpression, listItemsArr);
            }

            const newSortedItems = move(sortedItemsArr, source.index, destination.index);
            const newOrderedIds = newSortedItems.map((x, index) => ({ [x.id]: index }));
            const newExpression = newOrderedIds.reduce((accum, next) => {
                const objId = Object.keys(next)[0];
                accum[objId] = next[objId];
                return accum;
            }, {})
            const newSortExpression = `{{manualOrder(current_item.id,${JSON.stringify(newExpression)})}}`;

            dispatchUpdateComponentProperty({ pageId: destinationPageId }, destinationListInstanceComponentId, 'sort', newSortExpression);
        }
    };
    const handleNavigationPropertyChangeEvent = (name, pageId, listId, listItemId,) => (jsonValue) => {
        const cleansedValue = jsonValue.map(x => {
            const { children, ...rest } = x;
            if (children?.length) {
                return x;
            } else {
                return rest;
            }
        });
        const cleansedValueString = JSON.stringify(cleansedValue);
        const event = {
            target: {
                name,
                value: cleansedValueString,
                dataset: { pageId, listId, listItemId }
            }
        };
        handlePropertyChangeEvent(event);
    }
    const handlePropertyChangeEvent = (event) => {

        // todo - look for onChange and run it e.g. event-detail.js
        /*
            src/components/manage/event-detail.js
           const handleEventDateChangeEvent = async (e) => {
            dateRest.onChange && await dateRest.onChange(e);

            const id = e.target.dataset.id;
            const key = e.target.name;
            const value = dateUtils.parseDate(e.target.value);
            handleChangeEvent(null, id, key, value);
          };
     */


        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const pageId = event.target.dataset.pageId;
            const listId = event.target.dataset.listId;
            const listItemId = event.target.dataset.listItemId;

            const name = event.target.name;

            let propertyValue;
            if (event.target.type == 'checkbox') {
                propertyValue = event.target.checked;
            }
            else if (event.target.type == 'number') {
                // todo put this logic in domain layer
                propertyValue = event.target.value ? parseFloat(event.target.value) : null;
            }
            else if (event.target.type == 'date') {
                // todo put this logic in domain layer
                // keep the date as a string, but convert it to a date object for timezone
                propertyValue = event.target.value ? dateUtils.parseDate(event.target.value).toISOString() : null;
                // console.log({ propertyValue })
            }
            else {
                propertyValue = event.target.value;
            }

            const splitKeys = name.split('.');
            // const componentId = splitKeys[0];
            // const propertyId = splitKeys[1];
            // if splitKeys[startinIndex] starts with "list", then iterate or recur until it doesn't
            const findIndex = splitKeys.findIndex(key => !key.startsWith('list')) - 1;
            const startIndex = findIndex !== -1 ? findIndex : 0;

            const componentId = splitKeys.slice(0, startIndex + 1).join('.');
            const propertyId = splitKeys[startIndex + 1];

            dispatchUpdateComponentProperty({ pageId, listId, listItemId }, componentId, propertyId, propertyValue);
        }
    };

    const dispatchUpdateComponentProperty = ({ pageId, listId, listItemId }, componentId, propertyId, propertyValue) => {
        if (!pageId && (!listId || !listItemId)) throw new Error('Handle change event `pageId` or `listId` must be set to a value or null.')
        if (!componentId) throw new Error('Handle change `componentKey` is missing.')
        if (!propertyId) throw new Error('Handle change `propertyId` is missing.')
        if (typeof propertyValue == 'undefined') throw new Error('Handle change event `value` must be set to a value or null.')

        let retVal;

        if (pageId) {
            retVal = dispatch(updatePageChildComponentProperty({ pageId, componentId, propertyId, propertyValue }));
        } else if (listId) {
            retVal = dispatch(updateListItemComponentProperty({ listId, listItemId, componentId, propertyId, propertyValue }));
        }

        return retVal;
    };

    const dispatchCreateNewListItem = (listId, listInstanceComponentId) => {
        const retVal = dispatch(createNewListItem({ listId }));

        props.onChange('listId', listId);
        props.onChange('listItemId', retVal.payload.id);
        props.onChange('listInstanceComponentId', listInstanceComponentId);

        return retVal;
    };

    const onImageAdd = event => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const pageId = event.target.dataset.pageId;
            const listId = event.target.dataset.listId;
            const listItemId = event.target.dataset.listItemId;

            const name = event.target.name;
            const imageFile = event.target.files[0];

            const splitKeys = name.split('.');
            const path = splitKeys[0];
            const propertyId = splitKeys[1]; // url prop

            // dispatch async thunks are promises
            // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
            // todo v1.2 handle when image is just pasted or url is provided
            uploadImage(imageFile).then(action => {
                const downloadUrl = action.payload;
                const value = downloadUrl;
                dispatchUpdateComponentProperty({ pageId, listId, listItemId }, path, propertyId, value);
            });
        }
    };


    const onImageLoad = e => {
        const img = e.target;

        const name = img.dataset.name;

        const splitKeys = name.split('.');
        const path = splitKeys[0];

        if (path.startsWith('image')) {
            const actualWidth = img.naturalWidth;
            const actualHeight = img.naturalHeight;

            if (actualWidth && actualHeight) {
                const pageId = img.dataset.pageId;
                const listId = img.dataset.listId;
                const listItemId = img.dataset.listItemId;


                const siteDataRequestProps = {
                    componentId: path,
                    dataRetrievalListItemId: listItemId,
                    dataRetrievalListId: listId,
                    pageDefinitionId: pageId,
                    websiteData: props.websiteData,// todo - this is a hack
                }

                // in case not loading
                const currentWidth = getChildComponentPropertyValueSiteData_v1_2_0(siteDataRequestProps, 'width');
                if (actualWidth != currentWidth) {
                    dispatchUpdateComponentProperty({ pageId, listId, listItemId }, path, 'width', actualWidth);
                }

                const currentHeight = getChildComponentPropertyValueSiteData_v1_2_0(siteDataRequestProps, 'height');
                if (actualHeight != currentHeight) {
                    dispatchUpdateComponentProperty({ pageId, listId, listItemId }, path, 'height', actualHeight);
                }
            }
        }
    };
    const onImageRemove = e => {
        const key = e.target.dataset.key;
        dispatch(updateAssets({ key, value: null }));
    };

    const handleRelationshipChangeEvent = (event) => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const eventValue = event.target.value;
            if (event.target.dataset.relationshipBListId != null) {
                props.onChange('relationshipBListId', eventValue);
                props.onChange('relationshipBListItemId', null);
            } else if (event.target.dataset.relationshipBListItemId != null) {
                // it's possible they set this to empty, so don't dispatch an update
                if (eventValue) {
                    props.onChange('relationshipBListItemId', eventValue);
                    dispatchUpdateRelationship(relationshipAListId, relationshipAListItemId, relationshipBListId, eventValue);
                }
            } else {
                throw new Error('Handle change event `relationshipBListId` or `relationshipBListItemId` must be set to a value or null.')
            }
        }
    };

    const dispatchUpdateRelationship = (relationshipAListId, relationshipAListItemId, relationshipBListId, relationshipBListItemId) => {
        if (!relationshipAListId || !relationshipAListItemId || !relationshipBListId || !relationshipBListItemId) throw new Error('Handle change event `pageId` or `listId` must be set to a value or null.')

        let retVal;

        retVal = dispatch(updateRelationship({ listId: relationshipAListId, listItemId: relationshipAListItemId, relationshipListId: relationshipBListId, relationshipListItemId: relationshipBListItemId }));

        return retVal;
    };

    let componentsToEdit = null;

    if (relationshipAListId) {
        // const typeListOptions = property.options.map((option) => {
        //     return (
        //         <option key={option.key} value={option.key}>
        //             {option.value}
        //         </option>
        //     );
        // });\

        // todo merge w/ defaultValues prop
        const { ref: listIdAInputRef, ...listIdAInputRest } = register("relationshipAListId", { value: relationshipAListId });
        const { ref: listItemIdAInputRef, ...listItemIdAInputRest } = register("relationshipAListItemId", { value: relationshipAListItemId });
        const { ref: listIdBInputRef, ...listIdBInputRest } = register("relationshipBListId", { value: relationshipBListId });
        const { ref: listItemIdBInputRef, ...listItemIdBInputRest } = register("relationshipBListItemId", { value: relationshipBListItemId });
        const listIdBOptions = props.websiteData.style.wiml.siteData?.components?.items?.lists?.ids;

        const relationshipBListIdOptions = listIdBOptions.map((option) => {
            return (
                <option key={option} value={option}>
                    {option}
                </option>
            );
        });

        const relationshipBListItemIdOptions = convertObjectToArray(props.websiteData.style.wiml.siteData?.components?.items?.lists?.items?.[relationshipBListId]?.items?.items).map((option) => {
            return (
                <option key={option.id} value={option.id}>
                    {option.components?.items?.heading?.content?.data?.value || option.id}
                </option>
            );
        });
        // const relationshiptDataId = { "data-relationship-a-list-id": listId, "data-relationship-a-list-item-id": listItemId };
        const deleteButton = <Button className='ml-3' color="danger" onClick={handleDeleteListItemRelationship}>
            delete
        </Button>;

        componentsToEdit = (
            <>
                <h4>List Item A</h4>
                <Input type="select" innerRef={listIdAInputRef} {...listIdAInputRest} disabled>
                    <option value={relationshipAListId}>
                        {relationshipAListId}
                    </option>
                </Input>
                <Input type="select" innerRef={listItemIdAInputRef} {...listItemIdAInputRest} disabled>
                    <option value={relationshipAListItemId}>
                        {relationshipAListItemId}
                    </option>
                </Input>
                <h4>List Item B</h4>
                <Input type="select" innerRef={listIdBInputRef} {...listIdBInputRest} onChange={handleRelationshipChangeEvent} disabled={!relationshipBIsNew} data-relationship-b-list-id>
                    {defaultSelectionOption}
                    {relationshipBListIdOptions}
                </Input>
                <Input type="select" innerRef={listItemIdBInputRef} {...listItemIdBInputRest} onChange={handleRelationshipChangeEvent} disabled={!relationshipBIsNew} data-relationship-b-list-item-id>
                    {defaultSelectionOption}
                    {relationshipBListItemIdOptions}
                </Input>
                <Button color="primary" onClick={deselectListItemRelationship}>
                    back
                </Button>
                {props.relationshipBIsNew ? null : deleteButton}
            </>
        );
    }
    else if (listId) {
        const listItemComponents = props.websiteData.style.wiml.themeData.components.items.lists?.items?.[listId]?.components;
        const listItemComponentsReact = getListItemComponents(listId, listItemId, listItemComponents, props.selectedTab, props.listInstanceComponentId, props.listItemViewFormatKey, props.websiteData);
        const relationshipItemComponentsReact = getListItemRelationshipComponents(listId, listItemId,);

        componentsToEdit = (
            <>
                <div className='mb-5'>
                    <Button color="primary" onClick={deselectListItem}>
                        back
                    </Button>
                    <Button className='ml-3' color="danger" onClick={handleDeleteListItem}>
                        delete
                    </Button>
                </div>
                {/* <div>{JSON.stringify(itemData, null, 2)}</div> */}
                {listItemComponentsReact}
                {relationshipItemComponentsReact}
            </>
        );
    } else {
        const pages = props.websiteData.style.wiml.themeData.components.items.pages.items;
        const page = pages?.[pageId];

        const components = page?.components;
        // todo, instead of accessing page.components, access page.sections...
        // todo migrate - if no wiml needed this would be a new version of wip
        componentsToEdit = getPageChildSectionComponents_v1_3_0(props, pageId, components, componentsMetaLookup);
        // componentsToEdit = getPageComponents_v1_3_0(pageId, components, componentsMetaLookup);
    }
    const isAdmin = props.userData.roles.includes('admin');
    let wimlInput = null

    if (isAdmin) {
        const listId = props.listId;
        const listItemId = props.listItemId;

        let listItemElem = null;

        if (listId) {

            const selectListItemViewFormat = (e) => {
                props.onChange('listItemViewFormatKey', e.target.value);
            };

            const viewListOptionElems = viewListOptions.map((option) => {
                return (
                    <option key={option.key} value={option.key}>
                        {option.value}
                    </option>
                );
            });

            const listItemViewFormatInputComponent = (
                <Input type="select" value={props.listItemViewFormatKey} onChange={selectListItemViewFormat}>
                    {viewListOptionElems}
                </Input>
            );


            listItemElem = (
                <div className="my-2">
                    <h6>List: {listId}</h6>
                    <h6>List Item: {listItemId}</h6>
                    {listItemViewFormatInputComponent}
                </div>
            );
        }

        const clicker = (e) => {
            e?.preventDefault();
            wimlEditorWindow.current = null;
            props.onChange('pop', !props.pop);
        }

        const opener = (window) => {

            wimlEditorWindow.current = window;
        }


        // wimlInput = <WimlBoy listItemElem={listItemElem} handleChangeEvent={handleChangeEvent} clicker={clicker} {...props} />;

        const bb = props.pop ? <NewWindow onOpen={opener} onUnload={clicker} features={{ width: 800, height: 600 }}><WimlBoy listItemElem={listItemElem} onDiff={handleChangeEvent} clicker={clicker} {...props} /></NewWindow> : <><WimlBoy listItemElem={listItemElem} onDiff={handleChangeEvent} clicker={clicker} {...props} /></>;
        wimlInput = <>
            {/* <pre>{JSON.stringify(props.websiteData.style.wiml.themeData.markup, null, 2)}</pre> */}
            {bb}
        </>

    }

    let extraComponents = null;
    if (pageId == 'page__products') {
        extraComponents = <WimlProducts {...props} />;
    }

    let groupedComponents = null;

    // separating forms because we call `reset` which will reset everything in the form, and we don't want to reset the wiml input
    // the wiml. when they were both in the same form, the `reset` call would cause this to lose its value
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Form>
                {wimlInput}
            </Form>
            <Form>
                {groupedComponents}
            </Form>
            <Form>
                {extraComponents}
                {componentsToEdit}
            </Form>
        </DragDropContext>
    );

    // if not new wiml needed, new version of wip.
    function getPageChildSectionComponents_v1_3_0(props, pageId, components, componentsMetaLookup) {
        // find all containers - they should be top-level only
        const containerComponents = components
            ?.ids
            .map((componentId) => {
                const component = { ...components.items[componentId], id: componentId };
                return component;
            })
            .filter((component) => component.type == 'Container');

        const containerAndChildrenComponents = containerComponents.map((containerComponent, index) => {
            const nodeChildren = getPageChildNodeChildren_v1_3_0(props, pageId, containerComponent.type, containerComponent.key);
            const nodeChildrenComponentItemsArray = nodeChildren.map((node) => {
                const componentId = _getThemeDataComponentId_v1_2_0({ type: node.tag, key: node.attr?.key });
                if (components.items[componentId]) {
                    const component = { ...components.items[componentId], id: componentId };
                    return component;
                } else {
                    if (node.tag == 'List') {
                        // write regex to find potential nested list. it would be in this format
                        // const nestedListRegex = new RegExp(`list\\s+\\.${componentId}`);
                        const componentsArray = objectUtils.convertObjectToArray(components.items);
                        // console.log({ componentsArray })
                        // const foundId = componentsArray.find((component) => nestedListRegex.test(component.id));

                        const found = componentsArray.find(comp => comp.type == 'List' && comp.key == node.attr.key);
                        if (found) {
                            const component = { ...found, id: found.id };
                            return component;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                }
            }).filter(Boolean);
            const nodeChildrenComponentItemsObject = convertArrayToObject(nodeChildrenComponentItemsArray, 'id');
            const { id: containerId, ...containerItem } = containerComponent;
            const containerAndInnerComponentItemsObject = {
                [containerId]: containerItem,
                ...nodeChildrenComponentItemsObject,
            };
            const containerAndChildrenComponentIds = Object.keys(containerAndInnerComponentItemsObject);
            const containerAndChildrenComponents = { items: containerAndInnerComponentItemsObject, ids: containerAndChildrenComponentIds };

            const componentsArray = containerAndChildrenComponents?.ids?.map((componentId) => {
                // todo fix because id should be in the component
                const component = { ...containerAndChildrenComponents.items[componentId], id: componentId };
                if (component.themeProps?.adminDisplay != 'none') {
                    const properties = getComponentProperties_v1_2_0(props, component, { pageId }, componentsMetaLookup);

                    return getComponentFormGroup(props, component, properties);
                }
            }).filter(Boolean);

            const retVal = (
                <Draggable key={containerId} draggableId={containerId} index={index} disableInteractiveElementBlocking>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <Card id={"container-" + containerId}>
                                <CardHeader  {...provided.dragHandleProps} onClick={toggleContainer.bind(null, containerId)}>Container: {containerId}</CardHeader>
                                <Collapse isOpen={containerComponents.length == 1 || props.containerId === containerId}>
                                    <CardBody>
                                        {componentsArray}
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </div>
                    )}
                </Draggable>
            );

            return retVal;
        });

        const droppableRegion = <Droppable droppableId={props.selectedTab}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {containerAndChildrenComponents}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>;

        return droppableRegion;
    }

    function getListItemComponents(listId, listItemId, components, pageId, listInstanceComponentId, listItemViewFormatKey, websiteData) {
        if (!listId) throw new Error('listId is required')
        if (!listItemId) throw new Error('listItemId is required');
        if (!components) throw new Error('components is required');
        if (!pageId) throw new Error('pageId is required');
        if (!listInstanceComponentId) throw new Error('listInstanceComponentId is required');
        if (!listItemViewFormatKey) throw new Error('listItemViewFormatKey is required');
        if (!websiteData) throw new Error('websiteData is required');

        let retrievalIds;
        if (listItemViewFormatKey === 'components:page-specific') {
            let newPageId = pageId;
            if (newPageId == 'books' && websiteData.style.wiml.themeData.components.items.pages.ids.includes('book')) {
                newPageId = 'book';
            } else if (newPageId == 'blog' && websiteData.style.wiml.themeData.components.items.pages.ids.includes('post')) {
                newPageId = 'post';
            }
            retrievalIds = websiteData.style.wiml.themeData.components.items.pages.items[newPageId]?.components?.items?.[listInstanceComponentId]?.components?.ids;
        } else if (listItemViewFormatKey === 'components:all') {
            retrievalIds = components.ids;
        } else {
            throw new Error(`listItemViewFormatKey ${listItemViewFormatKey} is not supported`);
        }

        const componentsArray = retrievalIds.map((componentId) => {
            const component = { ...components.items[componentId], id: componentId };
            if (component.themeProps?.adminDisplay != 'none') {
                const properties = getComponentProperties_v1_2_0(props, component, { listId, listItemId }, componentsMetaLookup);
                return getComponentFormGroup(props, component, properties);
            }
        }).filter(Boolean);

        const retVal = componentsArray;
        return retVal;
    }

    function getListItemRelationshipComponents(listId, listItemId,) {
        let listItemRelationships = null;

        const relatedLists = props.websiteData.style.wiml.siteData.components.items.lists?.items?.[listId]?.items.items?.[listItemId]?.relationships?.lists?.items;

        let itemComponents = null;

        if (relatedLists) {
            const relatedListsArray = convertObjectToArray(relatedLists);
            itemComponents = relatedListsArray.map((relatedList) => {
                // const listItemComponent = (
                //     <div key={item.id}><ListItemRelationship item={item} component={component} onSelectListItemRelationship={selectListItemRelationship} /></div>
                // );
                const relatedListItemsArray = convertObjectToArray(relatedList.items);
                const listItemRelationshipComponents = relatedListItemsArray.map((relatedListItem) => {
                    // const labelValue = dot.pick(labelKey, item.components?.items) || `<i title=${labelKey}>EMPTY</i>`;
                    const selectListItemRelationship = () => {
                        props.onChange('relationshipAListId', listId);
                        props.onChange('relationshipAListItemId', listItemId);
                        props.onChange('relationshipBListId', relatedList.id);
                        props.onChange('relationshipBListItemId', relatedListItem.id);
                        props.onChange('relationshipBIsNew', false);
                    };
                    const listItemRelationshipComponent = (
                        <div key={relatedList.id + '_' + relatedListItem.id} >
                            <ListItemRelationship relatedListId={relatedList.id} relatedListItemId={relatedListItem.id} onClick={selectListItemRelationship} />
                        </div>

                    );

                    return listItemRelationshipComponent;
                });


                return listItemRelationshipComponents;
            });
        }

        const addNew = () => {
            props.onChange('relationshipAListId', listId);
            props.onChange('relationshipAListItemId', listItemId);
            props.onChange('relationshipBIsNew', true);
        };

        listItemRelationships = (
            <>
                <h6>Relationships</h6>
                <Button color="primary" onClick={addNew}>
                    Add
                </Button>
                {itemComponents}
            </>
        );
        const retVal = (
            <>
                {listItemRelationships}
            </>
        );

        return retVal;
    }

    function getComponentProperties_v1_2_0(props, component, { pageId, listId, listItemId }, componentsMetaLookup) {
        const propertyMeta = {
            "List.filter": false,
            "List.sort": false,
            "Image.width": false,
            "Image.height": false,
        }
        const isAdmin = props.userData.roles.includes('admin');

        const componentMeta = componentsMetaLookup[component.type];
        const componentDataId = { "data-page-id": pageId, "data-list-id": listId, "data-list-item-id": listItemId };

        let inputPropComponents = null;
        const inputProps = componentMeta.inputProps;
        if (inputProps) {
            inputPropComponents = Object.keys(inputProps)
                .filter((inputKey) => {
                    if (isAdmin) return true;

                    const fqPropKey = `${component.type}.${inputKey}`;
                    const isFiltered = propertyMeta[fqPropKey] === false;
                    return !isFiltered;
                })
                .map((inputKey) => {

                    const property = inputProps[inputKey];
                    let inputPropComponent = null;

                    if (Array.isArray(property)) {
                        throw new Error('Array input props are not supported.');
                    } else {
                        let inputComponent;

                        const inputType = property.type;
                        const inputName = `${component.id}.${inputKey}.data.expression`;
                        let defaultValue = defaultValues?.[component.id]?.[inputKey]?.data.expression;
                        if (inputType == 'date' && defaultValue) {
                            defaultValue = dateUtils.dateYYYYMMDD(new Date(defaultValue));
                        }
                        const inputLabel = property.label;
                        const inputDescription = property.description;
                        // use register to set a default value

                        const { ref: formInputRef, ...formInputRest } = register(inputName, { value: defaultValue });
                        // todo DRY THIS UP
                        switch (inputType) {
                            case 'number': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="number" step="0.01" onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'select': {
                                const typeListOptions = property.options.map((option) => {
                                    return (
                                        <option key={option.key} value={option.key}>
                                            {option.value}
                                        </option>
                                    );
                                });

                                inputComponent = (
                                    <Input type="select" id={inputName} name={inputName} innerRef={formInputRef} {...formInputRest} onChange={handlePropertyChangeEvent} {...componentDataId}>
                                        {defaultSelectionOption}
                                        {typeListOptions}
                                    </Input>
                                );
                                break;
                            }
                            case 'url': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="url" onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'short_text': {
                                inputComponent = <ReactstrapInputBlurChangeDetector onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'date': {

                                // https://stackoverflow.com/questions/49277112/react-js-how-to-set-a-default-value-for-input-date-type

                                /**
                                 *  const eventDate = props.event.schedule.date;
        const eventDateFormat = eventDate ? dateUtils.dateYYYYMMDD(new Date(eventDate)) : null;
        const schedule = { ...props.event.schedule, date: eventDateFormat };
        const abc = { ...props.event, schedule };
        const { register, formState: { errors } } = useForm({
            mode: 'onBlur',
            defaultValues: abc
        });
        
        
        src/components/manage/event-detail.js
                                 */
                                const defaultValueDate = defaultValue ? dateYYYYMMDD(new Date(defaultValue)) : null;
                                inputComponent = (
                                    // very important -- onChange must come last -- see above and see src/components/manage/event-detail.js
                                    <ReactstrapInputBlurChangeDetector type="date" id={inputName} name={inputName} innerRef={formInputRef} {...formInputRest} onChange={handlePropertyChangeEvent} {...componentDataId} defaultValue={defaultValueDate} />
                                );
                                break;
                            }
                            case 'medium_text': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="textarea" rows={2} onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'long_text': {
                                const onDiff = (newValue) => {
                                    return handleRichTextEditorChangeEvent({ pageId, listId, listItemId }, component.id, inputKey, newValue);
                                };
                                // Q: why does rich text get a unique key? A: https://app.clickup.com/25740756/docs/rhhem-14790/rhhem-5390
                                const uniqueKey = [component.id, inputKey, pageId, listId, listItemId].join('-');
                                // console.log({ uniqueKey })
                                inputComponent = <RichTextEditor key={uniqueKey} defaultValue={defaultValue} onDiff={onDiff} {...richTextConfig} />;
                                break;
                            }
                            case 'json': {
                                const jsonVal = defaultValue ? JSON.parse(defaultValue) : null;
                                inputComponent = <InputJsonEditorWrapper onDiff={handleNavigationPropertyChangeEvent(inputName, pageId, listId, listItemId)} defaultValue={jsonVal} />;
                                // inputComponent = <ReactstrapInputBlurChangeDetector type="textarea" rows={5} onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'boolean': {
                                inputComponent = <CustomInput type="switch" id={inputName} name={inputName} innerRef={formInputRef} {...formInputRest} onChange={handlePropertyChangeEvent} {...componentDataId} />;
                                break;
                            }
                            case 'list_item': {
                                throw new Error('Array input props are not supported.');
                            }
                            case 'image': {
                                const imageUrl = defaultValue;
                                inputComponent = (
                                    <>
                                        <Input name={inputName} type="file" accept="image/*" {...componentDataId} onChange={onImageAdd} /*todo: put this in global style={{ color: "transparent" }}*/ />
                                        <ReactstrapInputBlurChangeDetector type="url" onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />

                                        {imageUrl &&
                                            <>
                                                <div>
                                                    <Button outline color="primary" data-key='Image' onClick={onImageRemove}>
                                                        <FontAwesomeIcon icon={faTrash} /> Remove
                                                    </Button>
                                                </div>
                                                <div>
                                                    <img src={imageUrl} className="img-fluid shadow mt-4" {...componentDataId} data-name={inputName} onLoad={onImageLoad} />
                                                </div>
                                            </>}

                                    </>
                                );
                                break;
                            }
                            default: {
                                throw new Error(`Unknown input type: ${inputType}`);
                            }
                        }

                        const reactComponent = (
                            <FormGroup key={inputKey}>
                                <Label for={inputName} title={inputDescription}>{inputLabel}</Label>
                                {inputComponent}
                            </FormGroup>
                        );
                        inputPropComponent = reactComponent;
                    }

                    return inputPropComponent;
                });
        }

        let listItemComponents = null;

        const listProps = componentMeta.listProps;
        if (listProps) {
            listItemComponents = getListItemsComponents_v1_3_0(pageId, component, props, dispatchCreateNewListItem);
        }
        const retVal = (
            <>
                {inputPropComponents}
                {listItemComponents}
            </>
        );

        return retVal;
    }
}

export default WimlEditor;
const ListItemRelationship = props => {
    const relatedListId = props.relatedListId;
    const relatedListItemId = props.relatedListItemId;

    const retVal = (
        <Button color="primary" onClick={props.onClick}>
            <h6>{relatedListId} | {relatedListItemId}</h6>
        </Button>
    );
    return retVal;
};
function getPageChildNodeChildren_v1_3_0(props, pageId, componentType, componentKey) {
    const rootNode = JSON.parse(props.websiteData.style.wiml.themeData.components.items.rootNodeJson);

    const { type: pageType, key: pageKey } = getComponentTypeAndKeyFromId_v1_3_0(pageId);

    const pageNode = findNodeInTree_v1_3_0(rootNode, pageType, pageKey);
    if (!pageNode)
        throw new Error(`Could not find page node for ${pageId}`);

    const foundNode = findNodeInTree_v1_3_0(pageNode, componentType, componentKey);
    if (!foundNode)
        throw new Error(`Could not find node for ${componentKey}`);

    const nodeChildren = flatNodeChildren_v1_3_0(foundNode);
    return nodeChildren;
}

export function getComponentTypeAndKeyFromId_v1_3_0(componentId) {
    let type;
    let key;
    if (componentId.startsWith('header')) {
        type = 'Header';
    } else if (componentId.startsWith('footer')) {
        type = 'Footer';
    } else if (componentId.startsWith('page__')) {
        type = 'Page';
        key = componentId.replace('page__', '');
    } else {
        const split = componentId.split('__');
        type = split[0];
        key = split[1];
    }
    return { type, key };
}

const acceptedDefaultTypes = ['Container', 'Navigation'];
function getComponentFormGroup(props, component, properties) {
    const isAdmin = props.userData.roles.includes('admin');

    let header;
    if (component.key) {
        // convert dash case to title case
        const newHeader = _.startCase(component.key.replace(/-/g, ' '));
        header = (
            <h5>{component.type} <span className="text-secondary"> {newHeader}</span></h5>
        );
    } else {
        if (acceptedDefaultTypes.includes(component.type) || isAdmin) {
            header = (
                <h5>{component.type} <span className="text-secondary"> [default]</span></h5>
            );
        } else {
            return;
        }
    }

    const componentId = component.id;
    const camlizedComponentId = humps.decamelize(componentId, { separator: '-' });

    const retVal = (
        // <div key={componentId} className="my-4" data-wiml-component-id={camlizedComponentId}>
        <div key={componentId} className="my-4" id={camlizedComponentId}>
            {/* <pre>{camlizedComponentId}</pre> */}
            {/* <pre>{JSON.stringify(component, null, 2)}</pre> */}
            {header}
            {properties}
        </div>
    );

    return retVal;
}


const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
        .split("\n")
        .map((line, i) => `<span id="container_editor_line_number__${i + 1}" class="container_editor_line_number">${line}</span>`)
        .join("\n");
function handleHighlight(code) {
    return hightlightWithLineNumbers(code, languages.html);
}

// todo consider refer to jsoninputwrapper and create a wrapper for this that is just for syntax editor
function WimlBoy(props) {
    function handleChange(code) {
        setValue(code);
    }

    function inputBlur(e) {
        const newVal = e.target.value;
        if (newVal != prevVal) {
            props.onDiff && props.onDiff(e);
        }
    };

    // because wimlboy can be opened in a new window, react-hook-form is bound to a dom node that no longer exists.
    // if you dig, you'll see _f.ref is not in the new window popup, but rather in the original window.
    // react-hook-form is unaware the children are moved to a new dom node via portal.
    // the easiest way is to just remount the component so it's boudn to whatever window it was created in.
    const defaultValues = props.websiteData;
    const { register, reset, formState } = useForm({
        // shouldUnregister: false,
        mode: 'onBlur', //todo is it possible to make ONLY onBlur and exclude onChange?
        defaultValues: defaultValues,
    });
    const errors = formState.errors;

    const [value, setValue] = useState(props.websiteData.style.wiml.themeData.markup);
    const prevVal = props.websiteData.style.wiml.themeData.markup;
    // const prevVal = useRef(props.websiteData.style.wiml.themeData.markup);

    useEffect(() => {
        // this is necessary for pending changes to show up in the editor after first render
        const c = props.websiteData.style.wiml.themeData.markup;
        setValue(c);

    }, [props.websiteData.style.wiml.themeData.markup]);

    const { ref: wimlMarkupRef, ...wimlMarkupRest } = register('style.wiml.themeData.markup', { required: true, });
    return (
        < >
            <FormGroup>
                <Label for="style.wiml">WIML</Label>
                <br />
                <Button color="primary" onClick={props.clicker}>Move WIML code</Button>
                {/* <input invalid={!!errors.wiml} type="textarea" rows={20} {...wimlMarkupRest} ref={wimlMarkupRef} onDiff={props.handleChangeEvent} /> */}
                {/* <ReactstrapInputBlurChangeDetector invalid={!!errors.wiml} type="textarea" rows={20} {...wimlMarkupRest} innerRef={wimlMarkupRef} onDiff={props.handleChangeEvent} /> */}
                <div className="wiml-theme-data-markup-editor-container">
                    <Editor className="wiml-theme-data-markup-editor"
                        name="style.wiml"
                        onBlur={inputBlur}
                        onValueChange={handleChange}
                        highlight={handleHighlight}
                        value={value} />
                </div>
            </FormGroup>
            <div className="text-muted mb-4">
                <h6>Website: {props.websiteData.id}</h6>
                {props.listItemElem}
            </div>
            <style jsx global>{`
                .wiml-theme-data-markup-editor{
                    /* padding-left: 3rem; */
                    counter-reset: line;
                }
                /* only matches if within sidebar, not if in newwindow */
                :global(.sidebar-editor) .wiml-theme-data-markup-editor-container {
                    max-height: 400px;
                    overflow: auto !important;
                }
                .container_editor_line_number {
                    /* padding-left: 1rem; */
                }
                .container_editor_line_number:before {
                    position: absolute;
                    left: 0;
                    text-align: right;
                    opacity: .3;
                    user-select: none;
                    counter-increment: line;
                    content: counter(line);
                }
            `}</style>
        </ >
    );
}

export const findNodeInTree_v1_3_0 = (node, type, key) => {
    const formattedKey = unFormatNodeKey_v1_3_0(key);
    if (_isElementNode_v1_0_0(node) && node.tag == type && node.attr?.key == formattedKey) {
        return node;
    }
    if (node.child) {
        for (const child of node.child) {
            const result = findNodeInTree_v1_3_0(child, type, formattedKey);
            if (result) {
                return result;
            }
        }
    }
    // else if (node.child) {
    //     return node.child.find(child => findNodeInTree_v1_3_0(child, type, key));
    // }
    // else {
    //     return null;
    // }
}


export const flatNodeChildren_v1_3_0 = (node, includeNode = false) => {
    if (_isElementNode_v1_0_0(node)) {
        if (!node.child) {
            return [node];
        } else {
            const children = node
                .child
                .map((child) => {
                    return flatNodeChildren_v1_3_0(child, true);
                })
                .flat()
                .filter(Boolean);

            if (includeNode) {
                return [node, ...children];
            } else {
                return children;
            }
        }
    }
}

export const findNodeParentByType_v1_3_0 = (rootNode, targetNode, parentTag) => {
    const rootNodesFlatChildren = flatNodeChildren_v1_3_0(rootNode);
    const parentNodes = rootNodesFlatChildren.filter((node) => node.tag === parentTag);
    const parentNode = parentNodes.find((node) => flatNodeChildren_v1_3_0(node).includes(targetNode));
    return parentNode;
}

import { JSONEditor } from "@json-editor/json-editor/dist/nonmin/jsoneditor";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

// conslider not using svg above or idk. this lib will prefill the fontawesome classes
// see iconlib.
import '@fortawesome/fontawesome-free/css/all.css'
import { getListItemsComponents_v1_3_0 } from './list-items-v1.3.0';
import { unFormatNodeKey_v1_3_0 } from '@wip/common/app/wiml/versions/v1/v1.3.0/theme-data/compiler/components';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/site-data/retrieval';

const InputJsonEditorWrapper = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return <div>
        <Button color="primary" onClick={toggle}>
            Edit
        </Button>
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <InputJsonEditor {...props} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Done
                </Button>
            </ModalFooter>
        </Modal>
    </div>
}

const InputJsonEditor = (props) => {

    const ref = useRef();
    // const [editor, setEditor] = useState<JSONEditor>();
    const editor = useRef();
    const initialChangeEventFired = useRef(false);
    // const editorValue = useRef();
    useEffect(() => {
        if (true) {
            if (!editor.current) {
                editor.current = new JSONEditor(ref.current, {
                    theme: "bootstrap4",
                    iconlib: "fontawesome5",
                    disable_collapse: true,
                    disable_array_delete_last_row: true,
                    disable_array_delete_all_rows: true,
                    collapsed: false,
                    startval: props.defaultValue,
                    schema: {
                        $schema: "https://json-schema.org/draft/2020-12/schema",
                        $defs: {
                            navigationItemProperties: {
                                type: "object",
                                properties: {
                                    text: {
                                        type: "string",
                                        title: "Text",
                                    },
                                    url: {
                                        type: "string",
                                        title: "URL",
                                    },
                                },
                            },
                            navigationItem: {
                                type: "object",
                                title: "Navigation",
                                headerTemplate: "{{i}} - {{self.text}}",
                                allOf: [
                                    { $ref: "#/$defs/navigationItemProperties" },
                                    {
                                        properties: {
                                            children: {
                                                type: "array",
                                                title: "Children",
                                                format: "table",
                                                items: {
                                                    $ref: "#/$defs/navigationItemProperties",
                                                    title: "Child Navigation",
                                                    headerTemplate: "{{i}} - {{self.text}}",
                                                },
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                        type: "array",
                        title: "Navigation",
                        format: "table",
                        items: {
                            $ref: "#/$defs/navigationItem",
                        },
                    },
                });

                editor.current.on("change", () => {
                    if (initialChangeEventFired.current) {
                        // const newValue = JSON.stringify(editor.current.getValue());
                        // if (editorValue.current !== newValue) {
                        props.onDiff(editor.current.getValue());
                        // }
                        // editorValue.current = newValue;
                    } else {
                        initialChangeEventFired.current = true;
                    }
                });
            }

            return () => {
                // destroy editor
                if (editor.current) {
                    console.log("destroy editor");
                    // for (const callback in editor.current.callbacks) {
                    //     const callbackArray = editor.current.callbacks[callback];
                    //     for (const cb of callbackArray) {
                    //         editor.current.off(callback, cb);
                    //     }
                    // }
                    editor.current.off();
                    editor.current.destroy();
                    editor.current = null;
                }
            };
        }
    }, []);

    return <div id="jsoneditor" ref={ref} />;
};
