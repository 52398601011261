import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import ImageGallery from "react-image-gallery";

import humps from 'humps';
import React, { useRef, useState } from 'react';
import _ from 'lodash';


import { sortListItems } from '../../../../../../../../domain/list-sort';
import * as dateUtils from '../../../../../../../../lib/date-utils';
import { slugify } from '../../../../../../../../lib/text-utils';
import { getInferredComponentDefaultType_v1_0_0, _getComponentId_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { getListItemsSiteDataArray_v1_0_0 } from '../../../../v1.0.0/site-data/retrieval';
import { getComponentClassName_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/render-components';
import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../../v1.0.0/site-data/retrieval';
import { SiteDataListItem_v1, RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { List_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/list';
import { Slider_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/list-slider';
import { cloneReactChildren_v1_0_0, renderChildren_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/render-components';
import { useStyles_V1_2_0 } from '../utils/style-provider';
import Website from '../../../../../../../../domain/website';
import { breakpointsMin_v1_1_0, breakpoints_v1_1_0 } from '../../../../v1.1.0/theme-data/renderer/utils/breakpoints';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from '../../../site-data/retrieval';
import { isValidNonFragmentElement_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/nodes';
import { is } from 'cheerio/lib/api/attributes';
import { requiredGalleryComponentTypes_v1_2_0 } from '../../compiler/page-components';

const DEFAULT_LIST_KEY = 'default';
const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0("List");

export function List_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const [galleryIsOpen, setGalleryIsOpen] = useState(false);
    let galleryStartUrlRef = useRef<string>(null);

    const { registerGlobalStyles } = useStyles_V1_2_0();
    const [minImageWidth, setMinImageWidth] = useState<string>();
    const [minImageHeight, setMinImageHeight] = useState<string>();
    let retVal: React.ReactNode = null;
    // lists are always page-level but it's possible they'll be nested and get a dataRetrievalListId but we need to remove that
    const { dataRetrievalListComponenyKey: parentListComponentKey, listInstanceComponentId: parentlistInstanceComponentId, dataRetrievalListId: parentListId, dataRetrievalListItemId: parentListItemId, ...listCleansedProps } = props;
    if (parentListComponentKey) {
        listCleansedProps.componentKey = parentListComponentKey + "." + listCleansedProps.componentKey
        listCleansedProps.componentId = parentlistInstanceComponentId + "." + listCleansedProps.componentId
    }

    const potentiallyNestedListId = humps.camelize(listCleansedProps.listId || listCleansedProps.componentKey || DEFAULT_LIST_KEY);
    // const listId = potentiallyNestedListId.split('.').pop() || potentiallyNestedListId;
    const listId = listCleansedProps.listId;

    const filter = getChildComponentPropertyValueSiteData_v1_2_0(listCleansedProps, 'filter') || listCleansedProps.filter;
    const sort = getChildComponentPropertyValueSiteData_v1_2_0(listCleansedProps, 'sort') || listCleansedProps.sort;
    const limit = getChildComponentPropertyValueSiteData_v1_2_0(listCleansedProps, 'limit') || listCleansedProps.limit;

    const itemsArray = getListItemsSiteDataArray_v1_0_0(listCleansedProps, listId);

    let filteredArray = itemsArray;
    if (filter?.toString()?.startsWith(`{{slugify(current_item.components.items.heading.content.data.value) == current_page.request.query.`) && filter?.toString()?.endsWith(`}}`)) {
        const queryParam = filter.toString().replace('{{slugify(current_item.components.items.heading.content.data.value) == current_page.request.query.', '').replace('}}', '').trim();
        const bookHeadingFilter = listCleansedProps.currentPage.request.query[queryParam];
        filteredArray = itemsArray.filter((item) => {
            let retVal = false;
            const headingValue = item.components?.items?.heading?.content?.data?.value;
            if (headingValue) {
                const heading = slugify(headingValue);
                retVal = heading == bookHeadingFilter;
            }
            return retVal;
        });
    } else if (filter?.toString()?.startsWith(`{{slugify(current_item.components.items.heading__title.content.data.value) == current_page.request.query.`) && filter?.toString()?.endsWith(`}}`)) {
        const queryParam = filter.toString().replace('{{slugify(current_item.components.items.heading__title.content.data.value) == current_page.request.query.', '').replace('}}', '').trim();
        const bookHeadingFilter = listCleansedProps.currentPage.request.query[queryParam];
        filteredArray = itemsArray.filter((item) => {
            let retVal = false;
            const headingValue = item.components?.items?.heading__title?.content?.data?.value;
            if (headingValue) {
                const heading = slugify(headingValue);
                retVal = heading == bookHeadingFilter;
            }
            return retVal;
        });
    } else if (filter == "{{isPast(current_item.components.items.date.date.data.value)}}") {
        filteredArray = itemsArray
            .filter((item) => item.components?.items?.date?.date?.data?.value)
            .filter((item) => {
                // todo cast types ahead of time, not here
                const isPast = dateUtils.isPast(new Date(item.components.items.date.date.data.value as string));
                return isPast;
            });
    } else if (filter == "{{isPast(current_item.components.items.date__published.date.data.value)}}") {
        filteredArray = itemsArray
            .filter((item) => item.components?.items?.date__published?.date?.data?.value)
            .filter((item) => {
                // todo cast types ahead of time, not here
                const isPast = dateUtils.isPast(new Date(item.components.items.date__published.date.data.value as string));
                return isPast;
            });
    } else if (filter == "{{isPast(current_item.components.items.date__start.date.data.value)}}") {
        filteredArray = itemsArray
            .filter((item) => item.components?.items?.date__start?.date?.data?.value)
            .filter((item) => {
                // todo cast types ahead of time, not here
                const isPast = dateUtils.isPast(new Date(item.components.items.date__start.date.data.value as string));
                return isPast;
            });
    } else if (filter == "{{isFuture(current_item.components.items.date.date.data.value)}}") {
        filteredArray = itemsArray
            .filter((item) => item.components?.items?.date?.date?.data?.value)
            .filter((item) => {
                // todo cast types ahead of time, not here
                const isFuture = dateUtils.isFuture(new Date(item.components.items.date.date.data.value as string));
                return isFuture;
            });
    } else if (filter == "{{isFuture(current_item.components.items.date__published.date.data.value)}}") {
        filteredArray = itemsArray
            .filter((item) => item.components?.items?.date__published?.date?.data?.value)
            .filter((item) => {
                // todo cast types ahead of time, not here
                const isFuture = dateUtils.isFuture(new Date(item.components.items.date__published.date.data.value as string));
                return isFuture;
            });
    } else if (filter == "{{isFuture(current_item.components.items.date__start.date.data.value)}}") {
        filteredArray = itemsArray
            .filter((item) => item.components?.items?.date__start?.date?.data?.value)
            .filter((item) => {
                // todo cast types ahead of time, not here
                const isFuture = dateUtils.isFuture(new Date(item.components.items.date__start.date.data.value as string));
                return isFuture;
            });
    } else if (filter?.toString()?.startsWith(`{{current_item.id == "li_`) && filter?.toString()?.endsWith(`"}}`)) {
        // regex /"li_([^"]+)"/
        const id = filter.toString().replace('{{current_item.id == "', '').replace('"}}', '').trim();
        filteredArray = itemsArray
            .filter((item) => item.id == id);
    } else if (filter == "{{current_item.relationships.lists.items[render_props.parent_list_id].ids.includes(render_props.parent_list_item_id)}}") {
        filteredArray = itemsArray.filter((item) => {
            const isRelated = item?.relationships?.lists?.items?.[parentListId]?.ids?.includes(parentListItemId)
            return isRelated;
        });
    } else if (filter == "{{current_item.relationships.lists.ids.length == 0}}") {
        filteredArray = itemsArray.filter((item) => {
            // loop through relationships and if there are none, return true
            const hasAnyRelationships = (Object.keys(item.relationships?.lists?.items || {}).some((key) => {
                const relationship = item.relationships.lists.items[key];
                return relationship.ids.length > 0;
            }));
            return hasAnyRelationships == false;
        });
    } else if (filter == "{{current_item.id == current_page.request.query.product}}") {
        const id = listCleansedProps.currentPage.request.query['thank-you']; // todo this should probably not be thank-you but product instead
        // eventually we should make orders -> line items -> products via list relationships. until then, treat like navigation - its own thing.
        filteredArray = itemsArray
            .filter((item) => item.id == id);
    }

    const sortedArray = sortListItems(sort as string, filteredArray);

    let limitedArray = sortedArray;
    if (limit) {
        limitedArray = _limitListItemsArray(sortedArray, limit as number);
    }

    const finalArray = limitedArray;

    // todo move this logic to the compiler step in v1.2.0
    // todo why is concat needed?
    // const listComponentId = humps.camelize('list-' + listCleansedProps.componentKey);
    // note there is a discrepancy between the compiler and renderer. for some reason, the componentKey in renderer is not fqid, just key. e.g. in compiler its listBooks, but renderer its books.
    // const listInstanceComponentId = getListInstanceComponentThemeDataId_v1_0_0(listCleansedProps, COMPONENT_TYPE);
    const listInstanceComponentId = listCleansedProps.componentId;
    const childrenThemeDataMeta = getChildrenThemeDataMeta_v1_2_0(listInstanceComponentId, listCleansedProps.pageDefinitionId, props.websiteData);
    let minImageWidthRatioIds: number[] | null = null;
    let minImageHeightRatioIds: number[] | null = null;
    if (childrenThemeDataMeta?.galleryView) {
        const childrenSiteDataMeta = getChildrenSiteDataMeta_v1_2_0(listInstanceComponentId, listCleansedProps.pageDefinitionId, finalArray, props.websiteData);
        minImageWidthRatioIds = childrenSiteDataMeta?.minImageWidthRatioIds ?? null;
        minImageHeightRatioIds = childrenSiteDataMeta?.minImageHeightRatioIds ?? null;
        // todo remove elvis operator
        //         registerGlobalStyles?.(`.list-item img {
        // width: 50px;
        // }`);
    }
    const className = getComponentClassName_v1_0_0(listCleansedProps, 'list-item', childrenThemeDataMeta?.galleryView && 'gallery-view');
    const topLevelListComponentKey = listCleansedProps.componentKey;
    const topLevelProps = { className, topLevelListComponentKey };

    const onTargetImageLoad = (imgData: any) => {
        const minWidthImg = imgData.minWidthImg;
        if (minWidthImg) {
            // if (minImageWidthPx) {
            //     setMinImageWidthPx('');
            // }
            setMinImageWidth(`${minWidthImg.width}px`);
            console.log(imgData.listItemId, 'width', minWidthImg.width);
        }
        const minHeightImg = imgData.minHeightImg;
        if (minHeightImg) {
            // if (minImageHeightPx) {
            //     setMinImageHeightPx('');
            // }
            setMinImageHeight(`${minHeightImg.height}px`);
            console.log(imgData.listItemId, 'height', minHeightImg.height);
        }
    };


    const onTargetImageLoad2 = (imgData: any) => {
        const minWidthImg = imgData.minWidthImg;
        if (minWidthImg) {
            if (minImageWidth) {
                setMinImageWidth('100%');
            }
            setTimeout(() => {
                setMinImageWidth(`${minWidthImg.width}px`);
                console.log(imgData.listItemId, 'width', minWidthImg.width);
            }, 200);
        }
        const minHeightImg = imgData.minHeightImg;
        if (minHeightImg) {
            if (minImageHeight) {
                setMinImageHeight('100%');
            }
            setTimeout(() => {
                setMinImageHeight(`${minHeightImg.height}px`);

                console.log(imgData.listItemId, 'height', minHeightImg.height);
            }, 200);
        }
    };

    const galleryRef = useRef(null);

    const openGallery = (url: string) => {
        galleryStartUrlRef.current = url;
        setGalleryIsOpen(true);
    }

    const galleryCloser = () => {
        setGalleryIsOpen(false);
    }

    const _onSlide = () => {
        // debugger;
        // const b = galleryRef.current.getCurrentIndex();
        // setGalleryIsOpen(false);
        const stopVidepBtn = document.querySelector('.close-video');
        if (stopVidepBtn instanceof HTMLElement) {
            stopVidepBtn.click();
        }
    }

    const itemComponents = finalArray.map((item) => {
        const children = listCleansedProps.children;
        // todo why is dataRetrievalListId appearing in the dom?
        const propsForAllChildren = { listInstanceComponentId: listCleansedProps.componentId, dataRetrievalListComponenyKey: listCleansedProps.componentKey, dataRetrievalListId: listId, dataRetrievalListItemId: item.id, minImageWidthRatioIds, minImageHeightRatioIds, onTargetImageLoad, openGallery };
        const cloned = cloneReactChildren_v1_0_0(children, topLevelProps, propsForAllChildren);
        const renderedChildren = renderChildren_v1_0_0(cloned);

        const itemComponent = <React.Fragment key={item.id}>{renderedChildren}</React.Fragment>;
        return itemComponent;
    });

    let galleryComponent = null;
    if (galleryIsOpen) {
        const imageValues = finalArray.map(item => {
            const imageId = item.components?.ids.find((compId: string) => compId.startsWith('image'));
            const imageComponent = item.components?.items[imageId];
            const url = imageComponent?.url?.data?.value;
            return url;
        }).filter(Boolean);

        const images = [
            ...imageValues.map((url) => {
                return {
                    original: url,
                    thumbnail: url,
                }
            }),
            // {
            //     original: "https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/4v.jpg",
            //     thumbnail: "https://img.youtube.com/vi/2PSizSdN00U/0.jpg",
            //     videoUrl: "https://www.youtube.com/watch?v=2PSizSdN00U",
            //     renderItem: _renderVideoReactPlayer,
            // }
        ];

        const urlIndex = imageValues.indexOf(galleryStartUrlRef.current);
        const startIndex = urlIndex > -1 ? urlIndex : 0;

        // const images = [
        //     {
        //         original: "https://picsum.photos/id/1018/1000/600/",
        //         thumbnail: "https://picsum.photos/id/1018/1000/600/",
        //         // thumbnail: "https://picsum.photos/id/1018/250/150/",
        //     },
        //     {
        //         original: "https://picsum.photos/id/1015/1000/600/",
        //         thumbnail: "https://picsum.photos/id/1015/1000/600/",
        //         // thumbnail: "https://picsum.photos/id/1015/250/150/",
        //     },
        //     {
        //         original: "https://picsum.photos/id/1019/1000/600/",
        //         thumbnail: "https://picsum.photos/id/1019/1000/600/",
        //         // thumbnail: "https://picsum.photos/id/1019/250/150/",
        //     },
        // ];
        galleryComponent = (
            <Modal isOpen={true} toggle={galleryCloser} size="lg" centered className='gallery-modal'>
                <ModalHeader toggle={galleryCloser}></ModalHeader>
                <ModalBody toggle={galleryCloser}>
                    <ImageGallery showPlayButton={false} showFullscreenButton={false}
                        items={images} startIndex={startIndex}
                        onSlide={_onSlide}
                        ref={(i: any) => galleryRef.current = i}
                    />
                </ModalBody>
            </Modal>
        );
    }


    if (listCleansedProps.slider != null) {
        retVal = (
            <Slider_v1_0_0 {...listCleansedProps}>
                {itemComponents}
            </Slider_v1_0_0>
        );
    } else {
        retVal = itemComponents;
    }
    const htmlPreparedTopLevelComponentKey = topLevelListComponentKey.replace(/\./g, "__");
    const listComponentKeyQualifiedListItemId = `list_${htmlPreparedTopLevelComponentKey}`;
    return <>
        {retVal}
        {galleryComponent}
        <style jsx>{`
            @media ${breakpoints_v1_1_0.md} { 
                :global(.${listComponentKeyQualifiedListItemId}) :global(img) {
                    max-width: ${minImageWidth};
                }
            }
            @media ${breakpointsMin_v1_1_0.md} { 
                :global(.${listComponentKeyQualifiedListItemId}) :global(img) {
                    max-height: ${minImageHeight};
                }
            }
        `}</style>
    </>;
}

List_v1_2_0.listProps = List_v1_0_0.listProps;
List_v1_2_0.inputProps = List_v1_0_0.inputProps;

function _limitListItemsArray(itemsArray: any, limit: number) {
    const retVal = _.take(itemsArray, limit || itemsArray.length);

    return retVal;
}

export function getChildrenThemeDataMeta_v1_2_0(listInstanceComponentId: string, pageDefinitionId: string, websiteData: Website) {
    const defaultMeta = { galleryView: false };

    const meta = websiteData.getPageChildListInstanceComponentThemeData(pageDefinitionId, listInstanceComponentId).meta || defaultMeta;

    return meta;
}


export function getChildrenSiteDataMeta_v1_2_0(listComponentKey: string, pageDefinitionId: string, itemsArray: SiteDataListItem_v1[], websiteData: Website) {
    const retVal = { minImageHeightRatio: 0, minImageHeightRatioIds: [], minImageWidthRatio: 0, minImageWidthRatioIds: [], };
    if (!itemsArray || itemsArray.length === 0) {
        return retVal;
    }
    const listId: string = websiteData.getPageChildListInstanceComponentThemeData(pageDefinitionId, listComponentKey).listId;
    const camlizedListId = humps.camelize(listId);

    const ids: string[] = websiteData.getPageChildComponentThemeData(pageDefinitionId, listComponentKey).components.ids;
    const listThemeData = websiteData.getListComponentThemeData(listId);
    const idsToComponents = ids.map((id) => {
        const component = { ...listThemeData.components.items[id], id };
        return component;
    });
    const requiredGalleryComponents = idsToComponents.filter((component) => {
        const isRequiredGalleryComponent = requiredGalleryComponentTypes_v1_2_0.includes(component.type.toLowerCase());
        return isRequiredGalleryComponent;
    });
    const imageComponents = requiredGalleryComponents.map((component) => {
        return component;
    }).map((component) => component.id);

    const imageHeights = itemsArray.flatMap(item => imageComponents.map(ic => ({ id: item.id, ...item.components.items[ic] }))).map((component) => {
        const height = component?.height?.data?.value as number || 0;
        const width = component?.width?.data?.value as number || 0;
        let heightRatio = 0;

        if (height && width) {
            heightRatio = height / width;
        }

        return { height: heightRatio, id: component.id };
    });

    const groupedByHeight = _.groupBy(imageHeights, 'height');
    // get min image image height from keys of groupedByHeight which are heights
    const minImageHeight = Math.min(...Object.keys(groupedByHeight).map(Number));
    const minImageHeightIds = groupedByHeight[minImageHeight].map((item) => item.id);

    retVal.minImageHeightRatio = minImageHeight;
    retVal.minImageHeightRatioIds = minImageHeightIds;

    const imageWidths = itemsArray.flatMap(item => imageComponents.map(ic => ({ id: item.id, ...item.components.items[ic] }))).map((component) => {
        const width = component?.width?.data?.value as number || 0;
        const height = component?.height?.data?.value as number || 0;
        let widthRatio = 0;
        if (width && height) {
            widthRatio = width / height;
        }
        return { width: widthRatio, id: component.id };
    });

    const groupedByWidth = _.groupBy(imageWidths, 'width');
    // get min image image height from keys of groupedByHeight which are heights
    const minImageWidth = Math.min(...Object.keys(groupedByWidth).map(Number));
    const minImageWidthIds = groupedByWidth[minImageWidth].map((item) => item.id);

    retVal.minImageWidthRatio = minImageWidth;
    retVal.minImageWidthRatioIds = minImageWidthIds;

    return retVal;
}


function _renderVideo(item: Record<string, string>) {
    return <img className="image-ffgallery-image" src="https://lh3.googleusercontent.com/pw/AP1GczNyVefPUppY7QFKJ2t710ELeLV_T5JjGqWEYy827C--sw8IqT1mBzPr7gp7CM41Gke8c3WsWMg3De4403tvgjzsbfzmTlCAB71eoAF9Tn76uxV6TiJ-41MJlucXML56Ovmx_pLzExrdOMPWIypcVqvZJA=w739-h981-s-no-gm"></img>
}

import ReactPlayer from 'react-player';

function _renderVideoReactPlayer2(item: Record<string, string>) {
    return <ReactPlayer url={item.videoUrl} />
}
function _renderVideoReactPlayer(item: Record<string, string>) {
    return <MediaVideo videoSrc={item.videoUrl} thumbnailSrc={item.thumbnail} />
}

function MediaVideo(props: React.PropsWithChildren<Record<string, any>>) {
    const [showVideo, setShowVideo] = useState(false);
    const _toggleShowVideo = () => {
        setShowVideo(!showVideo);
    }

    const retVal = (
        <div>
            {showVideo ? (
                <div className="video-wrapper">
                    <button className="close-video" onClick={_toggleShowVideo} />
                    <ReactPlayer url={props.videoSrc} playing={true} controls={true} />
                </div>
            ) : (
                <>
                    <button className="play-button" onClick={_toggleShowVideo} />
                    <img
                        alt="sample video cover"
                        className="image-gallery-image"
                        src={props.thumbnailSrc}
                    />
                </>
            )}
        </div>
    );

    return <>
        {retVal}
        <style jsx global>{`

.play-button {
  cursor: pointer;
  position: absolute;
  border: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
}

.play-button:hover {
  background-color: rgba(0, 0, 0);
}

.play-button:after {
  content: "";
  display: block;
  position: absolute;
  top: 16.5px;
  left: 40px;
  margin: 0 auto;
  border-style: solid;
  border-width: 12.5px 0 12.5px 20px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 1);
}

.close-video::before {
  content: "✕";
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 50px;
  font-size: 30px;
  padding: 20px;
  z-index: 1;
  line-height: 0.7;
  display: block;
  text-shadow: 0 2px 2px #1a1a1a;
  color: #fff;
}

.video-wrapper {
  position: relative;
  padding: 33.35% 0; /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.app .image-gallery,
.app-sandbox {
  margin: 0 auto;
  width: 65%;
}

@media (max-width: 1320px) {
  .app-sandbox-content {
    padding: 0 20px;
  }
}

.app-sandbox {
  margin: 40px auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.app-buttons li {
  display: block;
}

@media (max-width: 768px) {
  .app-header {
    font-size: 20px;
  }

  .app-buttons li {
    display: block;
    margin: 10px 0;
  }

  .app-buttons li + li {
    padding: 0;
  }

  .play-button {
    height: 40px;
    width: 65px;
  }

  .play-button:after {
    top: 11px;
    left: 27px;
    border-width: 8.5px 0 8.5px 12px;
  }

  .close-video::before {
    font-size: 16px;
    padding: 15px;
  }
}

@media (max-width: 1024px) {
  .app .image-gallery,
  .app-sandbox {
    width: 100%;
  }
}

.app-interval-input-group {
  display: table;
}

.app-interval-label {
  display: table-cell;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 3px solid #ccc;
  border-right: none;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-interval-input {
  -webkit-appearance: none;
  display: table-cell;
  margin: 0;
  padding: 9px;
  border-radius: 5px;
  font-size: 14px;
  border: 3px solid #ccc;
  background: #fff;
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.app-interval-input {
  width: 65px;
}

.app-checkboxes {
  margin-top: 10px;
}

.app-checkboxes li {
  display: block;
}

        `}</style>
    </>

}
