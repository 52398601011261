// https://dev.to/thatgalnatalie/how-to-get-started-with-redux-toolkit-41e
import { nanoid, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { appendToFilename, websiteService } from '@wip/common';
import { convertArrayToObject, convertObjectToArray, generateId, makeSerialilzable } from '@wip/common/lib/object-utils';
import Website, { WEBSITE_PREFIXES } from '../domain/website';
import { PRODUCT_ROOT } from '@wip/common/lib/analytics-utils';

import firebaseClient, { getFirebaseKeyV3 } from '@wip/common/lib/firebase';
import { analyticsUtils } from '@wip/common'
import humps from 'humps';
import { toTitleCase } from '../lib/text-utils';

// TODO FIX THIS SOMEHOW
// MOVE TO A CLASS OR PUT THIS CONDITINALS IN THE LIB/FIREBASE-CLIENT 
const backupApiUrl = process.env.BACKUP_API_URL || process.env.REACT_APP_BACKUP_API_URL || process.env.NEXT_PUBILC_BACKUP_API_URL;

const websiteApi = websiteService.getClient(firebaseClient);

// TODO FIX THIS BY MAKING THIS MODULE IMPLEMENT GETCLIENT AS WELL.
// MOVE TO A CLASS OR PUT THIS CONDITINALS IN THE LIB/FIREBASE-CLIENT 
const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;
const tracker = analyticsUtils.getClient(segmentWriteKey);

export const initialState = {
  isLoading: false,
  isSaving: false,
  hasErrors: false,
  isReplayed: false,
  isReplaying: false,
  // todo -- should data be shortened to jsut {}??
  data: {},
  pendingChanges: {
    queue: [],
  },
  history: {
    snapshots: [],
  }
};

const websiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    getData: (state) => {
      state.isLoading = true;
    },
    getDataSuccess: (state, { payload }) => {
      // usually, we need to write a lot of code to make a reducer immutable. but the rtk prdocues a copy of the orig object before we get here, so it's safe to modify.
      // read more https://redux-toolkit.js.org/usage/immer-reducers#redux-toolkit-and-immer

      // DOES THIS WORK??? OR DO WE NEED INITIAL DATA
      state.data = { ...state.data, ...payload };

      state.isLoading = false;
      state.hasErrors = false;
    },
    getDataFailure: (state) => {
      state.isLoading = false;
      state.hasErrors = true;
    },
    saveData: (state) => {
      state.isSaving = true;
    },
    saveDataProgress: (state) => {
      state.isSaving = true;
    },
    saveDataSuccess: (state, { payload }) => {
      // usually, we need to write a lot of code to make a reducer immutable. but the rtk prdocues a copy of the orig object before we get here, so it's safe to modify.
      // read more https://redux-toolkit.js.org/usage/immer-reducers#redux-toolkit-and-immer
      // const { slug, ...payloadWithoutMeta } = payload;

      // state.data = { ...payloadWithoutMeta, meta: { slug } };

      state.isSaving = false;
      state.hasErrors = false;
    },
    saveDataFailure: (state) => {
      state.isSaving = false;
      state.hasErrors = true;
    },





    // SHOULd we do biz logic in reducer? If so can we re-use our oop classes?
    // https://stackoverflow.com/questions/47227419/react-redux-should-reducers-contain-any-logic
    // this approach is easy to understand but it should be known this will not be performant.
    // this will result in unecessary renders as every object is new and not re-used. this throws
    // out the benefits of immutable perf.
    updateAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateAbout(action.payload.key, action.payload.value);
      });
    },
    updateOnboard: (state, action) => {
      doSiteAction(state, site => {
        site.updateOnboard(action.payload.key, action.payload.value);
      });
    },
    // redux action name must start with pattern from apps/common/event-store/pending-changes.js
    updateOnboardByAdvance: (state, action) => {
      doSiteAction(state, site => {
        site.onboardAdvance(action.payload.fromStatus);
      });
    },
    updateAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateAssets(action.payload.key, action.payload.value);
      });
    },
    updateStyle: (state, action) => {
      doSiteAction(state, site => {
        site.updateStyle(action.payload.key, action.payload.value);
      });
    },
    createPageChildComponent: (state, action) => {
      doSiteAction(state, site => {
        // { pageId: data.pageId, parentId: data.parentId, componentKey: data.componentKey, componentType: data.componentType, componentThemeProps: data.componentThemeProps }));
        // }
        site.createPageChildComponent(action.payload.pageKey, action.payload.componentType, action.payload.componentKey, action.payload.componentThemeProps);
      });
    },
    movePageChildComponent: (state, action) => {
      doSiteAction(state, site => {
        site.movePageChildComponent(action.payload.pageId, action.payload.componentId, action.payload.position);
      });
    },
    replaceStyleImportStatement: (state, action) => {
      doSiteAction(state, site => {
        site.replaceStyleImportStatement(action.payload.newImport);
      });
    },
    replaceStyleCssVars: (state, action) => {
      doSiteAction(state, site => {
        site.replaceStyleCssVars(action.payload.newCssVars);
      });
    },
    updatePageChildComponentProperty: (state, action) => {
      doSiteAction(state, site => {
        site.updatePageChildComponentProperty(action.payload.pageId, action.payload.componentId, action.payload.propertyId, action.payload.propertyValue);
      });
    },
    updateListItemComponentProperty: (state, action) => {
      doSiteAction(state, site => {
        site.updateListItemComponentProperty(action.payload.listId, action.payload.listItemId, action.payload.componentId, action.payload.propertyId, action.payload.propertyValue);
      });
    },
    createNewListItem: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createNewListItem(payload.id, payload.listId);
        });
      }, prepare: ({ listId }) => {
        const id = generateId(WEBSITE_PREFIXES.LIST_ITEM_PREFIX);

        return {
          payload: {
            id,
            listId,
          }
        };
      }
    },
    deleteListItem: (state, action) => {
      doSiteAction(state, site => {
        site.deleteListItem(action.payload.listId, action.payload.listItemId);
      });
    },
    updateRelationship: (state, action) => {
      doSiteAction(state, site => {
        site.updateRelationship(action.payload.listId, action.payload.listItemId, action.payload.relationshipListId, action.payload.relationshipListItemId);
      });
    },
    deleteRelationship: (state, action) => {
      doSiteAction(state, site => {
        site.deleteRelationship(action.payload.listId, action.payload.listItemId, action.payload.relationshipListId, action.payload.relationshipListItemId);
      });
    },
    updateContact: (state, action) => {
      doSiteAction(state, site => {
        site.updateContact(action.payload.key, action.payload.value);
      });
    },
    updateCustomHtmlPage: (state, action) => {
      doSiteAction(state, site => {
        site.updateCustomHtmlPage(action.payload.key, action.payload.value);
      });
    },
    updateEcommerce: (state, action) => {
      doSiteAction(state, site => {
        site.updateEcommerce(action.payload.key, action.payload.value);
      });
    },
    updateSocial: (state, action) => {
      doSiteAction(state, site => {
        site.updateSocial(action.payload.key, action.payload.value);
      });
    },
    addGalleryToAbout: (state, action) => {
      doSiteAction(state, site => {
        site.addGalleryToAbout(action.payload.key);
      });
    },
    removeGalleryFromAbout: (state, action) => {
      doSiteAction(state, site => {
        site.removeGalleryFromAbout(action.payload.key);
      });
    },
    updateNavigation: (state, action) => {
      doSiteAction(state, site => {
        site.updateNavigation(action.payload.key, action.payload.value);
      });
    },


    createBook: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          // NOTE: most objects have a `meta.order` prop. Although books support that, they are ordered by release_date by default.
          site.createBook(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteBook: (state, action) => {
      doSiteAction(state, site => {
        site.deleteBook(action.payload.id);
      });
    },
    updateBookAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateBookAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateBookPurchase: (state, action) => {
      doSiteAction(state, site => {
        site.updateBookPurchase(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateBookAwards: (state, action) => {
      doSiteAction(state, site => {
        site.updateBookAwards(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateBookAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateBookAssets(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateBookMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateBookMeta(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateBookReviews: (state, action) => {
      doSiteAction(state, site => {
        site.updateBookReviews(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateBookSchedule: (state, action) => {
      doSiteAction(state, site => {
        site.updateBookSchedule(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateBook__Series: (state, action) => {
      doSiteAction(state, site => {
        site.updateBook__Series(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    addProductToBook: (state, action) => {
      doSiteAction(state, site => {
        site.addProductToBook(action.payload.id, action.payload.productId);
      });
    },
    removeProductFromBook: (state, action) => {
      doSiteAction(state, site => {
        site.removeProductFromBook(action.payload.id, action.payload.productId);
      });
    },

    createBookSeries: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          // NOTE: most objects have a `meta.order` prop. Although books support that, they are ordered by release_date by default.
          site.createBookSeries(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteBookSeries: (state, action) => {
      doSiteAction(state, site => {
        site.deleteBookSeries(action.payload.id);
      });
    },
    updateBookSeriesAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateBookSeriesAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateBookSeriesMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateBookSeriesMeta(action.payload.id, action.payload.key, action.payload.value);
      });
    },

    createPost: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          const { id, ...data } = payload;
          const postData = Object.keys(data).length ? data : undefined;
          site.createPost(id, postData);
        });
      }, prepare: payload => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
            ...payload
          }
        };
      }
    },
    deletePost: (state, action) => {
      doSiteAction(state, site => {
        site.deletePost(action.payload.id);
      });
    },
    updatePostAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updatePostAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updatePostAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updatePostAssets(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updatePostSchedule: (state, action) => {
      doSiteAction(state, site => {
        site.updatePostSchedule(action.payload.id, action.payload.key, action.payload.value);
      });
    },



    createEvent: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createEvent(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteEvent: (state, action) => {
      doSiteAction(state, site => {
        site.deleteEvent(action.payload.id);
      });
    },
    updateEventAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateEventAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateEventLocation: (state, action) => {
      doSiteAction(state, site => {
        site.updateEventLocation(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateEventSchedule: (state, action) => {
      doSiteAction(state, site => {
        site.updateEventSchedule(action.payload.id, action.payload.key, action.payload.value);
      });
    },





    createCustomPage: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createCustomPage(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteCustomPage: (state, action) => {
      doSiteAction(state, site => {
        site.deleteCustomPage(action.payload.id);
      });
    },
    updateCustomPageAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateCustomPageAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateCustomPageAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateCustomPageAssets(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateCustomPageMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateCustomPageMeta(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateCustomPageSchedule: (state, action) => {
      doSiteAction(state, site => {
        site.updateCustomPageSchedule(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateCustomPageNavigation: (state, action) => {
      doSiteAction(state, site => {
        site.updateCustomPageNavigation(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    addProductToCustomPage: (state, action) => {
      doSiteAction(state, site => {
        site.addProductToCustomPage(action.payload.id, action.payload.productId);
      });
    },
    removeProductFromCustomPage: (state, action) => {
      doSiteAction(state, site => {
        site.removeProductFromCustomPage(action.payload.id, action.payload.productId);
      });
    },
    addGalleryToCustomPage: (state, action) => {
      doSiteAction(state, site => {
        site.addGalleryToCustomPage(action.payload.id, action.payload.galleryId);
      });
    },
    removeGalleryFromCustomPage: (state, action) => {
      doSiteAction(state, site => {
        site.removeGalleryFromCustomPage(action.payload.id, action.payload.galleryId);
      });
    },




    createLandingPage: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createLandingPage(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteLandingPage: (state, action) => {
      doSiteAction(state, site => {
        site.deleteLandingPage(action.payload.id);
      });
    },
    updateLandingPageAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateLandingPageAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateLandingPageAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateLandingPageAssets(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateLandingPageMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateLandingPageMeta(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateLandingPageSchedule: (state, action) => {
      doSiteAction(state, site => {
        site.updateLandingPageSchedule(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateLandingPageNavigation: (state, action) => {
      doSiteAction(state, site => {
        site.updateLandingPageNavigation(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateLandingPageForm: (state, action) => {
      doSiteAction(state, site => {
        site.updateLandingPageForm(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    addProductToLandingPage: (state, action) => {
      doSiteAction(state, site => {
        site.addProductToLandingPage(action.payload.id, action.payload.productId);
      });
    },
    removeProductFromLandingPage: (state, action) => {
      doSiteAction(state, site => {
        site.removeProductFromLandingPage(action.payload.id, action.payload.productId);
      });
    },
    addGalleryToLandingPage: (state, action) => {
      doSiteAction(state, site => {
        site.addGalleryToLandingPage(action.payload.id, action.payload.galleryId);
      });
    },
    removeGalleryFromLandingPage: (state, action) => {
      doSiteAction(state, site => {
        site.removeGalleryFromLandingPage(action.payload.id, action.payload.galleryId);
      });
    },










    createSlide: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createSlide(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteSlide: (state, action) => {
      doSiteAction(state, site => {
        site.deleteSlide(action.payload.id);
      });
    },
    updateSlideAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateSlideAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateSlideAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateSlideAssets(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateSlideMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateSlideMeta(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateSlideNavigation: (state, action) => {
      doSiteAction(state, site => {
        site.updateSlideNavigation(action.payload.id, action.payload.key, action.payload.value);
      });
    },


    createSlide2: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createSlide2(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteSlide2: (state, action) => {
      doSiteAction(state, site => {
        site.deleteSlide2(action.payload.id);
      });
    },
    updateSlide2About: (state, action) => {
      doSiteAction(state, site => {
        site.updateSlide2About(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateSlide2Assets: (state, action) => {
      doSiteAction(state, site => {
        site.updateSlide2Assets(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateSlide2Meta: (state, action) => {
      doSiteAction(state, site => {
        site.updateSlide2Meta(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateSlide2Navigation: (state, action) => {
      doSiteAction(state, site => {
        site.updateSlide2Navigation(action.payload.id, action.payload.key, action.payload.value);
      });
    },





    createProduct: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createProduct(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteProduct: (state, action) => {
      doSiteAction(state, site => {
        site.deleteProduct(action.payload.id);
      });
    },
    updateProductAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateProductAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateProductAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateProductAssets(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateProductAttribute: (state, action) => {
      doSiteAction(state, site => {
        site.updateProductAttribute(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateProductMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateProductMeta(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateProductConfirmation: (state, action) => {
      doSiteAction(state, site => {
        site.updateProductConfirmation(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateProductSocial: (state, action) => {
      doSiteAction(state, site => {
        site.updateProductSocial(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateProductStripe: (state, action) => {
      doSiteAction(state, site => {
        site.updateProductStripe(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateProductPrice: (state, action) => {
      doSiteAction(state, site => {
        site.updateProductPrice(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateProductSubscription: (state, action) => {
      doSiteAction(state, site => {
        site.updateProductSubscription(action.payload.id, action.payload.key, action.payload.value);
      });
    },

    createGallery: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createGallery(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteGallery: (state, action) => {
      doSiteAction(state, site => {
        site.deleteGallery(action.payload.id);
      });
    },
    updateGalleryAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateGalleryAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateGalleryAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateGalleryAssets(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateGalleryMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateGalleryMeta(action.payload.id, action.payload.key, action.payload.value);
      });
    },


    createGalleryItem: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createGalleryItem(payload.id, payload.galleryId);
        });
      }, prepare: ({ galleryId }) => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
            galleryId
          }
        };
      }
    },
    deleteGalleryItem: {
      reducer: (state, action) => {
        doSiteAction(state, site => {
          site.deleteGalleryItem(action.payload.id, action.payload.galleryId);
        });
      },
    },
    updateGalleryItemAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateGalleryItemAbout(action.payload.id, action.payload.galleryId, action.payload.key, action.payload.value);
      });
    },
    updateGalleryItemAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateGalleryItemAssets(action.payload.id, action.payload.galleryId, action.payload.key, action.payload.value);
      });
    },
    updateGalleryItemMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateGalleryItemMeta(action.payload.id, action.payload.galleryId, action.payload.key, action.payload.value);
      });
    },



    createNavigationGroup: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createNavigationGroup(payload.id);
        });
      }, prepare: () => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
          }
        };
      }
    },
    deleteNavigationGroup: (state, action) => {
      doSiteAction(state, site => {
        site.deleteNavigationGroup(action.payload.id);
      });
    },
    updateNavigationGroupAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateNavigationGroupAbout(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateNavigationGroupAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateNavigationGroupAssets(action.payload.id, action.payload.key, action.payload.value);
      });
    },
    updateNavigationGroupMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateNavigationGroupMeta(action.payload.id, action.payload.key, action.payload.value);
      });
    },


    createNavigationGroupItem: {
      reducer: (state, { payload }) => {
        doSiteAction(state, site => {
          site.createNavigationGroupItem(payload.id, payload.navigationGroupId);
        });
      }, prepare: ({ navigationGroupId }) => {
        const id = getFirebaseKeyV3();

        return {
          payload: {
            id,
            navigationGroupId,
          }
        };
      }
    },
    deleteNavigationGroupItem: (state, action) => {
      doSiteAction(state, site => {
        site.deleteNavigationGroupItem(action.payload.id, action.payload.navigationGroupId);
      });
    },
    updateNavigationGroupItemAbout: (state, action) => {
      doSiteAction(state, site => {
        site.updateNavigationGroupItemAbout(action.payload.id, action.payload.navigationGroupId, action.payload.key, action.payload.value);
      });
    },
    updateNavigationGroupItemAssets: (state, action) => {
      doSiteAction(state, site => {
        site.updateNavigationGroupItemAssets(action.payload.id, action.payload.navigationGroupId, action.payload.key, action.payload.value);
      });
    },
    updateNavigationGroupItemMeta: (state, action) => {
      doSiteAction(state, site => {
        site.updateNavigationGroupItemMeta(action.payload.id, action.payload.navigationGroupId, action.payload.key, action.payload.value);
      });
    },
    updateNavigationGroupItemPage: (state, action) => {
      doSiteAction(state, site => {
        site.updateNavigationGroupItemPage(action.payload.id, action.payload.navigationGroupId, action.payload.key, action.payload.value);
      });
    },











    listenForPendingChanges: (state) => {
      // state.isLoading = true;
    },
    listenForPendingChangesSuccess: (state) => {
      // state.isLoading = false;
      state.hasErrors = false;
    },
    listenForPendingChangesFailure: (state) => {
      // state.isLoading = false;
      state.hasErrors = true;
    },
    getPendingChanges: (state) => {
      state.isLoading = true;
    },
    getPendingChangesSuccess: (state, { payload }) => {
      // usually, we need to write a lot of code to make a reducer immutable. but the rtk prdocues a copy of the orig object before we get here, so it's safe to modify.
      // read more https://redux-toolkit.js.org/usage/immer-reducers#redux-toolkit-and-immer
      state.pendingChanges.queue = payload

      state.isLoading = false;
      state.hasErrors = false;
    },
    getPendingChangesFailure: (state) => {
      state.isLoading = false;
      state.hasErrors = true;
    },
    replayChanges: (state) => {
      state.isReplaying = true;
    },
    replayChangesSuccess: (state, action) => {
      // todo handle clearing out if someone hits cancel on someone else's changes
      state.pendingChanges.queue = action.payload;
      state.isReplaying = false;
      state.isReplayed = true;
    },
    replayChangesFailure: (state) => {
      state.hasErrors = true;
      state.isReplaying = false;
    },
    savePendingChange: (state) => {
      state.isSaving = true;
    },
    savePendingChangeSuccess: (state, action) => {
      // todo handle clearing out if someone hits cancel on someone else's changes
      state.pendingChanges.queue.push(action);
      state.isSaving = false;
    },
    savePendingChangeFailure: (state) => {
      state.isSaving = false;
      state.hasErrors = true;
    },
    clearPendingChanges: (state) => {
      state.isSaving = true;
    },
    clearPendingChangesSuccess: (state) => {
      // todo handle clearing out if someone hits cancel on someone else's changes

      state.isSaving = false;
      state.pendingChanges.queue = [];
    },
    clearPendingChangesFailure: (state) => {
      state.isSaving = false;
      state.hasErrors = true;
    },


    getWebsiteHistory: (state) => {
      state.isLoading = true;
    },
    getWebsiteHistorySuccess: (state, { payload }) => {
      // usually, we need to write a lot of code to make a reducer immutable. but the rtk prdocues a copy of the orig object before we get here, so it's safe to modify.
      // read more https://redux-toolkit.js.org/usage/immer-reducers#redux-toolkit-and-immer
      state.history.snapshots = payload

      state.isLoading = false;
      state.hasErrors = false;
    },
    getWebsiteHistoryFailure: (state) => {
      state.isLoading = false;
      state.hasErrors = true;
    },
  }
});

const {
  getData, getDataSuccess, getDataFailure,
  updateAbout, updateOnboard, updateOnboardByAdvance, updateAssets, updateStyle, createPageChildComponent, movePageChildComponent, replaceStyleImportStatement, replaceStyleCssVars, updatePageChildComponentProperty, updateListItemComponentProperty, createNewListItem, deleteListItem, updateRelationship, deleteRelationship, updateContact, updateCustomHtmlPage, updateEcommerce, updateSocial, addGalleryToAbout, removeGalleryFromAbout, updateNavigation,
  createBook, deleteBook, updateBookAbout, updateBookPurchase, updateBookAwards, updateBookAssets, updateBookMeta, updateBookReviews, updateBookSchedule, updateBook__Series
  , addProductToBook, removeProductFromBook,
  createBookSeries, deleteBookSeries, updateBookSeriesAbout, updateBookSeriesMeta,
  createPost, deletePost, updatePostAbout, updatePostAssets, updatePostSchedule,
  createEvent, deleteEvent, updateEventAbout, updateEventLocation, updateEventSchedule,
  createCustomPage, deleteCustomPage, updateCustomPageAbout, updateCustomPageAssets, updateCustomPageMeta, updateCustomPageSchedule, updateCustomPageNavigation, addProductToCustomPage, removeProductFromCustomPage, addGalleryToCustomPage, removeGalleryFromCustomPage,
  createLandingPage, deleteLandingPage, updateLandingPageAbout, updateLandingPageAssets, updateLandingPageMeta, updateLandingPageSchedule, updateLandingPageNavigation, updateLandingPageForm, addProductToLandingPage, removeProductFromLandingPage, addGalleryToLandingPage, removeGalleryFromLandingPage,
  createSlide, deleteSlide, updateSlideAbout, updateSlideAssets, updateSlideMeta, updateSlideNavigation,
  createSlide2, deleteSlide2, updateSlide2About, updateSlide2Assets, updateSlide2Meta, updateSlide2Navigation,
  createProduct, deleteProduct, updateProductAbout, updateProductAssets, updateProductAttribute, updateProductMeta, updateProductConfirmation, updateProductPrice, updateProductSocial, updateProductStripe, updateProductSubscription,
  createGallery, deleteGallery, updateGalleryAbout, updateGalleryAssets, updateGalleryMeta, updateGalleryPrice,
  createGalleryItem, deleteGalleryItem, updateGalleryItemAbout, updateGalleryItemAssets, updateGalleryItemMeta, updateGalleryItemPrice,

  createNavigationGroup, deleteNavigationGroup, updateNavigationGroupAbout, updateNavigationGroupAssets, updateNavigationGroupMeta, updateNavigationGroupPrice,
  createNavigationGroupItem, deleteNavigationGroupItem, updateNavigationGroupItemAbout, updateNavigationGroupItemAssets, updateNavigationGroupItemMeta, updateNavigationGroupItemPrice, updateNavigationGroupItemPage,

  saveData, saveDataProgress, saveDataSuccess, saveDataFailure,
  getPendingChanges, getPendingChangesSuccess, getPendingChangesFailure,
  replayChanges, replayChangesSuccess, replayChangesFailure,
  savePendingChange, savePendingChangeSuccess, savePendingChangeFailure,
  clearPendingChanges: clearPendingChangesSync, clearPendingChangesSuccess, clearPendingChangesFailure,
  getWebsiteHistory, getWebsiteHistorySuccess, getWebsiteHistoryFailure,
  listenForPendingChanges, listenForPendingChangesSuccess, listenForPendingChangesFailure,
} = websiteSlice.actions;

export const reducer = websiteSlice.reducer;

export {
  getDataSuccess,
  getPendingChangesSuccess, replayChanges, replayChangesSuccess, replayChangesFailure,
  listenForPendingChanges, listenForPendingChangesSuccess, listenForPendingChangesFailure,
  updateAbout, updateOnboard, updateOnboardByAdvance, updateAssets, updateStyle, createPageChildComponent, movePageChildComponent, replaceStyleImportStatement, replaceStyleCssVars, updatePageChildComponentProperty, updateListItemComponentProperty, createNewListItem, deleteListItem, updateRelationship, deleteRelationship, updateContact, updateCustomHtmlPage, updateEcommerce, updateSocial, addGalleryToAbout, removeGalleryFromAbout, updateNavigation,
  createBook, deleteBook, updateBookAbout, updateBookPurchase, updateBookAwards, updateBookAssets, updateBookMeta, updateBookReviews, updateBookSchedule, updateBook__Series, addProductToBook, removeProductFromBook,
  createBookSeries, deleteBookSeries, updateBookSeriesAbout, updateBookSeriesMeta,
  createPost, deletePost, updatePostAbout, updatePostAssets, updatePostSchedule,
  createEvent, deleteEvent, updateEventAbout, updateEventLocation, updateEventSchedule,
  createCustomPage, deleteCustomPage, updateCustomPageAbout, updateCustomPageAssets, updateCustomPageMeta, updateCustomPageSchedule, updateCustomPageNavigation, addProductToCustomPage, removeProductFromCustomPage, addGalleryToCustomPage, removeGalleryFromCustomPage,
  createLandingPage, deleteLandingPage, updateLandingPageAbout, updateLandingPageAssets, updateLandingPageMeta, updateLandingPageSchedule, updateLandingPageNavigation, updateLandingPageForm, addProductToLandingPage, removeProductFromLandingPage, addGalleryToLandingPage, removeGalleryFromLandingPage,
  createSlide, deleteSlide, updateSlideAbout, updateSlideAssets, updateSlideMeta, updateSlideNavigation,
  createSlide2, deleteSlide2, updateSlide2About, updateSlide2Assets, updateSlide2Meta, updateSlide2Navigation,
  createProduct, deleteProduct, updateProductAbout, updateProductAssets, updateProductAttribute, updateProductMeta, updateProductConfirmation, updateProductPrice, updateProductSocial, updateProductStripe, updateProductSubscription,
  createGallery, deleteGallery, updateGalleryAbout, updateGalleryAssets, updateGalleryMeta, updateGalleryPrice,
  createGalleryItem, deleteGalleryItem, updateGalleryItemAbout, updateGalleryItemAssets, updateGalleryItemMeta, updateGalleryItemPrice,
  createNavigationGroup, deleteNavigationGroup, updateNavigationGroupAbout, updateNavigationGroupAssets, updateNavigationGroupMeta, updateNavigationGroupPrice,
  createNavigationGroupItem, deleteNavigationGroupItem, updateNavigationGroupItemAbout, updateNavigationGroupItemAssets, updateNavigationGroupItemMeta, updateNavigationGroupItemPrice, updateNavigationGroupItemPage,
};

export const fetchWebsiteData = createAsyncThunk(
  "website/fetchWebsiteData",
  async (slug, { dispatch }) => {
    // Set the loading state to true
    dispatch(getData());

    try {

      const data = await websiteApi.getDataBySlug(slug, 'latest');

      // convert the Website objy to a pojo. any events or actions must be json serializable.
      const dispatchData = makeSerialilzable(data);
      dispatch(getDataSuccess(dispatchData));
    } catch (error) {
      console.error('error', error)
      // Set any erros while trying to fetch
      dispatch(getDataFailure(error));
    }
  }
);

export const saveWebsiteData = createAsyncThunk(
  "website/saveWebsiteData",
  async (comment, { dispatch, getState, rejectWithValue }) => {
    dispatch(saveData());

    try {
      const websiteData = getState().website.data;

      const dispatchData = await websiteApi.saveWebsiteData(comment, websiteData);

      // TODO FIX THIS - ZAPIER IS FAILING DUE TO 5K LIMIT
      // TODO - ONLY DO THIS IN PRODUCTION
      // await websiteApi.saveWebsiteSnapshotBackup(backupApiUrl, websiteData.meta.slug, websiteData, comment);

      dispatch(saveDataSuccess(dispatchData));

      const user = getState().user.data;
      if (!user) throw new Error(`user must exist to record changes.`);

      const currentEnv = process.env.NODE_ENV;
      if (currentEnv == 'production' && websiteData.meta.websiteType != PRODUCT_ROOT) {
        const isAdmin = user.roles.includes('admin');

        if (isAdmin) {
          return;
        }

        tracker.track('Changes Saved', { website_id: websiteData.id });
        // window.freshsales?.trackEvent('Changes Saved', { website_id: websiteData.id });
        window.fwcrm?.trackCustomEvent('Changes Saved', { website_id: websiteData.id });
      }

    } catch (error) {
      console.error('error', error)
      dispatch(saveDataFailure(error));
      throw rejectWithValue(error);
    }
  }
);

export const saveFile = createAsyncThunk(
  "website/saveFile",
  async (image, { dispatch, getState }) => {
    dispatch(saveData());

    try {
      const siteId = getState().website.data.id;
      const fileName = appendToFilename(image.name, "_" + Date.now());
      const uploadTask = websiteApi.updloadFile(siteId, fileName, image);

      const uploadPromise = new Promise((resolve, reject) => {
        uploadTask.on('state_changed', snapshot => {


          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;


          saveDataProgress(progress);


        }, error => {



          reject(error);
        }, async () => {


          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();


          resolve(downloadURL);
        });
      });

      const downloadURL = await uploadPromise;


      dispatch(saveDataSuccess(downloadURL));

      return downloadURL;
    } catch (error) {
      console.error('error', error)
      dispatch(saveDataFailure(error));
    }
  }
);

export const recordChanges = createAsyncThunk(
  "website/record",
  async (action, { dispatch, getState }) => {
    dispatch(savePendingChange());
    const websiteData = getState().website.data;
    const websiteId = websiteData.id;

    const user = getState().user.data;

    try {

      const dispatchData = await websiteApi.savePendingChange(websiteData, action, user);
      dispatch(savePendingChangeSuccess(dispatchData));
      // await new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve();
      //   }, 2500);
      // });

      const currentEnv = process.env.NODE_ENV;

      if (currentEnv == 'production' && websiteData.meta.websiteType != PRODUCT_ROOT) {
        const isAdmin = user.roles.includes('admin');

        if (isAdmin) {
          return;
        }

        const readableEventName = getHumanReadableEventName(action.type);
        const formattedEventProps = humps.decamelizeKeys({ ...action.payload, websiteId });
        if (formattedEventProps.key) {
          formattedEventProps.key = humps.decamelize(formattedEventProps.key);
        }
        // console.log('event:', readableEventName, formattedEventProps);
        tracker.track(readableEventName, formattedEventProps);

        // DO NOT use freshsales?.trackEvent - it doesn't do anything
        // window.freshsales?.trackEvent(readableEventName, freshsalesProps);
        window.fwcrm?.trackCustomEvent(readableEventName, formattedEventProps);
      }
    } catch (error) {
      console.error('error', error)
      // Set any erros while trying to fetch
      dispatch(savePendingChangeFailure(error));
    }
  }
);

function getHumanReadableEventName(actionType) {
  let retVal;

  const removePrefix = actionType.replace('website/', '');

  const spacedOut = humps.decamelize(removePrefix, { separator: ' ' });

  const readableEventName = toTitleCase(spacedOut);

  const titleSplit = readableEventName.split(' ');
  const [firstWord, ...rest] = titleSplit;
  const firstWordTense = firstWord.endsWith('e') ? firstWord + 'd' : firstWord + 'ed';

  if (/add|remove/i.test(firstWord)) {
    // add or remove is different
    // find `to` or `from`
    const prepositionIndex = Math.max(rest.indexOf('To'), rest.indexOf('From'));
    const preposition = rest[prepositionIndex];
    const firstPart = rest.slice(0, prepositionIndex).join(' ');
    const secondPart = rest.slice(prepositionIndex + 1).join(' ');
    retVal = firstPart + ' ' + firstWordTense + ' ' + preposition + ' ' + secondPart;
  } else {
    // create delete
    const remainingPart = rest.join(' ');
    retVal = remainingPart + ' ' + firstWordTense;
  }

  return retVal;
}

export const fetchPendingChanges = createAsyncThunk(
  "website/fetchPendingChanges",
  async (_, { dispatch, getState }) => {
    const websiteId = getState().website.data.id;

    dispatch(getPendingChanges());

    try {
      const dispatchData = await websiteApi.getPendingChanges(websiteId);
      dispatch(getPendingChangesSuccess(dispatchData));
    } catch (error) {
      console.error('error', error)
      // Set any erros while trying to fetch
      dispatch(getPendingChangesFailure(error));
    }
  }
);


// deprecated
export const establishPendingChangesListener = createAsyncThunk(
  "website/establishPendingChangesListener",
  async (_, { dispatch, getState }) => {
    const websiteId = getState().website.data.id;

    dispatch(listenForPendingChanges());

    try {
      websiteApi.listenForPendingChanges(websiteId, (changes) => {
        // isSaving means we're currently making this pending change that's been listened to
        const isSaving = getState().website.isSaving;
        if (!isSaving) {
          dispatch(replayChanges());

          const alreadyProcessedChangeIds = getState().website.pendingChanges.queue.map(change => change.id);
          try {
            changes
              .filter(change => !alreadyProcessedChangeIds.includes(change.id))
              .forEach(change => {
                dispatch(change.action);
              });
            dispatch(replayChangesSuccess(changes));

          } catch (error) {
            console.error('error', error)
            // Set any erros while trying to fetch
            dispatch(replayChangesFailure(error));
          }
        }
      });
      dispatch(listenForPendingChangesSuccess());
    } catch (error) {
      console.error('error', error)
      // Set any erros while trying to fetch
      dispatch(listenForPendingChangesFailure(error));
    }
  }
);

export const clearPendingChanges = createAsyncThunk(
  "website/clearPendingChanges",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const websiteData = getState().website.data;
    const websiteId = websiteData.id;
    // ensure there are not errors
    if (getState().website.hasErrors) {
      throw new rejectWithValue('Cannot clear pending changes when there are errors');
    }

    dispatch(clearPendingChangesSync());

    try {
      const status = await websiteApi.deletePendingChanges(websiteId);

      dispatch(clearPendingChangesSuccess());

      const { isCancelled } = payload;
      if (isCancelled) {
        const user = getState().user.data;

        if (!user) throw new Error(`user must exist to record changes.`);
        const currentEnv = process.env.NODE_ENV;

        if (currentEnv == 'production' && websiteData.meta.websiteType != PRODUCT_ROOT) {
          const isAdmin = user.roles.includes('admin');

          if (isAdmin) {
            return;
          }

          tracker.track('Changes Cancelled', { website_id: websiteId });
          // window.freshsales?.trackEvent('Changes Cancelled', { website_id: websiteData.id });
          window.fwcrm?.trackCustomEvent('Changes Cancelled', { website_id: websiteId });
        }
      }

    } catch (error) {
      // handling errors in redux
      // https://stackoverflow.com/questions/63439021/handling-errors-with-redux-toolkit
      dispatch(clearPendingChangesFailure(error));

      throw error;
    }
  }
);

export const fetchWebsiteHistory = createAsyncThunk(
  "website/fetchWebsiteHistory",
  async (_, { dispatch, getState }) => {
    const websiteId = getState().website.data.id;

    dispatch(getWebsiteHistory());

    try {
      const dispatchData = await websiteApi.getHistory(websiteId);

      dispatch(getWebsiteHistorySuccess(dispatchData));
    } catch (error) {
      console.error('error', error)
      // Set any erros while trying to fetch
      dispatch(getWebsiteHistoryFailure(error));
    }
  }
);

function getSiteFromState(state) {
  const retVal = new Website(state.data.id, state.data);

  return retVal;
}

function doSiteAction(state, siteAction) {
  const site = getSiteFromState(state);

  const retVal = siteAction(site);

  state.data = makeSerialilzable(site);

  return retVal;
}
