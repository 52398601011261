import { convertThemeDataToReact_v1_0_0 } from './v1.0.0/theme-data/renderer/render-components';
import { ThemeData_v1, ComponentThemeData_v1, RenderThemeData_v1, SiteData_v1, WimlData_v1 } from './types';
import { _markupToComponentThemeData_v1_0_0 } from './v1.0.0/theme-data/compiler';
import { _markupToComponentThemeData_v1_1_0 } from './v1.1.0/theme-data/compiler';
import { _markupToComponentThemeData_v1_2_0 } from './v1.2.0/theme-data/compiler';
import { convertThemeDataToReact_v1_1_0 } from './v1.1.0/theme-data/renderer/render-components';
import { ComponentThemeData_v1_0_0 } from "./v1.0.0/theme-data/compiler/types";
import { ComponentThemeData_v1_1_0 } from './v1.1.0/theme-data/compiler/types';
import cheerio from 'cheerio';
import { ComponentThemeData_v1_2_0 } from './v1.2.0/theme-data/compiler/types';
import { convertThemeDataToReact_v1_2_0 } from './v1.2.0/theme-data/renderer/render-components';
import { wimlComponents_v1_0_0 } from './v1.0.0/theme-data/renderer/wiml-components-v1.0.0';
import { wimlComponents_v1_1_0 } from './v1.1.0/theme-data/renderer/wiml-components-v1.1.0';
import { wimlComponents_v1_2_0 } from './v1.2.0/theme-data/renderer/wiml-components-v1.2.0';
import { RuntimeWimlComponentCollection_v1_0_0, RuntimeWimlComponent_v1_0_0 } from './v1.0.0/theme-data/renderer/types';
import { createNewListItem_v1_0_0, deleteListItem_v1_0_0, deleteRelationship_v1_0_0, updateListItemComponentProperty_v1_0_0, updatePageChildComponentProperty_v1_0_0, updateRelationship_v1_0_0 } from './v1.0.0/site-data/modification';
import { getListComponentThemeData_v1_0_0, getPageChildListInstanceChildComponentThemeData_v1_0_0, getPageChildListInstanceComponentThemeData_v1_0_0, getPageComponentThemeData_v1_0_0 } from './v1.0.0/site-data/retrieval';
import { convertStringToSemVer } from '../../../../lib/text-utils';
import { serialize } from '../../../../lib/react-serializer';
import { addThemeDataPageChildComponentToMarkup_v1_3_0, moveThemeDataPageChildComponentToMarkup_v1_3_0, replaceStyleCssVarsInMarkup_v1_3_0, replaceStyleImportStatementInMarkup_v1_3_0 } from './v1.3.0/theme-data/compiler/modification';

import { formatNodeKey_v1_2_0, formatNodeType_v1_2_0 } from './v1.2.0/theme-data/compiler/components';

export function getWimlVersion_v1(wimlString: string) {
    const $ = cheerio.load(wimlString, { xmlMode: true });
    const root = $('WIML');
    const version = root.attr('version');

    // todo validate only one root node
    const semVer = convertStringToSemVerString_v1(version);

    return semVer;
}

export type WimlVer = "1.0.0" | "1.1.0" | "1.2.0" | "1.3.0";

export function convertStringToSemVerString_v1(version: string): WimlVer {
    // handle default or empty minor and patch
    const {
        major, minor, patch,
    } = convertStringToSemVer(version);

    return `${major}.${minor}.${patch}` as WimlVer;
}


export class ConvertThemeDataToReact_v1 {
    convertComponentThemeDataToRenderThemeData_v1(arg0: ComponentThemeData_v1) {
        const version = convertStringToSemVerString_v1(arg0.version);
        const versionSpecificConverterType = this.converterClassLookup_v1[version];
        const versionSpecificConverter = new versionSpecificConverterType();
        return versionSpecificConverter.convertComponentThemeDataToRenderThemeData_v1(arg0);
    }

    converterClassLookup_v1: Record<WimlVer, ConverterClass_v1> = {
        "1.0.0": ConvertThemeDataToReact_v1_0_0,
        "1.1.0": ConvertThemeDataToReact_v1_1_0,
        "1.2.0": ConvertThemeDataToReact_v1_2_0,
        "1.3.0": ConvertThemeDataToReact_v1_3_0,
    };

    constructor() {
    }

    __convertWimlToThemeData_v1(wimlString: string) {
        const version = getWimlVersion_v1(wimlString);
        const versionSpecificConverterType = this.converterClassLookup_v1[version];
        const versionSpecificConverter = new versionSpecificConverterType();
        // todo return type should be component theme data
        const componentThemeData = versionSpecificConverter.convertMarkupToComponentThemeData_v1(wimlString);
        // todo return type should be react/render theme data
        const renderThemeData = versionSpecificConverter.convertComponentThemeDataToRenderThemeData_v1(componentThemeData);


        const retVal = new ThemeData_v1();
        retVal.markup = wimlString;

        const pages = componentThemeData.pages;
        retVal.components.items.pages = pages;

        const lists = componentThemeData.lists;
        retVal.components.items.lists = lists;

        const rootNodeJson = JSON.stringify(componentThemeData.rootNode); // stringify so we can save in firebase as a string
        retVal.components.items.rootNodeJson = rootNodeJson;

        retVal.components.ids = Object.keys(retVal.components.items);

        const payload = Object.entries(renderThemeData).reduce((accum, [k, v]) => {
            // todo pass in list of types so we do not rely on build-time types to have consistent name. E.g. <Page/> becomes <It/> in the build
            // _reactLookupComponentsByType use this table so we can remove webpack build config
            const serializedPage = serialize(v);
            accum[k] = serializedPage;
            return accum;
        }, {});

        retVal.reactComponents.items = retVal.reactComponents.items || { pages: { ids: [], items: {} } };
        retVal.reactComponents.ids = Object.keys(retVal.reactComponents.items);
        retVal.reactComponents.items.pages.items = payload;
        retVal.reactComponents.items.pages.ids = Object.keys(payload);//.map((k, i) => ({ id: k, order: i }));

        const wimlVersion = componentThemeData.version;

        retVal.meta = {
            ...retVal.meta,
            version: wimlVersion,
        };

        return retVal;
    }

    convertMarkupToThemeData_v1(wimlString: string) {
        const version = getWimlVersion_v1(wimlString);
        const versionSpecificConverterType = this.converterClassLookup_v1[version];
        const versionSpecificConverter = new versionSpecificConverterType();

        const retVal = versionSpecificConverter.convertMarkupToThemeData_v1(wimlString);

        return retVal;
    }
}

// type ConverterConstructor = new () => {
//     convertWimlToThemeData_v1: (wimlString: string) => any;
//     convertThemeDataToReact_v1: (themeData: any) => any;
// };

type ConverterClass_v1 = new () => IConverter_v1;
interface IConverter_v1 {
    convertMarkupToComponentThemeData_v1: (wimlString: string) => ComponentThemeData_v1;
    convertComponentThemeDataToRenderThemeData_v1: (themeData: any) => RenderThemeData_v1;
    convertMarkupToThemeData_v1: (wimlString: string) => ThemeData_v1;
}

export class ConvertThemeDataToReact_v1_0_0 implements IConverter_v1 {
    constructor() {
    }

    public convertComponentThemeDataToRenderThemeData_v1(themeData: ComponentThemeData_v1_0_0) {
        return convertThemeDataToReact_v1_0_0(themeData);
    }
    public convertMarkupToComponentThemeData_v1(wimlString: string) {
        return _markupToComponentThemeData_v1_0_0(wimlString);
    }

    public convertMarkupToThemeData_v1(wimlString: string) {
        return new ConvertThemeDataToReact_v1().__convertWimlToThemeData_v1(wimlString);
    }
}

export class ConvertThemeDataToReact_v1_1_0 implements IConverter_v1 {
    constructor() {
    }

    public convertComponentThemeDataToRenderThemeData_v1(themeData: ComponentThemeData_v1_1_0) {
        return convertThemeDataToReact_v1_1_0(themeData);
    }
    public convertMarkupToComponentThemeData_v1(wimlString: string) {
        return _markupToComponentThemeData_v1_1_0(wimlString);
    }

    public convertMarkupToThemeData_v1(wimlString: string) {
        return new ConvertThemeDataToReact_v1().__convertWimlToThemeData_v1(wimlString);
    }
}
export class ConvertThemeDataToReact_v1_2_0 implements IConverter_v1 {
    constructor() {
    }

    public convertComponentThemeDataToRenderThemeData_v1(themeData: ComponentThemeData_v1_2_0) {
        return convertThemeDataToReact_v1_2_0(themeData);
    }
    public convertMarkupToComponentThemeData_v1(wimlString: string) {
        return _markupToComponentThemeData_v1_2_0(wimlString);
    }

    public convertMarkupToThemeData_v1(wimlString: string) {
        return new ConvertThemeDataToReact_v1().__convertWimlToThemeData_v1(wimlString);
    }
}

export class ConvertThemeDataToReact_v1_3_0 implements IConverter_v1 {
    defaultVersion = '1.3.0';
    defaultValidVersions = ['1.3.0', '1.3.0'];

    constructor() {
    }

    public convertComponentThemeDataToRenderThemeData_v1(themeData: ComponentThemeData_v1_2_0) {
        return convertThemeDataToReact_v1_2_0(themeData);
    }
    public convertMarkupToComponentThemeData_v1(wimlString: string) {
        return _markupToComponentThemeData_v1_2_0(wimlString, this.defaultVersion, this.defaultValidVersions);
    }

    public convertMarkupToThemeData_v1(wimlString: string) {
        return new ConvertThemeDataToReact_v1().__convertWimlToThemeData_v1(wimlString);
    }
}

export function convertMarkupToThemeData_v1(wimlString: string) {
    const converter = new ConvertThemeDataToReact_v1();
    return converter.convertMarkupToThemeData_v1(wimlString);
}

const wimlComponentVersionMetaLookup_v1 = {
    "1.0.0": wimlComponents_v1_0_0,
    "1.1.0": wimlComponents_v1_1_0,
    "1.2.0": wimlComponents_v1_2_0,
    "1.3.0": wimlComponents_v1_2_0,
} as Record<string, RuntimeWimlComponentCollection_v1_0_0>;


export function getComponentLookup_v1(wimlVersion: string, componentType: string) {
    return wimlComponentVersionMetaLookup_v1[wimlVersion][componentType];
}

export function updatePageChildComponentProperty_v1(pageId: string, componentId: string, propertyId: string, propertyExpression: string, wimlData: Wiml_v1) {
    updatePageChildComponentProperty_v1_0_0(pageId, componentId, propertyId, propertyExpression, wimlData);
}

export function addThemeDataPageChildComponentToMarkup_v1(pageKey: string, componentType: string, componentKey: string, themeProps: Record<string, any>, position: string, parentComponentType: string, parentComponentKey: string, wimlData: Wiml_v1) {
    //    // todo get wiml version from themeData
    //    const wimlVersion = this.themeData.meta.version;
    //    const componentLookup = getComponentLookup_v1(wimlVersion, componentType);
    //    const component = createThemeDataPageChildComponent(componentLookup, componentKey);

    //    const newComponent = {
    //        ...component,
    //        ...themeProps,
    //    };  

    //    this.themeData.components.items.pages[pageId].components.items[componentKey] = newComponent;
    // updatePageComponentProperty_v1_0_0(pageId, componentId, propertyId, propertyExpression, wimlData);
    return addThemeDataPageChildComponentToMarkup_v1_3_0(pageKey, componentType, componentKey, themeProps, position, parentComponentType, parentComponentKey, wimlData);
}

export function moveThemeDataPageChildComponentInMarkup_v1(pageId: string, componentId: string, position: string | number, wimlData: Wiml_v1) {
    //    // todo get wiml version from themeData
    //    const wimlVersion = this.themeData.meta.version;
    //    const componentLookup = getComponentLookup_v1(wimlVersion, componentType);
    //    const component = createThemeDataPageChildComponent(componentLookup, componentKey);

    //    const newComponent = {
    //        ...component,
    //        ...themeProps,
    //    };  

    //    this.themeData.components.items.pages[pageId].components.items[componentKey] = newComponent;
    // updatePageComponentProperty_v1_0_0(pageId, componentId, propertyId, propertyExpression, wimlData);
    return moveThemeDataPageChildComponentToMarkup_v1_3_0(pageId, componentId, position, wimlData);
}
export function replaceStyleImportStatementInMarkup_v1(newImport: string, wimlData: Wiml_v1) {

    return replaceStyleImportStatementInMarkup_v1_3_0(newImport, wimlData);
}


export function replaceStyleCssVarsInMarkup_v1(newCssVars: string, wimlData: Wiml_v1) {
    return replaceStyleCssVarsInMarkup_v1_3_0(newCssVars, wimlData);
}

export function createNewListItem_v1(listItemId: string, listId: string, wimlData: Wiml_v1) {
    const newItem = createNewListItem_v1_0_0(listItemId, listId, wimlData);
    return newItem;
}


export function getListComponentThemeData_v1(listId: string, wimlData: Wiml_v1) {
    return getListComponentThemeData_v1_0_0(listId, wimlData);
}


export function getPageComponentThemeData_v1(pageId: string, wimlData: Wiml_v1) {
    return getPageComponentThemeData_v1_0_0(pageId, wimlData);
}


export function getPageChildComponentThemeData_v1(pageId: string, componentId: string, wimlData: Wiml_v1) {
    return getPageComponentThemeData_v1_0_0(pageId, wimlData)?.components?.items?.[componentId];
}

export function getPageChildListInstanceComponentThemeData_v1(pageId: string, listInstanceComponentId: string, wimlData: Wiml_v1) {
    return getPageChildListInstanceComponentThemeData_v1_0_0(pageId, listInstanceComponentId, wimlData);
}

export function getPageChildListInstanceChildComponentThemeData_v1(pageId: string, listInstanceComponentId: string, componentId: string, wimlData: Wiml_v1) {
    return getPageChildListInstanceChildComponentThemeData_v1_0_0(pageId, listInstanceComponentId, componentId, wimlData);
}

export function updateListItemComponentProperty_v1(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string, wimlData: Wiml_v1) {
    updateListItemComponentProperty_v1_0_0(listId, listItemId, componentId, propertyId, propertyExpression, wimlData);
}


export function deleteListItem_v1(listId: string, listItemId: string, wimlData: Wiml_v1) {
    deleteListItem_v1_0_0(listId, listItemId, wimlData);
}


export function updateRelationship_v1(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string, wimlData: Wiml_v1) {
    updateRelationship_v1_0_0(listId, listItemId, relationshipListId, relationshipListItemId, wimlData);
}


export function deleteRelationship_v1(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string, wimlData: Wiml_v1) {
    deleteRelationship_v1_0_0(listId, listItemId, relationshipListId, relationshipListItemId, wimlData);
}

export class Wiml_v1 {

    themeData: ThemeData_v1;
    siteData: SiteData_v1;

    // constructor() {
    //     this.themeData = new ThemeData_v1();
    //     this.siteData = new SiteData_v1();
    // }

    constructor(data?: WimlData_v1) {
        if (data) {
            this.themeData = data.themeData;
            this.siteData = data.siteData;
        } else {
            this.themeData = new ThemeData_v1();
            this.siteData = new SiteData_v1();
        }
    }

    updateMarkup(value: string) {
        this.themeData = convertMarkupToThemeData_v1(value);
    }

    createThemeDataPageChildComponent(pageKey: string, componentType: string, componentKey: string, themeProps: Record<string, any>, position: string, parentComponentType: string, parentComponentKey: string,) {
        const key = formatNodeKey_v1_2_0(componentKey);

        const newMarkup = addThemeDataPageChildComponentToMarkup_v1(pageKey, componentType, key, themeProps, position, parentComponentType, parentComponentKey, this);
        this.updateMarkup(newMarkup);

        const type = formatNodeType_v1_2_0(componentType);
        const newlyCreatedComponent = this.getPageChildComponentThemeData(`page__${pageKey}`, `${type}__${key}`);
        return newlyCreatedComponent;
    }

    moveThemeDataPageChildComponent(pageId: string, componentId: string, position: string | number) {

        const newMarkup = moveThemeDataPageChildComponentInMarkup_v1(pageId, componentId, position, this);
        this.updateMarkup(newMarkup);

        const newlyMovedComponent = this.getPageChildComponentThemeData(pageId, componentId);
        return newlyMovedComponent;
    }

    replaceStyleImportStatement(newImport: string) {
        const newMarkup = replaceStyleImportStatementInMarkup_v1(newImport, this);
        this.updateMarkup(newMarkup);

        return newMarkup;
    }

    replaceStyleCssVars(newCssVars: string) {
        const newMarkup = replaceStyleCssVarsInMarkup_v1(newCssVars, this);
        this.updateMarkup(newMarkup);

        return newMarkup;
    }

    updatePageChildComponentProperty(pageId: string, componentId: string, propertyId: string, propertyExpression: string) {
        updatePageChildComponentProperty_v1(pageId, componentId, propertyId, propertyExpression, this);
    }

    getListComponentThemeData(listId: string) {
        return getListComponentThemeData_v1(listId, this);
    }


    getPageComponentThemeData(pageId: string) {
        return getPageComponentThemeData_v1(pageId, this);
    }

    getPageChildComponentThemeData(pageId: string, componentId: string) {
        return getPageChildComponentThemeData_v1(pageId, componentId, this);
    }

    getPageChildListInstanceComponentThemeData(pageId: string, listInstanceComponentId: string) {
        return getPageChildListInstanceComponentThemeData_v1(pageId, listInstanceComponentId, this);
    }

    getPageChildListInstanceChildComponentThemeData(pageId: string, listInstanceComponentId: string, componentId: string) {
        return getPageChildListInstanceChildComponentThemeData_v1(pageId, listInstanceComponentId, componentId, this);
    }

    deleteListItem(listId: string, listItemId: string) {
        deleteListItem_v1(listId, listItemId, this);
    }

    createNewListItem(listItemId: string, listId: string) {
        const newItem = createNewListItem_v1(listItemId, listId, this);

        return newItem;
    }

    updateListItemComponentProperty(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string) {
        updateListItemComponentProperty_v1(listId, listItemId, componentId, propertyId, propertyExpression, this);
    }

    updateRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        updateRelationship_v1(listId, listItemId, relationshipListId, relationshipListItemId, this);
    }


    deleteRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        deleteRelationship_v1(listId, listItemId, relationshipListId, relationshipListItemId, this);
    }
};
