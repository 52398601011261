import React from 'react';
import { Progress } from 'reactstrap';
import { getInferredComponentDefaultType_v1_0_0 } from '../../compiler/components';
import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../site-data/retrieval';
import { RuntimeWimlComponentProps_v1_0_0 } from '../../../../../../../app/wiml/versions/v1/v1.0.0/theme-data/compiler/types';


export function WimlNumber_v1_0_0(props: RuntimeWimlComponentProps_v1_0_0) {
    let retVal = null;

    const numberVal = getChildComponentPropertyValueSiteData_v1_0_0(props, 'number', getInferredComponentDefaultType_v1_0_0('Number')) as number;
    if (numberVal) {
        const displayFormat = props.display;
        switch (displayFormat) {
            case 'currency': {
                const numberString = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numberVal);
                retVal = (
                    <span>
                        {numberString}
                    </span>
                );
                break;
            }
            case 'percent': {
                if (numberVal < 0 || numberVal > 1) {
                    console.warn('WimlNumber: progress value must be between 0 and 1');
                }
                const percentValue = numberVal / 100;
                const numberString = new Intl.NumberFormat('en-US', { style: 'percent' }).format(percentValue);
                retVal = (
                    <span>
                        {numberString}
                    </span>
                );
                break;
            }
            case 'progress': {
                if (numberVal < 0 || numberVal > 1) {
                    console.warn('WimlNumber: progress value must be between 0 and 1');
                }
                const percentValue = numberVal / 100;
                retVal = (
                    <Progress value={numberVal} />
                ); break;
            }
            default: {
                retVal = (
                    <span>
                        {numberVal}
                    </span>
                );
                break;
            }
        }
    }
    return retVal;
}

WimlNumber_v1_0_0.inputProps = {
    number: {
        type: 'number',
        label: 'Number',
        description: 'The number',
    },
};
