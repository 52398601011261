import { Button } from 'reactstrap';
import { convertObjectToArray } from "@wip/common/lib/object-utils";
import humps from 'humps';
import { ListItem_v1_0_0 } from "./list-item-v1.0.0";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { sortListItems } from "@wip/common/domain/list-sort";
import { RuntimeWimlComponentProps_v1 } from '@wip/common/app/wiml/versions/v1/types';
import { DEFAULT_ADMIN_LIST_LABEL_KEY_v1_0_0 } from './wiml-editor-v1.0.0';

export function getListItemsComponents_v1_0_0(pageId: any, component: { listId: string; }, props: any, dispatchCreateNewListItem: (listId: any, listInstanceComponentId: any) => { payload: any; type: string; }) {
    const listId = humps.camelize(component.listId);
    const newComponent = { ...component, listId: listId };
    const listItemComponents = _getListItemsComponents_v1_0_0(pageId, newComponent, props, dispatchCreateNewListItem, DEFAULT_ADMIN_LIST_LABEL_KEY_v1_0_0);
    return listItemComponents;
}

export function __getListItemsFromListInstance_v1_0_0(pageId: string, listId: string, componentId: string, props: any,) {
    const items = props.websiteData.style.wiml.siteData?.components?.items?.lists?.items?.[listId]?.items?.items;

    let finalArray: any = [];
    if (items) {
        const listSiteData = props.websiteData.style.wiml.siteData.components.items.pages?.items?.[pageId]?.components?.items?.[componentId];

        const itemsArray = convertObjectToArray(items);

        let sortedArray = itemsArray;
        const sortExpression = listSiteData?.sort?.data?.value;

        if (sortExpression) {
            sortedArray = sortListItems(sortExpression, itemsArray);
        }
        finalArray = sortedArray;
    }
    return finalArray;
}

export function _getListItemsComponents_v1_0_0(pageId: string, component: { listId?: any; id?: any; component?: any; }, props: RuntimeWimlComponentProps_v1, dispatchCreateNewListItem: (listId: any, listInstanceComponentId: any) => { payload: any; type: string; }, defaultAdminListLabelKey: string) {
    const listId = component.listId;
    const componentId = component.id;

    const finalArray = __getListItemsFromListInstance_v1_0_0(pageId, listId, componentId, props);
    let itemComponents: {} | null | undefined = null;

    if (finalArray.length) {

        itemComponents = finalArray.map((item: any, index: number) => {
            const selectListItem = () => {
                props.onChange('listId', listId);
                props.onChange('listItemId', item.id);
                // todo remove this - it's handled when selectedTab changes
                // props.onChange('pageId', pageId);
                props.onChange('listInstanceComponentId', componentId);
            };

            // todo make this match convent - e.g. humps
            // react-beautiful-dnd requires a unique id for each draggable item, otherwise only last list wins
            const pageQualifiedListItemId = `${component.id}.${item.id}`;

            const listItemComponent = (
                <Draggable key={item.id} draggableId={pageQualifiedListItemId} index={index} disableInteractiveElementBlocking>
                    {(provided: any) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <ListItem_v1_0_0 item={item} component={component} onClick={selectListItem} defaultAdminListLabelKey={defaultAdminListLabelKey} />
                        </div>
                    )}
                </Draggable>

            );

            return listItemComponent;
        });
    }

    const pageQualifiedListId = `${pageId}.${listId}.${component.id}`;
    const listInstanceComponentId = componentId;

    const listItemComponents = (
        <>
            <Button color="primary" onClick={() => dispatchCreateNewListItem(listId, listInstanceComponentId)}>
                Add
            </Button>
            {/* <DragDropContext onDragEnd={handleDragEnd}> */}
            <Droppable droppableId={pageQualifiedListId}>
                {(provided: any) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {itemComponents}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            {/* </DragDropContext> */}
        </>
    );
    return listItemComponents;
}
